import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxEditorModule } from 'ngx-editor';

import { OverlayModule } from '@angular/cdk/overlay';
import { GoogleAutocompleteComponent } from 'src/app/module/google-map/component/google-autocomplete/google-autocomplete.component';
import { AngularMaterialModule } from '../module/angular-material/angular-material.module';
import { ProfetControlWidgetModule } from '../module/profet-control-widget/profet-control-widget.module';
import { MainNavigationComponent } from '../shared/components/main-navigation/main-navigation.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { DataTableWrapperComponent } from './components/data-table-wrapper/data-table-wrapper.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { HistoryListComponent } from './components/history-list/history-list.component';
import { InfoSheetComponent } from './components/info-sheet/info-sheet.component';
import { JsonFormComponent } from './components/json-form/json-form.component';
import { MlsAccessComponent } from './components/mls-access/mls-access.component';
import { OrderedListComponent } from './components/ordered-list/ordered-list.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ProgressLoaderComponent } from './components/progress-loader/progress-loader.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { SearchPropertyComponent } from './components/search-property/search-property.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';
import { AccessControlDirective } from './directives/access-control.directive';
import { ShowInfoDirective } from './directives/show-info.directive';
import { StandardStatusDirective } from './directives/standard_status.directive';
import { DynamicPipe } from './pipes/dynamic.pipe';
import { SelectedSourceDataPipe } from './pipes/selected-source-data.pipe';

@NgModule({
  exports: [
    AccessControlDirective,
    StandardStatusDirective,
    CommonModule,
    DataTableWrapperComponent,
    FormErrorComponent,
    FormsModule,
    AppHeaderComponent,
    InfoSheetComponent,
    JsonFormComponent,
    MainNavigationComponent,
    PaginatorComponent,
    ProgressLoaderComponent,
    ReactiveFormsModule,
    ShowInfoDirective,
    SearchPropertyComponent,
    HistoryListComponent,
  ],
  declarations: [
    AccessControlDirective,
    StandardStatusDirective,
    ConfirmComponent,
    DataTableWrapperComponent,
    FormErrorComponent,
    AppHeaderComponent,
    InfoSheetComponent,
    JsonFormComponent,
    MainNavigationComponent,
    MlsAccessComponent,
    PaginatorComponent,
    ProgressLoaderComponent,
    ShowInfoDirective,
    TextEditorComponent,
    SearchPropertyComponent,
    HistoryListComponent,
  ],
  imports: [
    AngularMaterialModule,
    OverlayModule,
    CommonModule,
    FormsModule,
    NgxEditorModule,
    ProfetControlWidgetModule,
    ReactiveFormsModule,
    RouterModule,
    OrderedListComponent,
    GoogleAutocompleteComponent,
    DynamicPipe,
    SelectedSourceDataPipe,
    ProgressSpinnerComponent,
  ],
})
export class SharedModule {}
