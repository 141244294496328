export interface ExceptionConfig {
  logger?: boolean;
  notification?: boolean;
  loader?: boolean;
}
export class HttpExceptions {
  constructor(
    public error: any,
    public services: ExceptionConfig
  ) {}
}
