import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyData',
  standalone: true,
})
export class EmptyDataPipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    if (['', null, undefined].includes(value)) return '--';
    else if (typeof value === 'string' && value.trim() == '') return '--';
    else if (Array.isArray(value) && value.length == 0) return '--';
    else return value;
  }
}
