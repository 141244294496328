import { Component } from '@angular/core';
import { AuthService } from 'src/app/module/auth/service/auth.service';
import { SlidingSheetService } from '../../services/sliding-sheet.service';
import { UserState } from '../../state/user.state';
import { MainNavigationComponent } from '../main-navigation/main-navigation.component';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss'],
})
export class AppHeaderComponent {
  constructor(
    private slidingSheetService: SlidingSheetService,
    public userState: UserState,
    public authService: AuthService
  ) {}

  openSideNav() {
    this.slidingSheetService.loadComponent(MainNavigationComponent).show();
  }
}
