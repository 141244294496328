import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { BasicDataType } from '../interface/common.interface';
import { UnitAffixPipe } from './unitAffix.pipe';

@Pipe({
  name: 'dynamicPipe',
  standalone: true,
})
export class DynamicPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe,
    private unitAffixPipe: UnitAffixPipe,
    private datePipe: DatePipe
  ) {}

  transform(value: any, dataType: BasicDataType = 'string', pipeArgs?: any, unit?: any): any {
    if (!value || !dataType || dataType == 'string') return value;

    if (pipeArgs) {
      if (!Array.isArray(pipeArgs)) {
        pipeArgs = [pipeArgs];
      }
    } else {
      pipeArgs = [];
    }

    switch (dataType) {
      case 'currency':
        value = this._convertToNumber(value);
        if (!this._isNumber(value)) return value;
        if (!pipeArgs.length) pipeArgs = ['USD', 'symbol', '1.0-0'];
        // if (unit) return this.unitAffixPipe.transform(this.currencyPipe.transform(value, ...pipeArgs), 'currency');
        return this.currencyPipe.transform(value, ...pipeArgs);

      case 'number':
        value = this._convertToNumber(value);
        if (!this._isNumber(value)) return value;
        if (!pipeArgs.length) pipeArgs = ['1.0-2'];
        if (unit) return this.unitAffixPipe.transform(this.decimalPipe.transform(value, ...pipeArgs), unit);
        else return this.decimalPipe.transform(value, ...pipeArgs);

      case 'numberString':
        return (value.toString() as string).replace(/\D/g, '');

      case 'date':
        return this.datePipe.transform(value, 'MM/dd/yyyy');

      default:
        return value;
    }
  }

  /**
   * Check if the value is a number
   */
  private _isNumber(value: unknown): boolean {
    return typeof value === 'number' || (typeof value === 'string' && !isNaN(Number(value)));
  }

  /**
   * Extract number out of a string
   */
  private _convertToNumber(value: unknown): number | null {
    if (typeof value == 'number') return value;

    if (typeof value != 'string') return null;

    let decimalIndex = 0;
    const numberString: string = value
      .replace(/[^.-\d]/g, '')
      .replace(/\./g, (item) => (!decimalIndex++ ? item : ''))
      .replace(/-/g, (item, offset) => (offset == 0 ? item : ''));

    if (numberString == '') return null;

    return Number(numberString);
  }
}
