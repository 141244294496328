import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  template: ``,
})
export class AbstractFormFieldControls {
  @Input()
  control!: FormControl;
  @Input() field!: any;
}
