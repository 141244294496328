import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, from, switchMap, throwError } from 'rxjs';
import { AuthService } from 'src/app/module/auth/service/auth.service';
import { AuthToken } from 'src/app/module/auth/state/auth.state';
import { environment as ENV } from 'src/environments/environment';

export const authTokenInterceptor: HttpInterceptorFn = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
  authService = inject(AuthService)
) => {
  return from(authService.getAuthToken()).pipe(
    switchMap((auth: AuthToken | null) => {
      let modifiedRequest = req;
      const pattern = new RegExp('^' + ENV.backendBaseUrl);
      const portalPattern = new RegExp('^' + ENV.portal.backendBaseUrl);

      if (req.url.search(pattern) != -1 || req.url.search(portalPattern) != -1)
        modifiedRequest = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${auth?.accessToken ?? ''}`),
        });

      return next(modifiedRequest).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            authService.logout();
            return EMPTY;
          }
          return throwError(() => error as HttpEvent<unknown>);
        })
      );
    })
  );
};
