import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import { RedirectUrlApiRequest, RedirectUrlApiResponse } from '../../order/interface/order.interface';
import {
  CompanyList,
  CompanyListApiResponse,
  OrderListApiResponse,
  UserList,
  UserListApiResponse,
} from '../interface/order-list.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderListApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  getOrderList(params: any): Observable<OrderListApiResponse> {
    const httpParams: any = { ...params };
    return this._http
      .post<CommonApiResponse<OrderListApiResponse>>(`${ENV.backendBaseUrl}/api/orders`, httpParams)
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  getUserList(companyKey: string): Observable<UserList[]> {
    return this._http
      .get<CommonApiResponse<UserListApiResponse>>(
        `${ENV.backendBaseUrl}/api/orderManagement/getUserListByCompanyKey`,
        {
          params: { companyKey },
        }
      )
      .pipe(
        map((res) => res.data.userList),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  getCompanyList(): Observable<CompanyList[]> {
    return this._http
      .get<CommonApiResponse<CompanyListApiResponse>>(`${ENV.backendBaseUrl}/api/orderManagement/getCompanyList`)
      .pipe(
        map((res) => res.data.companyList),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  copyOrder(orderId: string, newOrderId: string): Observable<void> {
    return this._http
      .post(`${ENV.backendBaseUrl}/api/order/copy`, { id: orderId, orderId: newOrderId })
      .pipe(map(() => {}));
  }

  transferOrder(orderId: string, username: string, companyId?: string): Observable<void> {
    return this._http
      .post(`${ENV.backendBaseUrl}/api/order/transfer`, {
        id: orderId,
        username,
        ...(companyId ? { companyId } : {}),
      })
      .pipe(map(() => {}));
  }

  /**
   * Fetch redirect url
   */
  getOrderRedirectionUrl(params: RedirectUrlApiRequest): Observable<RedirectUrlApiResponse> {
    const httpParams: any = {
      ...params,
    };
    return this._http
      .get<CommonApiResponse<RedirectUrlApiResponse>>(`${ENV.backendBaseUrl}/api/orders/getOrderRedirectionUrl`, {
        params: httpParams,
      })
      .pipe(map((res) => res.data));
  }
}
