<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="gen_table table sub_table table-hover">
    <!-- //Listing history table -->
    <ng-container matColumnDef="isPriorSale">
      <th mat-header-cell *matHeaderCellDef>Prior</th>
      <td mat-cell *matCellDef="let element; let i = dataIndex">
        <mat-checkbox
          color="primary"
          [checked]="element.isPriorSale"
          (click)="$event.stopPropagation(); onPriorSaleChange(element, i)"
          [disabled]="!element.isActive"
        >
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="ListPrice">
      <th mat-header-cell *matHeaderCellDef>List Price</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ListPrice | currency: 'USD' : 'symbol' : '1.0-0' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ListingContractDate">
      <th mat-header-cell *matHeaderCellDef>List Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ListingContractDate | date: 'MM-dd-yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ClosePrice">
      <th mat-header-cell *matHeaderCellDef>{{ historyType == 'DEED' ? 'Sold Price' : 'Close Price' }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ClosePrice | currency: 'USD' : 'symbol' : '1.0-0' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="CloseDate">
      <th mat-header-cell *matHeaderCellDef>Close Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.CloseDate | date: 'MM-dd-yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="StandardStatus">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
        <p [standardStatus]="element.StandardStatus">{{ element.StandardStatus }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="ModificationTimestamp">
      <th mat-header-cell *matHeaderCellDef>Modified</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ModificationTimestamp | date: 'MM-dd-yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="MLSListingNumber">
      <th mat-header-cell *matHeaderCellDef>MLS#</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MLSListingNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="MlsBoard">
      <th mat-header-cell *matHeaderCellDef>MLS Board</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MlsBoard }}
      </td>
    </ng-container>

    <!-- //Deed history table  -->
    <ng-container matColumnDef="TransactionRecordingDate">
      <th mat-header-cell *matHeaderCellDef>Recording Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.TransactionRecordingDate | date: 'MM-dd-yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="TransactionContractDate">
      <th mat-header-cell *matHeaderCellDef>Contract Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.TransactionContractDate | date: 'MM-dd-yyyy' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="TransactionDocumentType">
      <th mat-header-cell *matHeaderCellDef>Doc Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.TransactionDocumentType }}
      </td>
    </ng-container>
    <ng-container matColumnDef="TransactionRecordingDocumentId">
      <th mat-header-cell *matHeaderCellDef>Doc ID#</th>
      <td mat-cell *matCellDef="let element">
        {{ element.TransactionRecordingDocumentId }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Buyer1FullName">
      <th mat-header-cell *matHeaderCellDef>Buyer</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.Buyer1FullName && element.Buyer1FullName + ',') || '' }}{{ element.Buyer2FullName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Seller1FullName">
      <th mat-header-cell *matHeaderCellDef>Seller</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.Seller1FullName && element.Seller1FullName + ',') || '' }}{{ element.Seller2FullName }}
      </td>
    </ng-container>

    <!-- Expand configuration -->
    <ng-container matColumnDef="expand">
      <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-icon-button
          aria-label="expand row"
          (click)="expandedElement = expandedElement === element ? null : element; $event.stopPropagation()"
          *ngIf="element?.children?.length"
        >
          <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplayWithExpand.length" style="padding: 0">
        <div [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <table
            mat-table
            [dataSource]="element.children"
            multiTemplateDataRows
            *ngIf="element.children && element.children.length"
          >
            <!-- //Listing history table -->
            <ng-container matColumnDef="isPriorSale">
              <!-- <th mat-header-cell *matHeaderCellDef>Prior</th> -->
              <td mat-cell *matCellDef="let element; let i = dataIndex"></td>
            </ng-container>
            <ng-container matColumnDef="ListPrice">
              <!-- <th mat-header-cell *matHeaderCellDef>List Price</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.ListPrice | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="ListingContractDate">
              <!-- <th mat-header-cell *matHeaderCellDef>List Date</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.ListingContractDate | date: 'MM-dd-yyyy' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="ClosePrice">
              <!-- <th mat-header-cell *matHeaderCellDef>Close Price</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.ClosePrice | currency: 'USD' : 'symbol' : '1.0-0' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="CloseDate">
              <!-- <th mat-header-cell *matHeaderCellDef>Close Date</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.CloseDate | date: 'MM-dd-yyyy' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="StandardStatus">
              <!-- <th mat-header-cell *matHeaderCellDef>Status</th> -->
              <td mat-cell *matCellDef="let element">
                <p [standardStatus]="element.StandardStatus">{{ element.StandardStatus }}</p>
              </td>
            </ng-container>
            <ng-container matColumnDef="ModificationTimestamp">
              <!-- <th mat-header-cell *matHeaderCellDef>Modified</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.ModificationTimestamp | date: 'MM-dd-yyyy' }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="MLSListingNumber">
              <!-- <th mat-header-cell *matHeaderCellDef>MLS#</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.MLSListingNumber }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="MlsBoard">
              <!-- <th mat-header-cell *matHeaderCellDef>MLS Boeard</th> -->
              <td mat-cell *matCellDef="let element">
                <span>{{ element.MlsBoard }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="expand">
              <td
                mat-cell
                *matCellDef="let element"
                (click)="expandedElement = historyType == 'DEED' ? null : expandedElement === element ? null : element"
              ></td>
            </ng-container>
            <!-- <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr> -->
            <tr mat-row *matRowDef="let element; columns: expandedColumnsToDisplay"></tr>
          </table>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplayWithExpand"
      class="example-element-row"
      [ngStyle]="{ 'background-color': element.isActive ? 'initial' : 'rgb(242, 242, 242)' }"
      [class.example-expanded-row]="expandedElement === element"
      [class]="!element.isActive && isEditable ? 'disabled text-muted' : null"
      (click)="onView(element.ListingId)"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>
</div>
