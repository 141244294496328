import { Injectable } from '@angular/core';
import { SlidingSheetConfig } from '../interface/sliding-sheet.interface';
import { SlidingSheetState } from '../state/sliding-sheet.state';

@Injectable({
  providedIn: 'root',
})
export class SlidingSheetService {
  constructor(private slidingSheetState: SlidingSheetState) {}

  loadComponent(component: any, data?: any, config?: SlidingSheetConfig) {
    this.slidingSheetState.dynamicComponent$.next({ component, data, config });
    this.slidingSheetState.config$.next(config);
    return this;
  }

  show() {
    this.slidingSheetState.isOpen$.next(true);
  }

  hide() {
    this.slidingSheetState.isOpen$.next(false);
  }
}
