<div class="profet_checkbox">
  <mat-checkbox
    matRippleDisabled
    color="primary"
    class="checkbox-margin"
    [(ngModel)]="modelCtrlValue"
    [disabled]="isDisabled"
  >
    {{ label }}
  </mat-checkbox>
</div>
