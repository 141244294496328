import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnDestroy, Output } from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'profet-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
})
export class InputComponent implements AfterViewInit, OnDestroy {
  isDisabled: boolean = false;

  // Subscription to unsubscribe all observables on component destroy
  onDestroyNotifier$ = new Subject<void>();

  @Input() label: string | null = null;
  @Input() type: 'text' | 'number' | 'numberString' | 'tel' | 'email' | 'password' = 'text';
  @Input() prefixIcon: string | undefined;
  @Input() prefixText: string | undefined;
  @Input() suffixIcon: string | undefined;
  @Input() suffixText: string | undefined;
  @Input() infoText: string | undefined;
  @Input() hint: string | undefined;
  @Input() hideHint: boolean = false;
  @Input() hidden: boolean = false;
  @Input() required: boolean = false;
  @Input() isPercentage: boolean = false;

  @Output() suffixBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.hidden') get hideClass() {
    return this.hidden;
  }

  @HostBinding('class.hint-hidden') get hintHideClass() {
    return this.hideHint;
  }

  constructor(public ngControl: NgControl) {}

  ngAfterViewInit(): void {
    // Disable the control if it is disabled in the parent form
    this.isDisabled = this.ngControl.status === 'DISABLED';
    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((status) => (this.isDisabled = status === 'DISABLED'));
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  onSuffixBtnClick() {
    this.suffixBtnClicked.emit(true);
  }

  get modelCtrl(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get modelCtrlValue(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (this.ngControl.control as FormControl)?.value;
  }

  set modelCtrlValue(value: any) {
    if (this.ngControl.control) {
      // If Number type, Explicitly cast the value to number
      if (this.type == 'number') {
        value = [null, undefined, ''].includes(value) ? null : Number(value);
        if (isNaN(value)) {
          value = null;
        }
        this.ngControl.control.setValue(value);
      } else {
        this.ngControl.control.setValue(value);
      }
    }
  }
}
