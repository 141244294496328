import { Injectable, Type } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(component: Type<any>, dialogData?: any) {
    return this.dialog.open(component, dialogData);
  }
}
