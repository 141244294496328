import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdjustmentGridConfig } from 'src/app/module/adjustment-grid/config/adjustment-grid.config';
import { GridConfig } from 'src/app/module/adjustment-grid/interface/adjustment.interface';
import { LongTermRentalState } from 'src/app/module/long-term-rental/state/long-term-rental.state';
import { IApproachState } from 'src/app/module/order/interface/approach-state.interface';
import {
  ComparableTableDataSource,
  OrderDetail,
  PropertyMap,
  SecondarySearchFilter,
  ValuationType,
} from 'src/app/module/order/interface/order.interface';
import { CostApproachState } from 'src/app/module/order/state/cost-approach.state';
import { OrderState } from 'src/app/module/order/state/order.state';
import { SalesApproachState } from 'src/app/module/order/state/sales-approach.state';
import { ShortTermRentalState } from 'src/app/module/short-term-rental/state/short-term-rental.state';
import { ValuationOrderSubType } from 'src/app/shared/interface/report.interface';

type SharedKeys = 'selectedComparableList' | 'customValueAdjustmentLineItem' | 'derivedPrice';
type OrderApproach = Pick<OrderDetail, 'salesComparison' | 'costApproach' | 'shortTermRental' | 'longTermRental'>;

@Injectable({
  providedIn: 'root',
})
export class ApproachFactoryState implements IApproachState {
  constructor(
    private orderState: OrderState,
    private salesApproachState: SalesApproachState,
    private costApproachState: CostApproachState,
    private shortTermRentalState: ShortTermRentalState,
    private longTermRentalState: LongTermRentalState
  ) {}

  get comparableList$(): Observable<ComparableTableDataSource[]> | undefined {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.orderState.comparableList$;

      case ValuationType.cost:
        return this.costApproachState.comparableList$;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.comparableList$;

      case ValuationType.longTermRental:
        return this.longTermRentalState.comparableList$;

      default:
        return;
    }
  }

  get comparableList(): ComparableTableDataSource[] {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.orderState.comparableList;

      case ValuationType.cost:
        return this.costApproachState.comparableList;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.comparableList;

      case ValuationType.longTermRental:
        return this.longTermRentalState.comparableList;

      default:
        return [];
    }
  }

  set comparableList(order: ComparableTableDataSource[]) {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        this.orderState.comparableList = order;
        break;

      case ValuationType.cost:
        this.costApproachState.comparableList = order;
        break;

      case ValuationType.shortTermRental:
        this.shortTermRentalState.comparableList = order;
        break;

      case ValuationType.longTermRental:
        this.longTermRentalState.comparableList = order;
        break;

      default:
        break;
    }
  }

  get selectedComparableMap$(): Observable<PropertyMap> | undefined {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.salesApproachState.selectedComparableMap$;

      case ValuationType.cost:
        return this.costApproachState.selectedComparableMap$;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.selectedComparableMap$;

      case ValuationType.longTermRental:
        return this.longTermRentalState.selectedComparableMap$;

      default:
        return;
    }
  }

  get selectedComparableMap(): PropertyMap {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.salesApproachState.selectedComparableMap;

      case ValuationType.cost:
        return this.costApproachState.selectedComparableMap;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.selectedComparableMap;

      case ValuationType.longTermRental:
        return this.longTermRentalState.selectedComparableMap;

      default:
        return {};
    }
  }

  set selectedComparableMap(value: PropertyMap) {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        this.salesApproachState.selectedComparableMap = value;
        break;

      case ValuationType.cost:
        this.costApproachState.selectedComparableMap = value;
        break;

      case ValuationType.shortTermRental:
        this.shortTermRentalState.selectedComparableMap = value;
        break;

      case ValuationType.longTermRental:
        this.longTermRentalState.selectedComparableMap = value;
        break;

      default:
        break;
    }
  }

  get comparableListLength$(): Observable<number> | undefined {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.orderState.comparableListLength$;

      case ValuationType.cost:
        return this.costApproachState.comparableListLength$;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.comparableListLength$;

      case ValuationType.longTermRental:
        return this.longTermRentalState.comparableListLength$;

      default:
        return;
    }
  }

  get comparableListLength(): number {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.orderState.comparableListLength;

      case ValuationType.cost:
        return this.costApproachState.comparableListLength;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.comparableListLength;

      case ValuationType.longTermRental:
        return this.longTermRentalState.comparableListLength;

      default:
        return 0;
    }
  }

  set comparableListLength(length: number) {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        this.orderState.comparableListLength = length;
        break;

      case ValuationType.cost:
        this.costApproachState.comparableListLength = length;
        break;

      case ValuationType.shortTermRental:
        this.shortTermRentalState.comparableListLength = length;
        break;

      case ValuationType.longTermRental:
        this.longTermRentalState.comparableListLength = length;
        break;

      default:
        break;
    }
  }

  get secondarySearchFilter(): SecondarySearchFilter | null {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return this.orderState.secondarySearchFilter;

      case ValuationType.cost:
        return this.costApproachState.secondarySearchFilter;

      case ValuationType.shortTermRental:
        return this.shortTermRentalState.secondarySearchFilter;

      case ValuationType.longTermRental:
        return this.longTermRentalState.secondarySearchFilter;

      default:
        return null;
    }
  }

  set secondarySearchFilter(filter: SecondarySearchFilter | null) {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        this.orderState.secondarySearchFilter = filter;
        break;

      case ValuationType.cost:
        this.costApproachState.secondarySearchFilter = filter;
        break;

      case ValuationType.shortTermRental:
        this.shortTermRentalState.secondarySearchFilter = filter;
        break;

      case ValuationType.longTermRental:
        this.longTermRentalState.secondarySearchFilter = filter;
        break;

      default:
        break;
    }
  }

  get activeApproachKey(): keyof OrderApproach {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return 'salesComparison';

      case ValuationType.cost:
        return 'costApproach';

      case ValuationType.shortTermRental:
        return 'shortTermRental';

      case ValuationType.longTermRental:
        return 'longTermRental';

      default:
        return 'salesComparison';
    }
  }

  get approachKeyBasedOnReportType(): keyof OrderApproach {
    switch (this.orderState.orderType) {
      case ValuationOrderSubType.desktop:
        return 'salesComparison';

      case ValuationOrderSubType.rental:
        return 'shortTermRental';

      default:
        return 'salesComparison';
    }
  }

  get activeApproachPage(): string {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return 'sales-approach';

      case ValuationType.cost:
        return 'cost-approach';

      case ValuationType.shortTermRental:
        return 'short-term-rental';

      case ValuationType.longTermRental:
        return 'long-term-rental';

      default:
        return 'sales-approach';
    }
  }

  getOrderValue<T extends SharedKeys>(order: OrderDetail, key: T): OrderDetail[keyof OrderDetail][T] {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return order.salesComparison[key];

      case ValuationType.cost:
        return order.costApproach?.[key];

      case ValuationType.shortTermRental:
        return order.shortTermRental?.[key];

      case ValuationType.longTermRental:
        return order.longTermRental?.[key];

      default:
        return;
    }
  }

  get gridConfig(): GridConfig {
    switch (this.orderState.activeValuationType) {
      case ValuationType.sales:
        return AdjustmentGridConfig.salesApproachGridConfig;

      case ValuationType.cost:
        return AdjustmentGridConfig.costApproachGridConfig;

      case ValuationType.shortTermRental:
        return AdjustmentGridConfig.strApproachGridConfig;

      case ValuationType.longTermRental:
        return AdjustmentGridConfig.ltrApproachGridConfig;

      default:
        return AdjustmentGridConfig.salesApproachGridConfig;
    }
  }
}
