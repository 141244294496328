/* eslint-disable @typescript-eslint/no-unsafe-return */
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import {
  ExtendedPropertyDetail,
  GenerateListingSheetApiRequest,
  PropertyDataApiRequest,
  PropertyPageView,
  PropertyViewApiRequest,
  PropertyViewApiResponse,
} from '../interface/property.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  getActivePropertyPageView(params: PropertyViewApiRequest): Observable<PropertyPageView> {
    const httpParams: any = { ...params };
    return this._http
      .get<CommonApiResponse<PropertyViewApiResponse>>(`${ENV.backendBaseUrl}/api/getPageLayoutDetails`, {
        params: httpParams,
      })
      .pipe(
        map((res) => res.data.pageLayout),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  getActivePropertyPageData(params: PropertyDataApiRequest): Observable<ExtendedPropertyDetail> {
    const httpParams: any = { ...params };
    return this._http
      .get<CommonApiResponse<ExtendedPropertyDetail>>(`${ENV.backendBaseUrl}/api/getPageData`, {
        params: httpParams,
      })
      .pipe(
        map((res) => res.data),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /**
   * Fetching street view or satellite view
   * @param url
   * @param params
   * @returns
   */
  getStreetOrSatelliteView(url: string, params: any) {
    const httpParams: any = {
      ...params,
    };

    return this._http
      .get(url, {
        params: httpParams,
        responseType: 'blob',
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(() => error);
        })
      );
  }

  initPropertyPdfGeneration(params: GenerateListingSheetApiRequest): Observable<any> {
    let httpParams = new HttpParams()
      .set('api', '/reports/v1/GenerateListingSheet')
      .set('authType', 'app_static')
      .set('apiType', 'data');

    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        httpParams = httpParams.set(key, value);
      }
    }

    return this._http.get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, {
      params: httpParams,
    });
  }

  fetchGeneratedPropertyPdf(referenceId: number): Observable<any> {
    const params = new HttpParams()
      .set('api', '/reports/v1/GetListingSheet')
      .set('authType', 'app_static')
      .set('apiType', 'data')
      .set('ReferenceId', referenceId);

    return this._http.get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, { params });
  }
}
