import { Component, OnDestroy, OnInit } from '@angular/core';
import { interval, Subject, takeUntil } from 'rxjs';
import { ProgressLoaderService } from '../../services/progress-loader.service';

@Component({
  selector: 'app-progress-loader',
  templateUrl: './progress-loader.component.html',
  styleUrls: ['./progress-loader.component.scss'],
})
export class ProgressLoaderComponent implements OnInit, OnDestroy {
  ellipsis = '';

  onDestroyNotifier$ = new Subject<void>();

  constructor(public progressLoaderService: ProgressLoaderService) {}

  ngOnInit(): void {
    interval(500)
      .pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe(() => {
        this.ellipsis = this.ellipsis.length < 3 ? this.ellipsis + '.' : '';
      });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }
}
