import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PaginatorConfig } from '../../model/paginator-config';
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges {
  dataSize!: number;
  pageSize!: number;
  pageIndex!: number;
  pageSizeOptions!: number[];

  @Input() paginationData!: PaginatorConfig;
  @Output() paginatorAction = new EventEmitter<PageEvent>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnChanges(): void {
    this.dataSize = this.paginationData.dataSize ?? 0;
    this.pageSize = this.paginationData.pageSize ?? 10;
    this.pageIndex = this.paginationData.pageIndex ?? 0;
    this.pageSizeOptions = this.paginationData.pageSizeOptions ?? [10, 25, 50, 100];
  }

  pageChange(event: PageEvent) {
    this.paginatorAction.emit(event);
  }
}
