import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, debounceTime, takeUntil } from 'rxjs';

import { SelectOptionData } from 'src/app/shared/interface/profet-control-widget.interface';

@Component({
  selector: 'profet-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
})
export class SelectComponent implements AfterViewInit, OnDestroy {
  /** Stores unaltered value received from input */
  options: Array<SelectOptionData> = [];

  /** Stores all or filtered options based on search */
  filteredOptions: Array<SelectOptionData> = [];

  searchCtrl: FormControl = new FormControl([]);

  isDisabled: boolean = false;

  onDestroyNotifier$ = new Subject<void>();

  @Input() label: string | null = null;
  @Input() outerLabel: string | null = null;
  @Input() multiple: boolean = false;
  @Input() showEmpty: boolean = false;
  @Input() canSearch: boolean = true;
  @Input() required: boolean = false;
  @Input() inProgress: boolean = false;

  @Input('options') set _options(value: Array<SelectOptionData>) {
    this.options = value;
    setTimeout(() => {
      this._filterOptions();
    });
  }

  @ViewChild(MatSelect) matSelect?: MatSelect;
  @ViewChild('searchInput', { read: ElementRef }) searchInput?: ElementRef;

  @HostBinding('class.in-progress') get inProgressClass() {
    return this.inProgress;
  }

  constructor(
    public ngControl: NgControl,
    private _cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    // Disable the control if it is disabled in the parent form
    this.isDisabled = this.ngControl.status === 'DISABLED';
    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((status) => (this.isDisabled = status === 'DISABLED'));

    this.searchCtrl.valueChanges.pipe(takeUntil(this.onDestroyNotifier$), debounceTime(300)).subscribe((res) => {
      this._filterOptions(res);
      this._cdr.detectChanges();
    });

    this.matSelect?.openedChange.pipe(takeUntil(this.onDestroyNotifier$)).subscribe((isOpen) => {
      if (!isOpen) this.searchCtrl.setValue(null);
      this._focusSearchInput();
    });
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  compareWithFn(option: string, selection: string) {
    return option == selection;
  }

  private _filterOptions(searchText?: string) {
    if (!searchText) {
      if (this.showEmpty) {
        if (!this.options?.length) this.filteredOptions = [{ key: null, displayValue: '(None)' }];
        else this.filteredOptions = [{ key: null, displayValue: '(None)' }, ...this.options];
      } else this.filteredOptions = this.options;
    } else {
      this.filteredOptions = this.options.filter((option) => {
        if (option.children) {
          return option.children.filter((type) => type.displayValue?.toLowerCase().includes(searchText.toLowerCase()))
            ?.length
            ? true
            : false;
        } else {
          return option.displayValue?.toLowerCase().includes(searchText.toLowerCase());
        }
      });
    }
  }

  private _focusSearchInput() {
    if (!this.searchInput) return;

    this.searchInput.nativeElement.focus();
  }

  get modelCtrl(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get modelCtrlValue(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (this.ngControl.control as FormControl)?.value;
  }

  set modelCtrlValue(value: any) {
    if (this.ngControl.control) this.ngControl.control.setValue(value);
  }
}
