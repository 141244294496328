import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, catchError, finalize, map, of, switchMap, tap } from 'rxjs';
import { ValuationType } from 'src/app/module/order/interface/order.interface';
import { OrderState } from 'src/app/module/order/state/order.state';
import { EndPoints } from 'src/app/shared/api-service/api-endpoints';
import { ApiGatewayService } from 'src/app/shared/api-service/api-gateway.service';
import { InitializerService } from 'src/app/shared/initializer/initializer.service';
import { AppEnumMap } from 'src/app/shared/interface/common.interface';
import { ExceptionConfig } from 'src/app/shared/model/error.model';
import { JsonFormControls } from 'src/app/shared/model/json-form.model';
import { ProgressLoaderService } from 'src/app/shared/services/progress-loader.service';
import { AppState } from 'src/app/shared/state/app.state';
import { CompanyState } from 'src/app/shared/state/company.state';
import { ReportState } from 'src/app/shared/state/report.state';
import { UserState } from 'src/app/shared/state/user.state';
import { Utilities } from 'src/app/shared/utility/utilities';
import { OrderListConfig } from '../../order-list/config/order-list.config';
import { OrderListService } from '../../order-list/service/order-list.service';
import { OrderListState } from '../../order-list/state/order-list.state';
import { CompanySettingApi } from '../api/company-setting.api';
import { CompanyDetail } from '../interface/company-setting.interface';
import { CompanySettingState } from '../state/company-setting.state';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private companySettingApi: CompanySettingApi,
    private companySettingState: CompanySettingState,
    private companyState: CompanyState,
    private userState: UserState,
    private appState: AppState,
    private reportState: ReportState,
    private initializerService: InitializerService,
    private progressLoaderService: ProgressLoaderService,
    private orderListState: OrderListState,
    private orderListService: OrderListService,
    private orderState: OrderState
  ) {}

  /**
   * @deprecated
   * Use CompanySettingService.getCompanyDetail instead
   */
  getCompanySettings(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.getCompanySettings + '?' + Utilities.encodeQueryParam(reqBody);
    return this.apiGatewayService.doGet(url, exptnConfig);
  }

  getCompanyDetail() {
    this.companySettingApi.getCompanyDetail(this.companyState.companySetting.companyKey).subscribe((res) => {
      this.companySettingState.companyDetail = res;
    });
  }

  updateCompanyDetail(companyDetail: CompanyDetail) {
    const updatedData = {
      id: this.companyState.companySetting.id,
      companyKey: this.companyState.companySetting.companyKey,
      settings: companyDetail,
    };
    this.companySettingApi.updateCompanyDetail(this.companyState.companySetting.id, updatedData).subscribe((res) => {
      this.companySettingState.companyDetail = res;
      this.companyState.companySetting = {
        ...this.companyState.companySetting,
        ...(res as any),
      };
    });
  }

  /** @deprecated */
  updateCompanySettings(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.updateCompanySettings + '/' + reqBody.id;
    return this.apiGatewayService.doPatch(url, reqBody, exptnConfig);
  }

  /** Get details of an order by order id */
  getCompanyFormSetting(): Observable<boolean> {
    return this.companySettingApi.getCompanyFormSetting().pipe(
      map(({ formList }) => {
        this.companySettingState.formList = formList;
        return false;
      })
    );
  }

  /** @deprecated */
  getCompsTableSettingForm(): any {
    const response: any = {};
    const companyDetail = this.companySettingState.companyDetail;

    const tableValues =
      this.orderState.activeValuationType == ValuationType.cost
        ? structuredClone(companyDetail.costApproachTableSettings ?? [])
        : this.orderState.activeValuationType == ValuationType.shortTermRental
          ? structuredClone(companyDetail.shortTermRentalCompsTableSettings ?? [])
          : structuredClone(companyDetail.compsTableSettings ?? []);

    const jsonPath =
      this.orderState.activeValuationType == ValuationType.cost
        ? 'settings.costApproachTableSettings'
        : this.orderState.activeValuationType == ValuationType.shortTermRental
          ? 'settings.shortTermRentalCompsTableSettings'
          : 'settings.compsTableSettings';

    // const formEnumList = this.companySettingState.formEnumList;
    const jsonFormData = this.companySettingState.formList.find((form) => form.id == 'general');
    const jsonForm = new FormGroup({});
    const dependentFields = new Set();

    if (jsonFormData?.controls)
      (jsonFormData.controls as JsonFormControls[]) = (jsonFormData?.controls as JsonFormControls[])?.map(
        (control: JsonFormControls) => {
          control['value'] = tableValues;
          control['enums'] = this.appState.enumMap[control.enumType as keyof AppEnumMap] ?? [];
          let template: any = [];
          if (['orderedListEnum', 'orderedListString', 'orderedListEditableString'].includes(control.displayType)) {
            const enums = [...control.enums];
            if (control.value) {
              control.value.forEach((enumValue: any) => {
                if (enums.length) {
                  const index = enums.findIndex((data) => data.enumValue == enumValue);
                  if (index > -1) {
                    template.push(enums[index]);
                    enums.splice(index, 1);
                  }
                } else {
                  template.push(enumValue);
                }
              });
            }

            template = [...template, ...enums];
          }
          control['template'] = template;

          return control;
        }
      );

    (jsonFormData?.controls as JsonFormControls[]).forEach((element) => {
      const validatorsToAdd: any[] = [];
      const asyncValidatorToAdd: any[] = [];

      for (const [key, value] of Object.entries(element.displayTypeValidators)) {
        switch (key) {
          case 'required':
            if (value) validatorsToAdd.push(Validators.required);
            break;
          default:
            break;
        }
      }

      const validators = {
        validatorsToAdd: validatorsToAdd,
        asyncValidatorToAdd: asyncValidatorToAdd,
      };

      const isEnable = this._checkDependency(jsonForm, element);

      element['isEnable'] = isEnable;
      if (element.dependentOn) {
        for (const key in element.dependentOn) {
          dependentFields.add(key);
        }
      }
      jsonForm.addControl(
        element.name,
        new FormControl(
          { value: element.value, disabled: !isEnable },
          validators.validatorsToAdd,
          validators.asyncValidatorToAdd
        )
      );
    });

    dependentFields?.forEach((key: any) => {
      jsonForm.get(key)?.valueChanges.subscribe(() => {
        const fields = (jsonFormData?.controls as JsonFormControls[]).filter((ctrl: JsonFormControls) => {
          return ctrl.dependentOn && Object.prototype.hasOwnProperty.call(ctrl.dependentOn, key);
        });
        fields?.forEach((element) => {
          const isEnable = this._checkDependency(jsonForm, element);
          if (isEnable) {
            jsonForm.get(element.name)?.enable();
            element['isEnable'] = true;
          } else {
            jsonForm.get(element.name)?.disable();
            element['isEnable'] = false;
          }
        });
      });
    });

    response.fg = jsonForm;
    response.field = jsonFormData?.controls.find((control) => control.jsonPath == jsonPath);
    response.control = jsonForm.get(response.field.name) as FormControl;

    return response;
  }

  getAppSetting() {
    return this.companySettingApi.getApplicationSetting().pipe(
      map((setting) => {
        this.companyState.companySetting = setting.companySettings || {};
        this.userState.userSetting = setting.userSettings || {};
        this.reportState.reportSetting =
          this.initializerService.formatReportSetting(setting.orderTypeList, this.appState.enumMap) ?? [];
        return true;
      }),
      catchError(() => of(false))
    );
  }

  switchCompany(companyKey: string): Observable<boolean> {
    this.progressLoaderService.show().message('Switching Company');

    return this.companySettingApi.switchCompany(companyKey).pipe(
      switchMap(() =>
        this.getAppSetting().pipe(
          tap(() => {
            this.orderListState.orderFilter = {
              ...this.orderListState.orderFilter,
              pageLimit: OrderListConfig.defaultFilter.pageLimit,
              pageNumber: OrderListConfig.defaultFilter.pageNumber,
            };
            this.orderListService.getUserList(companyKey).subscribe();
          }),
          map(() => true)
        )
      ),
      catchError(() => {
        return of(false);
      }),
      finalize(() => this.progressLoaderService.hide())
    );
  }

  /** @deprecated */
  private _checkDependency = (jsonForm: any, control: JsonFormControls) => {
    if (
      !control.dependentOn ||
      (control.dependentOn &&
        Object.keys(control.dependentOn)?.every(
          (name: string) =>
            jsonForm.get(name)?.value == control.dependentOn[name] ||
            control.dependentOn[name].toString().includes(jsonForm.get(name)?.value?.toString())
        ))
    ) {
      return true;
    } else {
      return false;
    }
  };
}
