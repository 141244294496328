import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderDetail } from '../interface/order.interface';
import { ValueReconciliation } from '../interface/value-reconciliation.interface';
import { OrderState } from './order.state';

@Injectable({
  providedIn: 'root',
})
export class ValueReconciliationState {
  constructor(private orderState: OrderState) {}

  private _valueReconciliationSub$ = new BehaviorSubject<ValueReconciliation | null>(null);
  valueReconciliation$ = this._valueReconciliationSub$.asObservable();

  get valueReconciliation(): ValueReconciliation | null {
    return this._valueReconciliationSub$.value;
  }

  set valueReconciliation(value: ValueReconciliation | null) {
    this._valueReconciliationSub$.next(value);

    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    if (order.valueReconciliation && value) order.valueReconciliation = value;

    this.orderState.orderDetail = order;
  }
}
