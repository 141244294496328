import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, throwError } from 'rxjs';
import { ApplicationSettingApiResponse, CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import {
  CompanyDetail,
  CompanyDetailApiResponse,
  CompanyFormSettingApiResponse,
  CompanySettingForm,
} from '../interface/company-setting.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  getCompanySetting(params: any): Observable<any> {
    const httpParams: any = { ...params };
    return this._http.get(`${ENV.backendBaseUrl}/api/getPageLayoutDetails`, { params: httpParams }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return of();
      })
    );
  }

  updateCompanySetting(params: any): Observable<any> {
    const httpParams: any = { ...params };
    return this._http.get(`${ENV.backendBaseUrl}/api/getPageLayoutDetails`, { params: httpParams }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  /** Get details of a company */
  updateCompanyDetail(companyId: string, params: any): Observable<CompanyDetail> {
    const httpParams: any = params;
    return this._http
      .patch<CommonApiResponse<CompanyDetailApiResponse>>(
        `${ENV.backendBaseUrl}/api/companySettingses/${companyId}`,
        httpParams
      )
      .pipe(
        map((res) => res.data.companySettings.settings),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Get details of a company */
  getCompanyDetail(companyKey: string): Observable<CompanyDetail> {
    const httpParams: any = { companyKey };
    return this._http
      .get<CommonApiResponse<CompanyDetailApiResponse>>(
        `${ENV.backendBaseUrl}/api/companySettingses/search/findCompanyByCompanyKey`,
        {
          params: httpParams,
        }
      )
      .pipe(
        map((res) => res.data.companySettings.settings),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /** Get form details for company settings form */
  getCompanyFormSetting(): Observable<{ formList: CompanySettingForm[] }> {
    const httpParams: any = { formName: 'companySettings' };
    return this._http
      .get<CommonApiResponse<CompanyFormSettingApiResponse>>(`${ENV.backendBaseUrl}/api/getAllFormMetadata`, {
        params: httpParams,
      })
      .pipe(
        map((res) => {
          return { formList: res.data.sections };
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return of();
        })
      );
  }

  /**
   * Get Application Settings
   */
  getApplicationSetting() {
    return this._http
      .get<CommonApiResponse<ApplicationSettingApiResponse>>(`${ENV.backendBaseUrl}/api/getApplicableSettings`)
      .pipe(
        map((res) => res.data.applicableSettings),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  /**
   *Switch company
   */
  switchCompany(companyKey: string): Observable<CommonApiResponse<void>> {
    return this._http
      .post<CommonApiResponse<void>>(
        `${ENV.backendBaseUrl}/api/companySettingses/changeUserCompany?companyKey=${companyKey}`,
        {}
      )
      .pipe(
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }
}
