import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserSettingForm } from '../interface/user-setting.interface';

@Injectable({
  providedIn: 'root',
})
export class UserSettingState {
  /** Stores details related to forms to update company settings */
  private _formListSub$ = new BehaviorSubject<UserSettingForm[]>([]);
  formList$ = this._formListSub$.asObservable();

  get formList(): UserSettingForm[] {
    return this._formListSub$.value;
  }

  set formList(formList: UserSettingForm[]) {
    this._formListSub$.next(formList);
  }
}
