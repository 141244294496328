import { AppEnumMap, BasicDataType } from 'src/app/shared/interface/common.interface';
import { ValuationOrderSubType } from 'src/app/shared/interface/report.interface';
import { FileType } from '../../file-viewer/interface/file-viewer.interface';
import { AsyncDataStatusType, PropertyDataSourceType, ValuationType } from '../../order/interface/order.interface';
import { OrderMultiDataSource } from '../../order/interface/value-reconciliation.interface';

export type PropertyAttribute =
  | 'AboveGradeFinishedArea'
  | 'AboveGradeFinishedAreaSource'
  | 'AboveGradeFinishedAreaUnits'
  | 'adjustedPerSquareFeet'
  | 'adjustedPrice'
  | 'adjustedPricePerSquareFeet'
  | 'adjustmentValue'
  | 'AgentAddress'
  | 'AgentRemarks'
  | 'appeal'
  | 'Appliances'
  | 'ArchitecturalStyle'
  | 'AssessedImprovementValue'
  | 'AssessedLandValue'
  | 'AssessedValue'
  | 'AssessedYear'
  | 'AssessorsMapReference'
  | 'AssociationFee'
  | 'AssociationFeeFrequency'
  | 'AssociationFeeIncludes'
  | 'AssociationName'
  | 'AssociationYN'
  | 'AttachedGarage'
  | 'AttachedGarageYN'
  | 'AttachmentType'
  | 'AverageDailyRent'
  | 'Basement'
  | 'BasementBathroomsFull'
  | 'BasementBathroomsHalf'
  | 'BasementBedrooms'
  | 'BasementEntry'
  | 'BasementOther'
  | 'BasementRecRooms'
  | 'BasementType'
  | 'BathroomsDecimal'
  | 'BathroomsFull'
  | 'BathroomsFullCalculated'
  | 'BathroomsHalf'
  | 'BathroomsHalfCalculated'
  | 'bathroomsTotal'
  | 'BathroomsTotalInteger'
  | 'BedroomsTotal'
  | 'BelowGradeFinishedArea'
  | 'BelowGradeFinishedAreaSource'
  | 'BelowGradeFinishedAreaUnits'
  | 'BelowGradeTotalArea'
  | 'BuildingAreaTotal'
  | 'BuildingCondition'
  | 'BuiltInGarage'
  | 'BuyerAgentEmail'
  | 'BuyerAgentFullName'
  | 'BuyerAgentOfficePhone'
  | 'BuyerFinancing'
  | 'BuyerOfficeEmail'
  | 'BuyerOfficeName'
  | 'BuyerOfficePhone'
  | 'Carport'
  | 'CarportSpaces'
  | 'CarportYN'
  | 'CarStorageType'
  | 'CensusTractId'
  | 'City'
  | 'CleaningFee'
  | 'CloseDate'
  | 'ClosePrice'
  | 'comments'
  | 'Concessions'
  | 'ConcessionsAmount'
  | 'ConcessionsComments'
  | 'ConstQuality'
  | 'ConstructionType'
  | 'ContractDate'
  | 'Cooling'
  | 'CoolingType'
  | 'CountyOrParish'
  | 'Covered'
  | 'CoveredSpaces'
  | 'coverImageUrl'
  | 'DataSource'
  | 'DaysAvailableLast12Months'
  | 'DaysBlockedLast12Months'
  | 'DaysOnMarket'
  | 'DaysReservedLast12Months'
  | 'derived_adjustedMonthlyRent'
  | 'derivedAttachmentType'
  | 'derivedHeating'
  | 'derivedLotFeatures'
  | 'derivedView'
  | 'DetachedGarage'
  | 'Distance'
  | 'DistressedYN'
  | 'DistressType'
  | 'Driveway'
  | 'ElementarySchool'
  | 'ElementarySchoolDistrict'
  | 'EntryLevel'
  | 'EntryLocation'
  | 'ExteriorFeatures'
  | 'FIPS'
  | 'FireplaceFeatures'
  | 'FireplacesTotal'
  | 'FireplaceYN'
  | 'Flooring'
  | 'Foundation'
  | 'FoundationDetails'
  | 'FoundationWalls'
  | 'FunctionalUtility'
  | 'Furnished'
  | 'GarageArea'
  | 'GarageNumber'
  | 'GarageSpaces'
  | 'GarageYN'
  | 'GreenEnergyEfficient'
  | 'GreenEnergyGeneration'
  | 'Heating'
  | 'HeatingFuelType'
  | 'HeatingType'
  | 'HighSchool'
  | 'HighSchoolDistrict'
  | 'hoaDues'
  | 'HostFee'
  | 'ImageURLs'
  | 'InteriorFeatures'
  | 'LastSaleContractDate'
  | 'LastSaleDate'
  | 'LastSaleDocumentType'
  | 'LastSalePrice'
  | 'LastSaleRecordingDate'
  | 'Latitude'
  | 'LegalSubdivisionName'
  | 'Levels'
  | 'ListAgentEmail'
  | 'ListAgentFullName'
  | 'ListAgentPreferredPhone'
  | 'ListedRent'
  | 'ListingContractDate'
  | 'ListingDescription'
  | 'ListingId'
  | 'ListingType'
  | 'ListOfficeEmail'
  | 'ListOfficeName'
  | 'ListOfficePhone'
  | 'ListPrice'
  | 'ListPriceMonthly'
  | 'ListPriceNightly'
  | 'ListPriceWeekly'
  | 'LivingArea'
  | 'LivingAreaSource'
  | 'LivingAreaUnits'
  | 'LocationFactors'
  | 'LocationRating'
  | 'Longitude'
  | 'LotFeatures'
  | 'LotSizeAcres'
  | 'LotSizeDimensions'
  | 'LotSizeSquareFeet'
  | 'MainLevelBathrooms'
  | 'MainLevelBedrooms'
  | 'MarketImprovementValue'
  | 'MarketLandValue'
  | 'MarketValue'
  | 'MiddleOrJuniorSchool'
  | 'MiddleOrJuniorSchoolDistrict'
  | 'MlsBoard'
  | 'MLSListingNumber'
  | 'MlsStatus'
  | 'ModificationTimestamp'
  | 'NeighborhoodLocation'
  | 'OccupancyRate'
  | 'OfficeAddress1'
  | 'OffMarketTimestamp'
  | 'OpenParking'
  | 'OpenParkingSpaces'
  | 'OpenParkingYN'
  | 'OriginalEntryTimestamp'
  | 'OriginalListPrice'
  | 'OriginatingSystemName'
  | 'OtherParking'
  | 'Owner1FullName'
  | 'Owner2FullName'
  | 'OwnerOccupied'
  | 'ParcelNumber'
  | 'ParkingFeatures'
  | 'ParkingTotal'
  | 'PatioAndPorchFeatures'
  | 'PatioType'
  | 'PendingTimestamp'
  | 'PeopleCapacity'
  | 'PMXPropertyId'
  | 'PoolFeatures'
  | 'PoolPrivateYN'
  | 'PoolType'
  | 'PorchType'
  | 'PostalCode'
  | 'pricePerSquareFeet'
  | 'priorSaleDate'
  | 'priorSalePrice'
  | 'PropertyAttachedYN'
  | 'propertyCity'
  | 'propertyImageURLs'
  | 'propertyLatitude'
  | 'propertyLongitude'
  | 'propertyRights'
  | 'propertyState'
  | 'propertyStreetAddress'
  | 'PropertySubType'
  | 'PropertyType'
  | 'propertyZip'
  | 'PublicRemarks'
  | 'RentalIncome'
  | 'RentalSource'
  | 'RentalSourceUrl'
  | 'RentalType'
  | 'RevenueLast12Months'
  | 'Roof'
  | 'RoomsTotal'
  | 'RVParkingDimensions'
  | 'SeniorCommunityYN'
  | 'SimilarityScore'
  | 'Source'
  | 'SourcePropertyId'
  | 'SpecialListingConditions'
  | 'StandardStatus'
  | 'StateOrProvince'
  | 'StatusChangeTimestamp'
  | 'Stories'
  | 'StoriesTotal'
  | 'StreetAdditionalInfo'
  | 'StreetDirPrefix'
  | 'StreetDirSuffix'
  | 'StreetName'
  | 'StreetNumber'
  | 'StreetNumberNumeric'
  | 'StreetSuffix'
  | 'StreetSuffixModifier'
  | 'SubdivisionName'
  | 'subjectCity'
  | 'subjectState'
  | 'subjectStreetAddress'
  | 'subjectZip'
  | 'TaxAnnualAmount'
  | 'TaxLegalDescription'
  | 'TaxYear'
  | 'UnitNumber'
  | 'UnparsedAddress'
  | 'View'
  | 'ViewFactors'
  | 'ViewRating'
  | 'WaterFrontFeatures'
  | 'WaterSource'
  | 'WaterYN'
  | 'weightage'
  | 'YearBuilt'
  | 'YearBuiltEffective'
  | 'Zoning'
  | 'ZoningCompliance'
  | 'ZoningDescription';

export type PropertyDetail = {
  [key in PropertyAttribute]?: PropertyAttributeValue;
};

export type ExtendedPropertyDetail = PropertyDetail & {
  id?: string;
  valuationType?: ValuationType;
  deedHistory?: PropertyAttributeValue<Array<DeedHistory>>;
  listingHistory?: PropertyAttributeValue<Array<ListingHistory>>;
  imageWithLabels?: PropertyAttributeValue<Array<ImageRecord>>;
  roomDetails?: PropertyAttributeValue<PropertyRoomCharacter>;
};

export type PropertyFieldConfigMap = {
  [key in PropertyAttribute]: PropertyFieldConfig;
};

export interface PropertyFieldConfig {
  fieldDisplayName: string;
  datatype: BasicDataType;
  enumKey: keyof AppEnumMap | null;
  prefix: string | null;
  suffix: string | null;

  /** In case of derived fields, this will be the list of fields that were used to derive this field's value. */
  referenceFieldList: Array<PropertyAttribute>;

  /** If true, user can enter custom value. Default is false. */
  acceptCustomValue: boolean;

  /** If true, multiple values can be selected from the dropdown. Default is false. */
  isMultiSelect: boolean;

  /** Number of decimal digits to be allowed. Default is 0. */
  maxFractionDigits: number | null;

  /** If isMultiSelect is true, then this will be the max number of selections allowed. */
  maxSelection: number | null;

  /** This prefix will be used for abbreviated field value */
  abbreviationPrefix: string | null;

  /** This suffix will be used for abbreviated field value */
  abbreviationSuffix: string | null;

  /** If true, autoComputed adjustment value will not be used for calculation. Default is false. */
  ignoreAutoAdjustment: boolean;

  /** Enforce max value on input field */
  maxValue?: number | null;

  /** Enforce min value on input field */
  minValue?: number | null;
}

export interface PropertyPageView {
  companyKey: string;
  reportType: string;
  pageName: string;
  sectionList: PropertyPageViewSection[];
}

export interface PropertyPageViewSection {
  sectionName: string;
  sectionDisplayName: null | string;
  layoutStructure: 'KEY_VALUE' | 'PARAGRAPH' | 'ROOM_DESCRIPTION' | 'LISTING_HISTORY' | 'DEED_HISTORY' | 'IMAGE_GRID';
  editable: boolean;
  cssStyleSet?: Record<string, string>;
  cssClassList?: string[];
  showValidation?: boolean;
  collapsible?: boolean;
  fieldInfoList: PropertyPageViewField[];
}

export interface PropertyPageViewField {
  fieldName: PropertyAttribute;
  editable: boolean;
  cssStyleSet?: Record<string, string>;
  cssClassList?: string[];
  showValidation?: boolean;
}

export enum PropertyPageViewFieldType {
  date = 'date',
  dropdown = 'dropdown',
  string = 'string',
  text = 'text',
}

export enum PropertyPageViewDataFormat {
  currency = 'currency',
  date = 'date',
  deed = 'deed',
  email = 'email',
  empty = '',
  history = 'history',
  image = 'image',
  number = 'number',
  number0 = 'number0',
  rooms = 'rooms',
  ynBoolean = 'YNBoolean',
}

export enum StandardStatusType {
  active = 'Active',
  activeUC = 'Active Under Contract',
  pending = 'Pending',
  sold = 'Sold',
  canceled = 'Cancel/Withdrawn',
  expired = 'Expired',
  delisted = 'Delisted',
}

export interface PropertyAttributeValue<T = any> {
  adjustmentValue: OrderMultiDataSource<number>;
  listingRecord: T;
  publicRecord: T;
  inspectionRecord: T;
  rentalRecord: T;
  userRecord: T;
  selectedSource: PropertyDataSourceType;
  asyncDataStatus: AsyncDataStatusType | null;

  /** @deprecated */
  format: BasicDataType;

  /** @deprecated */
  fieldDisplayName: null | string;

  /** @deprecated */
  fieldEnumType: keyof AppEnumMap | null;

  /** @deprecated */
  required: boolean;

  /** @deprecated */
  prefix?: string | null;

  /** @deprecated */
  suffix?: string | null;

  /** @deprecated */
  selectedValue?: string | number | null;
}

export interface PropertyHistory {
  Address?: string;
  AgentAddress?: string;
  AlternateListingNumber?: string;
  Appliances?: string;
  ArchitecturalStyle?: string;
  Basement?: string;
  BathroomsDecimal?: string;
  BathroomsTotalInteger?: string;
  bathroomsTotal?: string;
  BedroomsTotal?: string;
  City?: string;
  CloseDate?: Date;
  ClosePrice?: string;
  CommunityFeatures?: string;
  ConstructionMaterials?: string;
  Cooling?: string;
  DataSource?: string;
  DaysOnMarket?: string;
  DistressedYN?: string;
  DistressType?: string;
  ElementarySchool?: string;
  ElementarySchoolDistrict?: string;
  EventTimestamp?: Date;
  ExteriorFeatures?: string;
  FIPS?: string;
  FireplaceFeatures?: string;
  FireplacesTotal?: string;
  FireplaceYN?: string;
  GarageSpaces?: string;
  GarageYN?: string;
  Heating?: string;
  HighSchool?: string;
  HighSchoolDistrict?: string;
  InteriorFeatures?: string;
  Latitude?: string;
  ListAgentEmail?: string;
  ListAgentFullName?: string;
  ListAgentPreferredPhone?: string;
  ListAgentStateLicense?: string;
  ListingContractDate?: string;
  ListingId?: string;
  ListingKey?: string;
  ListingType?: string;
  ListOfficeEmail?: string;
  ListOfficeName?: string;
  ListOfficePhone?: string;
  ListPrice?: string;
  LivingArea?: string;
  Longitude?: string;
  LotFeatures?: string;
  LotSizeDimensions?: string;
  LotSizeSquareFeet?: string;
  MajorChangeTimestamp?: string;
  MiddleOrJuniorSchool?: string;
  MiddleOrJuniorSchoolDistrict?: string;
  MlsBoard?: string;
  MLSListingNumber?: string;
  MlsStatus?: MlsStatusType;
  ModificationTimestamp?: Date;
  OfficeAddress1?: string;
  OffMarketDate?: Date;
  OffMarketTimestamp?: Date;
  OnMarketDate?: string;
  OtherStructures?: string;
  ParcelNumber?: string;
  ParkingFeatures?: string;
  PatioAndPorchFeatures?: string;
  PendingTimestamp?: Date;
  PMXPropertyId?: string;
  PoolFeatures?: string;
  PostalCode?: string;
  PostalCodePlus4?: string;
  PropertyRefID?: string;
  PropertySubType?: string;
  PropertyType?: string;
  PublicRemarks?: string;
  Roof?: string;
  RoomsTotal?: string;
  StandardStatus?: string;
  StateOrProvince?: string;
  StatusChangeTimestamp?: Date;
  StdAddress: {
    City?: string;
    PostalCode?: string;
    StateOrProvince?: string;
    UnparsedAddress?: string;
  };
  StoriesDescription?: string;
  StoriesTotal?: string;
  Topography?: string;
  UnparsedAddress?: string;
  View?: string;
  WaterAccess?: string;
  WaterFrontFeatures?: string;
  WaterSource?: string;
  WaterYN?: string;
  YearBuilt?: string;
  Zoning?: string;
  ZoningDescription?: string;
}

export interface PropertyDeedHistory {
  HawaiiTitleCertificateNumber?: null;
  MailingStreetDirPrefix?: null;
  TitleCompanyName?: string;
  Buyer2IsCorporation?: boolean;
  Buyer1MiddleName?: null;
  MailingStreetDirSuffix?: null;
  TransactionMultiAPN?: null;
  Buyer1CareOfName?: null;
  TransactionDocumentType?: string;
  TransferTax?: string;
  TransactionTypeForeclosure?: boolean;
  Buyer1OwnershipRights?: string;
  TransactionTypeNewConstruction?: boolean;
  Buyer2Relationship?: string;
  Seller2FullName?: string;
  ClosePriceCode?: string;
  Buyer1Relationship?: string;
  TransactionRecordingPage?: null;
  Buyer2MiddleName?: string;
  MailingCity?: string;
  MailingPostalCodePlus4?: string;
  PartialInterestType?: null;
  TransactionContractDate?: Date;
  MailingStreetNumberSuffix?: null;
  MailingUnitPrefix?: null;
  Buyer1LastName?: string;
  TransactionTypeInterFamily?: boolean;
  TransactionRecordingDate?: Date;
  TransactionRecordingBook?: null;
  MailingUnitNumber?: null;
  TransactionTypeResale?: boolean;
  TransactionType?: string;
  Buyer2OwnershipRights?: null;
  Buyer1FirstName?: string;
  Buyer1NameSuffix?: null;
  HaveAdditionalOwners?: boolean;
  ClosePrice?: number;
  Buyer2LastName?: string;
  MailingStreetNumberAddl?: null;
  MailingPostalCode?: string;
  Buyer2FullName?: string;
  Seller1FullName?: string;
  HawaiiLandCourtDocumentId?: null;
  Buyer2FirstName?: string;
  Buyer1FullName?: string;
  MailingStreetNumber?: string;
  AssessorsMapReference?: null;
  WashingtonExciseNumber?: null;
  MailingStreetSuffix?: string;
  MailingStreetName?: string;
  Buyer2NameSuffix?: null;
  MailingStateOrProvince?: string;
  TransactionRecordingDocumentId?: string;
  Seller1IsCorporation?: boolean;
  TransactionTypeREO?: boolean;
  Seller2IsCorporation?: boolean;
  Buyer1IsCorporation?: boolean;
  MailingCarrierRoute?: string;
}

export interface PropertyImage {
  url?: string;
  userLabel?: string;
  userLabelSelected?: boolean;
  automatedLabel?: null;
  selected?: boolean;
  userUploaded?: boolean;
  isInProgress?: boolean;
  fileName?: string;
  rawFile?: File;
}

export interface PropertyRoomCharacter {
  RoomLaundryLevel?: string;
  RoomBedroom2Features?: string;
  RoomBedroom4Level?: string;
  RoomLivingRoomLevel?: string;
  RoomLibraryLevel?: string;
  RoomBedroom4Features?: string;
  RoomBedroom5Dimensions?: string;
  RoomBedroom5Features?: string;
  RoomRecreationRoomFeatures?: string;
  RoomKitchenLevel?: string;
  RoomRecreationRoomDimensions?: string;
  RoomFamilyRoomLevel?: string;
  RoomRecreationRoomLevel?: string;
  RoomBreakfastRoomFeatures?: string;
  RoomMasterBedroomLevel?: string;
  RoomLaundryDimensions?: string;
  RoomFamilyRoomFeatures?: string;
  RoomLaundryFeatures?: string;
  RoomLivingRoomDimensions?: string;
  RoomBedroom3Features?: string;
  RoomFamilyRoomDimensions?: string;
  RoomKitchenDimensions?: string;
  RoomBedroom2Dimensions?: string;
  RoomDiningRoomFeatures?: string;
  RoomBreakfastRoomLevel?: string;
  RoomBedroom4Dimensions?: string;
  RoomMasterBedroomDimensions?: string;
  RoomLibraryDimensions?: string;
  RoomDiningRoomLevel?: string;
  RoomMasterBedroomFeatures?: string;
  RoomDiningRoomDimensions?: string;
  RoomLibraryFeatures?: string;
  RoomBedroom3Level?: string;
  RoomLivingRoomFeatures?: string;
  RoomBreakfastRoomDimensions?: string;
  RoomBedroom5Level?: string;
  RoomBedroom2Level?: string;
  RoomKitchenFeatures?: string;
  RoomBedroom3Dimensions?: string;
}

export interface PropertyViewApiRequest {
  [param: string]: string;
  reportType: ValuationOrderSubType;
  pageName: 'propertyDetails';
}

export interface PropertyViewApiResponse {
  pageLayout: PropertyPageView;
}

export interface PropertyDataApiRequest {
  // [param: string]: string | undefined;
  reportType: ValuationOrderSubType;
  pageName: 'propertyDetails';
  orderId?: string;
  propertyId?: string;
  listingId?: string;
  pmxProperty?: boolean;
  responseKeys?: PropertyAttribute[];
}

export interface PropertyImageUploadApiRequest {
  [param: string]: string;
  propertyId: string;
}

export interface PropertySearchCriteria {
  listingsOnly?: string;
  streetNumber?: string;
  streetName?: string;
  unitNumber?: string;
  city?: string;
  state?: string;
  zip?: string;
  fipsCode?: string;
  parcelNumber?: string;
  ownerName?: string;
  mlsNumber?: string;
  mlsBoard?: string;
}

export interface PublicRecord {
  LotSizeSquareFeet: string;
  BathroomsTotalInteger: string;
  bathroomsTotal: string;
  BedroomsTotal: number;
  LastSaleDate: string;
  LastSalePrice: string;
  LivingArea: string;
  OwnerName: string;
  PropertySubType: string;
  ParcelNumber: string;
  Latitude: string;
  Longitude: string;
  City: string;
  FIPS: string;
  PMXPropertyId: string;
  PostalCode: string;
  PropertyType: string;
  StateOrProvince: string;
  StreetAddress: string;
  UnitNumber: string;
  MlsListingNumber: string;
  Address: string;
}

export type MlsStatusType = 'Sold' | 'Active Under Contract' | 'Active' | 'Pending';

export interface ListingHistory {
  isActive: boolean;
  isPriorSale: boolean;
  ListingContractDate?: Date | null;
  ListPrice?: string;
  ClosePrice?: string;
  CloseDate?: Date | null;
  StandardStatus?: string;
  ModificationTimestamp?: Date | null;
  MLSListingNumber?: string;
  MlsBoard?: string;
  ListingId?: string;
  children?: ListingHistoryChild[];
  saleDate?: Date | null;
}

type ListingHistoryChild = Omit<ListingHistory, 'isActive' | 'isPriorSale' | 'children' | 'ListingId'>;

export interface DeedHistory {
  isActive: boolean;
  isPriorSale: boolean;
  TransactionRecordingDate?: Date | null;
  TransactionContractDate?: Date | null;
  ClosePrice?: string;
  TransactionDocumentType?: string;
  TransactionRecordingDocumentId?: string;
  Buyer1FullName?: string;
  Buyer2FullName?: string;
  Seller1FullName?: string;
  Seller2FullName?: string;
  children?: DeedHistoryChild[];
  saleDate?: Date | null;
}

type DeedHistoryChild = Omit<DeedHistory, 'isActive' | 'isPriorSale' | 'children' | 'ListingId'>;

export enum HistoryType {
  listing = 'LISTING',
  deed = 'DEED',
}

export interface UpdatePropertyField {
  value: PropertyAttributeValue;
  key: keyof ExtendedPropertyDetail;
}

export interface ImageRecord {
  url: string;
  fileReferenceId?: string;
  automatedLabel: string | null;
  label: string | null;
  isSelected?: boolean;
  fileType: FileType;
  subCategory: string | null;
  createdDate: Date;
}

export interface GenerateListingSheetApiRequest {
  OrderId: string;
  MLSListingNumber?: string;
  MLSBoard?: string;
  Street?: string;
  Zip?: string;
}

export interface GenerateListingSheetApiResponse {
  Data?: {
    ReferenceId?: number;
  };
}

export interface ListingSheetApiResponse {
  responseData: string;
}
