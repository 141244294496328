import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedSourceData',
  standalone: true,
})
export class SelectedSourceDataPipe implements PipeTransform {
  transform(obj: any): any {
    if (obj && obj.selectedSource) {
      const key = obj.selectedSource;
      return obj[key];
    } else {
      return null;
    }
  }
}
