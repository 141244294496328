import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CombinedOrderType } from 'src/app/shared/interface/report.interface';
import { PaginatorConfig } from 'src/app/shared/model/paginator-config';
import { NewOrder } from '../interface/new-order.interface';

@Injectable({
  providedIn: 'root',
})
export class NewOrderState {
  constructor() {}

  /** Stores details related to a new order to be created */
  private _newOrderSub$ = new BehaviorSubject<NewOrder>({} as NewOrder);
  newOrder$ = this._newOrderSub$.asObservable();

  get newOrder(): NewOrder {
    return this._newOrderSub$.value;
  }

  set newOrder(order: NewOrder) {
    this._newOrderSub$.next(order);
  }

  get orderType(): CombinedOrderType {
    return (this.newOrder.reportType || this.newOrder.orderType) as CombinedOrderType;
  }

  private _searchPropertyPaginationSub$ = new BehaviorSubject<PaginatorConfig | null>(null);
  searchPropertyPagination$ = this._searchPropertyPaginationSub$.asObservable();

  get searchPropertyPagination() {
    return this._searchPropertyPaginationSub$.value;
  }

  set searchPropertyPagination(pageData: PaginatorConfig | null) {
    this._searchPropertyPaginationSub$.next(pageData);
  }
}
