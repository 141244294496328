import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { fetchAuthSession, signInWithRedirect, signOut } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { environment } from 'src/environments/environment';
import { AuthState, AuthToken } from '../state/auth.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private _router: Router,
    private _location: Location,
    private authState: AuthState,
    private localStorageService: LocalStorageService
  ) {
    this._listenToAuthState();
    this.validateAuthentication();
  }

  login(): void {
    signInWithRedirect();
  }

  logout(): void {
    signOut()
      .catch(() => {
        console.error('Error signing out user');
      })
      .finally(() => {
        sessionStorage.clear();
        this.localStorageService.clearAll();

        this.authState.isAuthenticated = false;
        this.authState.authToken = null;

        if (environment.isLocal) {
          this._router.navigate(['/login']);
        } else {
          window.location.href = environment.logInUrl;
        }
      });
  }

  async getAuthToken(): Promise<AuthToken | null> {
    try {
      const res = await fetchAuthSession();

      if (!res.tokens?.idToken || !res.tokens?.accessToken) {
        throw new Error('Missing tokens');
      }

      return {
        idToken: res.tokens.idToken.toString(),
        accessToken: res.tokens.accessToken.toString(),
      };
    } catch (error) {
      console.error('Error fetching auth token:', error);

      if (!sessionStorage.getItem('redirectInitiated')) {
        sessionStorage.setItem('redirectInitiated', 'true');

        if (environment.isLocal) {
          this._router.navigate(['/login']);
        } else {
          window.location.href = environment.logInUrl;
        }
      }

      return null;
    }
  }

  async validateAuthentication(): Promise<boolean> {
    return await this.getAuthToken().then((res) => {
      if (res) {
        this.authState.isAuthenticated = true;
        this.authState.authToken = {
          idToken: res?.idToken ?? null,
          accessToken: res?.accessToken ?? null,
        };

        return true;
      } else {
        this.authState.isAuthenticated = false;
        this.authState.authToken = null;
        return false;
      }
    });
  }

  /** Listens to authentication events and update authentication state */
  private _listenToAuthState() {
    Hub.listen('auth', ({ payload: { event } }) => {
      const redirectUrl = this._location.path();

      switch (event) {
        case 'signedIn':
        case 'signInWithRedirect':
          this.authState.isAuthenticated = true;
          break;

        case 'signedOut':
        case 'signInWithRedirect_failure':
          this.authState.isAuthenticated = false;
          if (!['/', '/login'].includes(this._location.path())) {
            localStorage.setItem('redirectUrl', redirectUrl);
          }

          break;
      }
    });
  }
}
