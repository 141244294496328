import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AngularMaterialModule } from 'src/app/module/angular-material/angular-material.module';
import { CompanySettingService } from 'src/app/module/setting/service/company-setting.service';
import { DialogService } from '../../services/dialog.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-ordered-list',
  templateUrl: './ordered-list.component.html',
  styleUrls: ['./ordered-list.component.scss'],
  standalone: true,
  imports: [CommonModule, AngularMaterialModule, FormsModule, ReactiveFormsModule],
})
export class OrderedListComponent {
  reConcileStep: any = {
    recValue: '',
    recLabel: 'add',
    recIndex: 0,
  };

  constructor(
    public dialogRef: MatDialogRef<OrderedListComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogService: DialogService,
    private companySettingService: CompanySettingService,
    private route: ActivatedRoute
  ) {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dialogData.field.template, event.previousIndex, event.currentIndex);
    this.onSet();
  }

  onDelete(index: number) {
    this.dialogData.field.template.splice(index, 1);
    this.pathControlValue(this.dialogData.field.template);
  }

  onEdit(index: number) {
    this.reConcileStep.recValue = this.dialogData.field.template[index] || '';
    this.reConcileStep.recLabel = 'update';
    this.reConcileStep.recIndex = index;
  }

  onCancel() {
    this.reConcileStep = {
      recValue: '',
      recLabel: 'add',
      recIndex: 0,
    };
  }

  onAddOrUpdate() {
    if (this.reConcileStep.recLabel == 'add') {
      this.dialogData.field.template.push(this.reConcileStep.recValue);
      this.pathControlValue(this.dialogData.field.template);
    } else {
      this.dialogData.field.template[this.reConcileStep.recIndex] = this.reConcileStep.recValue;
      this.pathControlValue(this.dialogData.field.template);
    }
    this.onCancel();
  }

  pathControlValue(value: any) {
    this.dialogData.fg.patchValue({
      [this.dialogData.field.name]: value,
    });
  }

  close() {
    this.dialogRef.close(this.dialogData.control);
  }

  onSet() {
    const orderedList: string[] = [];
    this.dialogData.field.template?.forEach((data: any) => {
      if (this.dialogData.fg.get(this.dialogData.field.name).value?.includes(data.enumValue)) {
        orderedList.push(data.enumValue);
      }
    });
    this.pathControlValue(orderedList);
  }

  openConfirm(event: any, action: string, index: number) {
    event.preventDefault();
    if (action == 'delete') {
      const config = {
        buttonLabel: ['No', 'Yes'],
        description: 'Do you want to delete the content?',
        title: 'Delete Content',
      };
      const dialogRef = this.dialogService.openDialog(ConfirmComponent, {
        width: '600px',
        data: config,
      });
      dialogRef.afterClosed().subscribe((data: boolean) => {
        if (data) {
          this.onDelete(index);
        }
      });
    }
  }
}
