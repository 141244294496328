<nav class="navbar navbar-expand-sm shadow-sm profapp_nav">
  <button
    mat-icon-button
    color="primary"
    aria-label="main menu"
    tabindex="0"
    class="me-2 me-xxl-4 p-0"
    (click)="openSideNav()"
  >
    <img src="../assets/hamburger_menu.svg" width="21px" />
  </button>
  <a class="navbar-brand me-4 me-xxl-5" href="#">
    <img src="../assets/Profetlogo.png" alt="Logo" style="width: 140px" />
  </a>
  <a mat-stroked-button color="primary" routerLink="/orders/new" class="ms-auto me-5 small_btn">
    <mat-icon>list_alt_add</mat-icon>
    Create Order
  </a>
  &nbsp;
  <button mat-button [matMenuTriggerFor]="menu" class="me-4">
    <span class="me-3 fw-normal"> {{ userState.userSetting.firstName }} {{ userState.userSetting.lastName }} </span>
    <img src="../assets/dp.png" alt="Avatar Logo" style="width: 40px" class="rounded-pill" />
  </button>
  <mat-menu #menu="matMenu" class="accounts_drpmenu">
    <!-- <button mat-menu-item>Profile</button> -->
    <button mat-menu-item (click)="authService.logout()"><mat-icon>logout</mat-icon> Logout</button>
  </mat-menu>
</nav>
