import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import { NewOrder } from '../interface/new-order.interface';

@Injectable({
  providedIn: 'root',
})
export class NewOrderApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  searchProperty(params: any): Observable<any> {
    const httpParams: any = {
      api: '/mls/val/v1/SearchProperty',
      authType: 'app_static',
      apiType: 'data',
      ...params,
    };
    return this._http.get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, {
      params: httpParams,
    });
  }

  searchPropertyByMLS(params: any): Observable<any> {
    const httpParams: any = {
      api: '/mls/val/v1/GetListingDetails',
      authType: 'dynamic',
      apiType: 'data',
      includeimages: true,
      includepubrec: true,
      ...params,
    };
    return this._http.get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/proxy/get`, {
      params: httpParams,
    });
  }

  searchOrderByAddress(params: any): Observable<string> {
    const httpParams: any = {
      ...params,
    };

    return this._http
      .get<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/order/getDuplicateOrders`, { params: httpParams })
      .pipe(
        map((res) => res.data.orderList as string),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }

  createOrder(params: NewOrder): Observable<string> {
    const httpParams: any = {
      ...params,
    };
    return this._http
      .post<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/initiateOrder`, httpParams)
      .pipe(map((res) => res.data.id as string));
  }
}
