export class AddressConfig {
  static readonly unitNumberPrefixList: string[] = [
    '#',
    'APT',
    'BSMT',
    'BLDG',
    'DEPT',
    'FL',
    'FRNT',
    'HNGR',
    'KEY',
    'LBBY',
    'LOT',
    'LOWR',
    'OFC',
    'PH',
    'PIER',
    'REAR',
    'RM',
    'SIDE',
    'SLIP',
    'SPC',
    'STOP',
    'STE',
    'TRLR',
    'UNIT',
    'UPPR',
  ];
}
