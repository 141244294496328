import {
  CdkOverlayOrigin,
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayRef,
  ScrollStrategyOptions,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Observable, Subject, map, takeUntil } from 'rxjs';
import { MLSBoard } from 'src/app/module/order/interface/order.interface';
import { OrderService } from 'src/app/module/order/service/order.service';
import { UserSettingService } from 'src/app/module/setting/service/user-setting.service';
import { ExceptionConfig } from 'src/app/shared/model/error.model';
import { AbstractFormFieldControls } from '../../helpers/abstract-form-field-controls';
import { ProgressLoaderService } from '../../services/progress-loader.service';
import { SnackBarService } from '../../services/snack-bar.service';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'app-mls-access',
  templateUrl: './mls-access.component.html',
  styleUrls: ['./mls-access.component.scss'],
})
export class MlsAccessComponent extends AbstractFormFieldControls {
  onDestroyNotifier$ = new Subject<void>();
  columnsToDisplay: string[] = [];
  columDef: any;
  dataSource: any = [];
  templateopen: boolean = false;
  states: any = [];
  stateSelected: any;
  membershipNumber: any;
  mlsBoard: any = [];
  mlsBoardSelected: any = [];
  slNoSeleted: any;
  verifiedSeleted: boolean = false;
  addNewButton: boolean = true;
  editMode: boolean = false;
  addVerifyDisable: boolean = true;
  editVerifyDisable: boolean = true;
  addVerifyHidden: boolean = false;
  editVerifyHidden: boolean = false;
  userName: any;

  overlayPositions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
    },
  ];

  overlayRef?: OverlayRef;

  @Output() onSubmitMls = new EventEmitter<any>();
  @ViewChild('deleteOverlayTemplate') deleteOverlayTemplate!: TemplateRef<any>;
  @ViewChild('deleteTrigger') deleteTrigger!: CdkOverlayOrigin;
  @ViewChild('defaultTemplate') defaultTemplate!: TemplateRef<any>;
  @ViewChild('statusTemplate') statusTemplate!: TemplateRef<any>;
  @ViewChild('mlsInformationTemplate') mlsInformationTemplate!: TemplateRef<any>;

  constructor(
    private userSettingService: UserSettingService,
    private snackBarService: SnackBarService,
    private userState: UserState,
    public scrollStrategies: ScrollStrategyOptions,
    private overlay: Overlay,
    private viewContaineref: ViewContainerRef,
    private orderService: OrderService,
    private _cdr: ChangeDetectorRef,
    private progressLoaderService: ProgressLoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userName = this.userState.userSetting?.userName;
    this.states = this.field.enums;
    this.createTable(this.control.value);
  }

  createTable(data: any) {
    this.addVerifyDisable = true;
    this.editVerifyDisable = true;
    this.columnsToDisplay = [];
    this.columDef = this.field.format;
    Object.keys(this.columDef).forEach((key: any) => {
      this.columnsToDisplay.push(this.columDef[key].columnKey);
    });
    setTimeout(() => {
      this.dataSource = data;
    }, 20);
  }

  openMlsAccessContextActions(deleteTrigger: CdkOverlayOrigin) {
    const contextActionsOverlay = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-dark-backdrop',
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(deleteTrigger.elementRef)
        .withPositions(this.overlayPositions),
    });
    this.overlayRef = this.overlay.create(contextActionsOverlay);
    const portal = new TemplatePortal(this.deleteOverlayTemplate, this.viewContaineref);
    this.overlayRef.attach(portal);
    this.overlayRef.backdropClick().subscribe(() => this.closeMlsAccessContextActions());
  }

  closeMlsAccessContextActions() {
    this.overlayRef?.dispose();
  }

  getTableColumnTemplate(columnHeader: string): TemplateRef<any> {
    switch (columnHeader) {
      case 'Status':
        return this.statusTemplate;
      case 'MLS Board':
        return this.mlsInformationTemplate;
      default:
        return this.defaultTemplate;
    }
  }

  buildMLSBoardDisplayNameFromUserSettings(mlsBoard: any): string {
    return mlsBoard?.mlsAbbreviation + '-' + mlsBoard?.mlsName;
  }

  buildMLSBoardDisplayNameForDropDown(mlsBoard: any): string {
    return mlsBoard?.displayValue + '-' + mlsBoard?.key;
  }

  getMLSBoard() {
    this.mlsBoard = [];
    this.progressLoaderService.show().message('Getting MLS Boards');
    return this.orderService
      .getMLSBoards({ state: this.stateSelected })
      .pipe(
        takeUntil(this.onDestroyNotifier$),
        map((data: MLSBoard[]) => {
          const list: { key: string; displayValue: string }[] = [];
          data?.forEach((item: MLSBoard) => {
            list.push({ key: item.MLSBoard, displayValue: `${item.MLSBoard} (${item.MLSName})` });
          });
          this.progressLoaderService.hide();
          return list.sort((a, b) => (a.displayValue < b.displayValue ? -1 : 1));
        })
      )
      .subscribe((list) => {
        if (list?.length) {
          this.mlsBoard = [...list];
        }
        this._cdr.detectChanges();
      });
  }

  closeMlsData() {
    this.templateopen = false;
    this.addNewButton = true;
  }

  removeMls(deleteRow: any) {
    const exptnConfig: ExceptionConfig = {
      notification: true,
      loader: true,
    };
    const deleteMlsRow = {
      userName: this.userName,
      mlsAccessSettings: {
        membershipNumber: deleteRow.membershipNumber,
        mlsBoard: deleteRow.mlsBoard,
        state: deleteRow.state,
        mlsAbbreviation: deleteRow.MLSDisplayAbbreviation,
        SLNO: deleteRow.SLNO,
      },
    };
    const addObr: Observable<any> = this.userSettingService.deleteMLSAccessData(deleteMlsRow, exptnConfig);
    addObr.subscribe({
      next: (response) => {
        this.snackBarService.open('success', response.message);
        if (response?.data?.userSettings?.settings?.mlsAccessSettings) {
          this.dataSource = response.data.userSettings.settings.mlsAccessSettings;
          this.onSubmitMls.emit({
            dataSource: this.dataSource,
            name: this.field.name,
          });
        }
      },
    });
    this.closeMlsAccessContextActions();
  }

  onOptionsSelected(option?: any) {
    if (option == 'state' && this.stateSelected) this.getMLSBoard();
    if (this.addVerifyHidden && this.stateSelected && this.mlsBoardSelected && this.membershipNumber)
      this.addVerifyDisable = false;
    else if (this.editVerifyHidden && this.membershipNumber) this.editVerifyDisable = false;
  }

  addMlsData() {
    this.templateopen = true;
    this.addNewButton = false;
    this.editMode = false;
    this.addVerifyHidden = true;
    this.editVerifyHidden = false;
    this.verifiedSeleted = false;
    this.stateSelected = '';
    this.slNoSeleted = '';
    this.membershipNumber = '';
    this.mlsBoardSelected = '';
    this.mlsBoard = [];
  }

  addAndVerify() {
    if (this.dataSource?.length == 15) {
      this.snackBarService.open('error', 'MLS board configurations exceeded the limit');
    } else {
      const state = this.stateSelected;
      const membershipNo = this.membershipNumber;
      const mlsBoard = this.mlsBoardSelected;
      const addNew = {
        userName: this.userName,
        function: 'save',
        mlsAccessSettings: {
          membershipNumber: membershipNo,
          state: state,
          mlsName: mlsBoard?.key,
          mlsAbbreviation: mlsBoard?.displayValue,
          mlsBoard: mlsBoard?.key,
        },
      };
      const exptnConfig: ExceptionConfig = {
        notification: true,
        loader: true,
      };
      if (this.checkDuplicate(addNew.mlsAccessSettings)) {
        this.snackBarService.open('warning', 'MLS board configurations already added');
      } else {
        const addObr: Observable<any> = this.userSettingService.saveMLSAccessData(addNew, exptnConfig);
        addObr.subscribe({
          next: (response) => {
            this.snackBarService.open('success', response.message);
            if (response.data?.userSettings?.settings?.mlsAccessSettings) {
              this.dataSource = response.data?.userSettings?.settings?.mlsAccessSettings;
              this.stateSelected = '';
              this.slNoSeleted = '';
              this.membershipNumber = '';
              this.mlsBoardSelected = '';
              this.addVerifyDisable = true;
              this.onSubmitMls.emit({
                dataSource: this.dataSource,
                name: this.field.name,
              });
            }
          },
        });
      }
    }
  }

  checkDuplicate(mlsTobeAdded: any) {
    const addedMLSBoardsList = structuredClone(this.dataSource);
    const addedMLSBoardsIdentifierList: any[] = [];
    addedMLSBoardsList?.forEach((addedMLS: any) => {
      if (addedMLS.mlsName) {
        addedMLSBoardsIdentifierList.push(addedMLS.mlsAbbreviation + addedMLS.state);
      } else {
        addedMLSBoardsIdentifierList.push(addedMLS.mlsBoard + addedMLS.state);
      }
    });
    let duplicate = false;
    if (addedMLSBoardsIdentifierList.includes(mlsTobeAdded.mlsAbbreviation + mlsTobeAdded.state)) {
      duplicate = true;
    }
    return duplicate;
  }

  editMlsData(editRow: any) {
    this.addNewButton = false;
    this.editMode = true;
    this.templateopen = true;
    this.addVerifyHidden = false;
    this.editVerifyHidden = true;
    this.verifiedSeleted = editRow.verified;
    this.stateSelected = editRow.state;
    this.slNoSeleted = editRow.SLNO;
    this.membershipNumber = editRow.membershipNumber;
    setTimeout(() => {
      this.mlsBoard = [
        { MLSName: editRow.mlsName, MLSDisplayAbbreviation: editRow.mlsAbbreviation, MLSBoard: editRow.mlsBoard },
      ];
      this.mlsBoardSelected = this.mlsBoard[0];
    }, 100);
  }

  editAndVerify() {
    const mlsBoard = this.mlsBoardSelected;
    const exptnConfig: ExceptionConfig = {
      notification: true,
      loader: true,
    };
    const edit = {
      userName: this.userName,
      function: 'update',
      mlsAccessSettings: {
        membershipNumber: this.membershipNumber,
        mlsBoard: mlsBoard?.MLSBoard ? mlsBoard.MLSBoard : '',
        state: this.stateSelected,
        mlsAbbreviation: mlsBoard?.MLSDisplayAbbreviation ? mlsBoard.MLSDisplayAbbreviation : '',
        mlsName: mlsBoard?.MLSName ? mlsBoard.MLSName : '',
        SLNO: this.slNoSeleted,
        verified: this.verifiedSeleted,
      },
    };
    const addObr: Observable<any> = this.userSettingService.saveMLSAccessData(edit, exptnConfig);
    addObr.subscribe({
      next: (response) => {
        this.snackBarService.open('success', response.message);
        if (response?.data?.userSettings?.settings?.mlsAccessSettings) {
          this.dataSource = response.data.userSettings.settings.mlsAccessSettings;
          this.templateopen = false;
          this.addNewButton = true;
          this.onSubmitMls.emit({
            dataSource: this.dataSource,
            name: this.field.name,
          });
        }
      },
    });
  }
}
