import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GridRowData, UnitAdjustmentRecommendationSet } from '../interface/adjustment.interface';

@Injectable({
  providedIn: 'root',
})
export class ValueAdjustmentState {
  /** Stores details related to a value adjustment grid row data */
  private _gridRowDataSub$ = new BehaviorSubject<GridRowData[]>([]);
  gridRowData$ = this._gridRowDataSub$.asObservable();

  get gridRowData(): GridRowData[] {
    return this._gridRowDataSub$.value;
  }

  set gridRowData(value: GridRowData[]) {
    this._gridRowDataSub$.next(value);
  }

  /** Stores unit adjustment recommendation for different models */
  private _unitAdjustmentRecommendationSub$ = new BehaviorSubject<UnitAdjustmentRecommendationSet | null>(null);
  unitAdjustmentRecommendation$ = this._unitAdjustmentRecommendationSub$.asObservable();

  get unitAdjustmentRecommendation(): UnitAdjustmentRecommendationSet | null {
    return this._unitAdjustmentRecommendationSub$.value;
  }

  set unitAdjustmentRecommendation(value: UnitAdjustmentRecommendationSet | null) {
    this._unitAdjustmentRecommendationSub$.next(value);
  }
}
