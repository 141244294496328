import { Pipe, PipeTransform } from '@angular/core';

type UnitType = 'area';

@Pipe({
  name: 'unitAffix',
  standalone: true,
})
export class UnitAffixPipe implements PipeTransform {
  constructor() {}

  transform(value: any, unit: UnitType): any {
    if (!value) return;
    switch (unit) {
      case 'area':
        return `${value} sf`;
      default:
        return value;
    }
  }
}
