import { Injectable } from '@angular/core';
import { AppEnumMap } from '../interface/common.interface';

@Injectable({
  providedIn: 'root',
})
export class AppState {
  /**
   * Stores all the enumerations used in the app
   * Value is stored in this variable during application bootstrap
   */
  enumMap!: AppEnumMap;
}
