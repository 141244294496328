<ng-container
  [ngTemplateOutlet]="
    dialogData.field.displayType == 'orderedListEnum'
      ? orderedListEnum
      : dialogData.field.displayType == 'orderedListString'
        ? orderedListString
        : dialogData.field.displayType == 'listEnum'
          ? listEnum
          : orderedListEditableString
  "
>
</ng-container>

<ng-template #orderedListEnum>
  <div class="p-3">
    <h2 class="text_themeBlue mb-0">{{ dialogData?.field?.displayName }}</h2>
    <p class="my-0">Drag and re-order to set the list order</p>
  </div>
  <mat-selection-list
    color="primary"
    class="reorder_list px-3"
    cdkDropList
    [formControl]="dialogData.control"
    (cdkDropListDropped)="drop($event)"
  >
    <mat-list-option
      style="border-width: 1px; border-style: dashed"
      *ngFor="let opt of dialogData.field.template; let i = index"
      cdkDrag
      [value]="opt.enumValue"
      [disabled]="opt.enumValueMetadata?.viewType == 'fixed' ? true : false"
    >
      <p class="m-0">{{ i + 1 + '.' }} {{ opt.enumDisplayValue }}</p>
    </mat-list-option>
  </mat-selection-list>
</ng-template>
<ng-template #listEnum>
  <div class="p-3">
    <h2 class="text_themeBlue mb-0">{{ dialogData?.field?.displayName }}</h2>
  </div>
  <mat-selection-list color="primary" class="reorder_list px-3" [formControl]="dialogData.control">
    <mat-list-option
      *ngFor="let opt of dialogData.field.template; let i = index"
      [value]="opt.enumValue"
      [disabled]="opt.enumValueMetadata?.viewType == 'fixed' ? true : false"
    >
      <p class="m-0">{{ i + 1 + '.' }} {{ opt.enumDisplayValue }}</p>
    </mat-list-option>
  </mat-selection-list>
</ng-template>

<ng-template #orderedListString>
  <div class="p-3">
    <h2 class="text_themeBlue mb-0">{{ dialogData?.field?.displayName }}</h2>
    <p class="my-0">Drag and re-order to set the list order</p>
  </div>
  <mat-list
    class="reorder_list px-3"
    role="list"
    cdkDropList
    [formControl]="dialogData.control"
    ngDefaultControl
    (cdkDropListDropped)="drop($event)"
  >
    <mat-list-item
      role="listitem"
      style="border-width: 1px; border-style: dashed"
      *ngFor="let opt of dialogData.field.template; let i = index"
      cdkDrag
    >
      <p class="m-0">{{ i + 1 + '.' }} {{ opt.enumDisplayValue }}</p>
    </mat-list-item>
  </mat-list>
</ng-template>

<ng-template #orderedListEditableString class="image_wrapNav">
  <div class="p-3 d-flex">
    <h2 class="text_themeBlue mb-0">{{ dialogData?.field?.displayName }}</h2>
  </div>
  <ul [formControl]="dialogData.control" ngDefaultControl class="list-group" style="overflow-y: scroll">
    <li *ngFor="let opt of dialogData.field.template; let i = index" class="list-group-item">
      <p class="m-0">{{ i + 1 + '.' }} {{ opt }}</p>
      <div class="mt-1 d-flex">
        <button mat-button color="danger" aria-label="Delete report" (click)="openConfirm($event, 'delete', i)">
          <mat-icon>delete</mat-icon>
        </button>
        <button mat-button class="ms-2" color="accent" aria-label="Edit report" (click)="onEdit(i)">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
    </li>
  </ul>
  <div class="p-3">
    <label for="">{{ reConcileStep.recLabel == 'update' ? 'Update' : 'Add' }} Content</label>
    <textarea class="w-100" matInput [(ngModel)]="reConcileStep.recValue"></textarea>
    <div class="mt-1 d-flex">
      <button
        mat-button
        color="primary"
        class="me-1 small_btn"
        *ngIf="reConcileStep.recLabel != 'add'"
        (click)="onCancel()"
      >
        Cancel
      </button>

      <button
        mat-flat-button
        class="small_btn"
        color="accent"
        [disabled]="!reConcileStep.recValue"
        (click)="onAddOrUpdate()"
      >
        {{ reConcileStep.recLabel == 'update' ? 'Update' : 'Add' }}
      </button>
    </div>
  </div>
</ng-template>
<button tabindex="0" mat-icon-button aria-label="close" class="popclose" (click)="close()">
  <mat-icon>close</mat-icon>
</button>
<!-- <div class="d-flex p-3">
    <button mat-button class="ms-auto" (click)="close()">Set</button>
</div> -->
