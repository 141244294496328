import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderConfig } from '../config/order.config';
import { Neighborhood, NeighborhoodInsight } from '../interface/neighborhood.interface';
import { OrderDetail } from '../interface/order.interface';
import { OrderState } from './order.state';

@Injectable({
  providedIn: 'root',
})
export class NeighborhoodState {
  constructor(private orderState: OrderState) {}

  /** Stores Neighborhood details */
  private _neighborhoodSub$ = new BehaviorSubject<Neighborhood | null>(null);
  neighborhood$ = this._neighborhoodSub$.asObservable();

  get neighborhood(): Neighborhood | null {
    return this._neighborhoodSub$.value;
  }

  set neighborhood(data: Neighborhood | null) {
    this._neighborhoodSub$.next(data);

    const order: OrderDetail = structuredClone(this.orderState.orderDetail);

    if (order.neighborhood) order.neighborhood = data;
    this.orderState.orderDetail = structuredClone(order);
  }

  /** Stores Zip-Code and Comparable insights */
  private _insightSub$ = new BehaviorSubject<NeighborhoodInsight>(OrderConfig.defaultNeighborhoodInsight);
  insight$ = this._insightSub$.asObservable();

  get insight(): NeighborhoodInsight {
    return this._insightSub$.value;
  }

  set insight(data: NeighborhoodInsight) {
    this._insightSub$.next(data);
  }
}
