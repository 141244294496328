<mat-accordion class="shadow-sm property-search-accordion">
  <mat-expansion-panel class="find-by-address" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title> Find by Address </mat-panel-title>
    </mat-expansion-panel-header>
    <app-google-autocomplete (addressSelected)="onAddressSelected($event)" class="d-block" />
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Search by Address </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="searchByAddressForm">
      <div class="row">
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="streetNumber" label="Street Number" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="streetName" label="Street Name" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="unitNumber" label="Unit Number" type="numberString" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="city" label="City" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-select label="State" formControlName="state" [options]="appState.enumMap.state" [showEmpty]="true" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="zip" label="Zip" type="numberString" />
        </div>
        <div class="col-12 col-lg-6">
          <profet-input formControlName="fipsCode" type="numberString" label="County(FIPS) Code" />
        </div>
        <div class="col-12">
          <button
            (click)="initiateSearch(searchByAddressForm, 'address')"
            mat-flat-button
            class="small_btn float-end"
            color="primary"
            [disabled]="searchByAddressFormDisable"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Search by MLS Number </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="searchByMlsForm">
      <div class="row">
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="mlsNumber" label="MLS #" />
        </div>
        <div class="col-12 col-lg-6">
          <profet-select label="State" formControlName="state" [options]="appState.enumMap.state" [showEmpty]="true" />
        </div>
        <div class="col-12 mb-1">
          <profet-select
            formControlName="mlsBoard"
            label="MLS Board"
            [options]="mlsBoardList"
            [showEmpty]="true"
            [inProgress]="isMLSInProgress"
          />
        </div>

        <div class="col-12">
          <button
            (click)="initiateSearch(searchByMlsForm, 'mls')"
            mat-flat-button
            class="small_btn float-end"
            color="primary"
            [disabled]="searchByMlsForm.invalid"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Search by Parcel Number </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="searchByParcelForm">
      <div class="row">
        <div class="col-12 col-lg-6 mb-1">
          <profet-input formControlName="parcelNumber" label="Parcel Number" type="numberString" />
        </div>
        <div class="col-12 col-lg-6 mb-1">
          <profet-select label="State" formControlName="state" [options]="appState.enumMap.state" [showEmpty]="true" />
        </div>
        <div class="col-12 mb-1">
          <profet-input formControlName="zip" label="ZIP" type="numberString" />
        </div>
        <!-- <div class="col-12 mb-1">
          <profet-select formControlName="fipsCode" label="County" [options]="this.countyList" [showEmpty]="true" />
        </div> -->
        <div class="col-12">
          <button
            (click)="initiateSearch(searchByParcelForm, 'parcel')"
            mat-flat-button
            class="small_btn float-end"
            color="primary"
            [disabled]="searchByParcelForm.invalid"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Search by Owner </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="searchByOwnerForm">
      <div class="row">
        <div class="col-12 col-md-8 mb-1">
          <profet-input formControlName="ownerName" label="Owner Name" [required]="true" />
        </div>
        <div class="col-12 col-md-4 mb-1">
          <profet-input type="numberString" formControlName="zip" label="ZIP" [required]="true" />
        </div>
        <div class="col-12">
          <button
            (click)="initiateSearch(searchByOwnerForm, 'owner')"
            mat-flat-button
            class="small_btn float-end"
            color="primary"
            [disabled]="searchByOwnerForm.invalid"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>
