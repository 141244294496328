import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DocumentPreviewApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  uploadFiles(params: FormData): Observable<any> {
    return this._http.post<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/uploadFiles`, params).pipe(
      map((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return res.data;
      })
    );
  }

  deleteFile(fileReferenceId: string): Observable<any> {
    return this._http.delete<CommonApiResponse<any>>(`${ENV.backendBaseUrl}/api/file/${fileReferenceId}`);
  }

  getFile(url: string): Observable<any> {
    return this._http.get(url, { responseType: 'blob' }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }
}
