import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CompanyDetail, CompanySettingForm } from '../interface/company-setting.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingState {
  /** Stores details related to the current company */
  private _companyDetailSub$ = new BehaviorSubject<CompanyDetail>({} as CompanyDetail);
  companyDetail$ = this._companyDetailSub$.asObservable();

  /**
   * @deprecated
   * Use CompanyState.companySetting instead
   */
  get companyDetail(): CompanyDetail {
    return this._companyDetailSub$.value;
  }

  set companyDetail(company: CompanyDetail) {
    this._companyDetailSub$.next(company);
  }

  /** Stores details related to forms to update company settings */
  private _formListSub$ = new BehaviorSubject<CompanySettingForm[]>([]);
  formList$ = this._formListSub$.asObservable();

  get formList(): CompanySettingForm[] {
    return this._formListSub$.value;
  }

  set formList(formList: CompanySettingForm[]) {
    this._formListSub$.next(formList);
  }
}
