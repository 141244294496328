import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { OrderListConfig } from '../config/order-list.config';
import { OrderListApiResponse, OrderListFilter } from '../interface/order-list.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderListState {
  constructor(private localStorageService: LocalStorageService) {}

  /**
   * Stores order list request data including filters
   */
  private _orderFilterSub$: BehaviorSubject<OrderListFilter> = new BehaviorSubject<OrderListFilter>(
    this.localStorageService.getItem('preference', 'orderSearchFilter') ?? OrderListConfig.defaultFilter
  );
  orderFilter$: Observable<OrderListFilter> = this._orderFilterSub$.asObservable();

  get orderFilter(): OrderListFilter {
    return this._orderFilterSub$.value;
  }

  set orderFilter(value: OrderListFilter) {
    this._orderFilterSub$.next({ ...this._orderFilterSub$.value, ...value });
  }

  /**
   * Stores details related to all the orders.
   * Order list table will make use of this state.
   */
  private _orderListSub$: BehaviorSubject<OrderListApiResponse> = new BehaviorSubject<OrderListApiResponse>(
    OrderListConfig.defaultOrderListData
  );
  orderList$: Observable<OrderListApiResponse> = this._orderListSub$.asObservable();

  get orderList(): OrderListApiResponse {
    return this._orderListSub$.value;
  }

  set orderList(orderList: OrderListApiResponse) {
    this._orderListSub$.next(orderList);
  }

  /**
   * Stores details related to all users under a specific company.
   * Used to show user filter for all users
   */
  private _userListSub$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  userList$: Observable<any> = this._userListSub$.asObservable();

  get userList(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._userListSub$.value;
  }

  set userList(userList: any) {
    this._userListSub$.next(userList);
  }
}
