import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'profet-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
})
export class DatePickerComponent implements AfterViewInit, OnDestroy {
  _rangeStart: Date | null = null;
  _rangeEnd: Date | null = null;
  label!: string;
  isDisabled: boolean = false;

  // Subscription to unsubscribe all observables on component destroy
  onDestroyNotifier$ = new Subject<void>();

  @Input('label') set _label(v: string) {
    if (v) this.label = v;
    else if (!this.range) this.label = 'Choose a date';
    else if (this.range) this.label = 'Choose a date range';
  }

  @Input() range: boolean = false;

  @Input() minDate?: Date;
  @Input() maxDate?: Date = new Date();

  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';

  @Input() clear?: boolean;

  @Input() infoText: string | undefined;

  constructor(public ngControl: NgControl) {}

  ngAfterViewInit(): void {
    // Disable the control if it is disabled in the parent form
    this.isDisabled = this.ngControl.status === 'DISABLED';
    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((status) => (this.isDisabled = status === 'DISABLED'));
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  pickerClosed(): any {
    if (this.range && this._rangeStart && this._rangeEnd) {
      this.modelCtrlValue = {
        start: this._rangeStart,
        end: this._rangeEnd,
      };
    }
  }

  clearData() {
    if (this.range) {
      this.modelCtrlValue = {
        start: null,
        end: null,
      };
    } else {
      this.modelCtrlValue = null;
    }
  }

  get rangeStart(): Date | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.modelCtrlValue?.start;
  }

  set rangeStart(value: Date | null) {
    if (value) value.setHours(0, 0, 0, 0);
    this._rangeStart = value;
  }

  get rangeEnd(): Date | null {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.modelCtrlValue?.end;
  }

  set rangeEnd(value: Date | null) {
    if (value) value.setHours(23, 59, 59, 999);
    this._rangeEnd = value;
  }

  get modelCtrl(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get modelCtrlValue(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (this.ngControl.control?.value) return (this.ngControl.control as FormControl).value;
    else if (this.range) {
      return {
        start: null,
        end: null,
      };
    } else {
      return null;
    }
  }

  set modelCtrlValue(value: any) {
    if (this.ngControl.control) {
      if (value) {
        this.ngControl.control.setValue(value);
      } else if (this.range) {
        this.ngControl.control.setValue({
          start: null,
          end: null,
        });
      } else {
        this.ngControl.control.setValue(null);
      }
    }
  }
}
