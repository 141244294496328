import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { accessControlGuard } from './shared/guard/access-control.guard';
import { authGuard } from './shared/guard/auth.guard';
import { unAuthGuard } from './shared/guard/unauth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'orders', pathMatch: 'full' },
  {
    path: 'app/orders',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./page/company-setting-page/company-setting-page.module').then((m) => m.CompanySettingPageModule),
    canActivate: [authGuard, accessControlGuard],
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./page/user-setting-page/user-setting-page.module').then((m) => m.UserSettingPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'orders/new',
    loadChildren: () => import('./page/new-order-page/new-order-page.module').then((m) => m.NewOrderPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'orders/:orderId/view',
    loadChildren: () => import('./page/view-order-page/view-order-page.module').then((m) => m.ViewOrderPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'orders/:orderId',
    loadChildren: () => import('./page/order-page/order-page.module').then((m) => m.OrderPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'orders',
    loadChildren: () => import('./page/order-list-page/order-list-page.module').then((m) => m.OrderListPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'property',
    loadChildren: () => import('./page/property-page/property-page.module').then((m) => m.PropertyPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('./page/login-page/login-page.module').then((m) => m.LoginPageModule),
    canActivate: [unAuthGuard],
  },
  {
    path: '**',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
