import { Injectable } from '@angular/core';
import { UserSetting } from '../interface/user.interface';

@Injectable({
  providedIn: 'root',
})
export class UserState {
  userSetting!: UserSetting;
  permittedActions!: string[];
}
