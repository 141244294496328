import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Observable, Subject, map, of, switchMap, takeUntil, timer } from 'rxjs';
import { JsonFormControls, JsonFormData, JsonFormValidators } from '../../model/json-form.model';
import { DialogService } from '../../services/dialog.service';
import { CommonService } from '../../utility/common.service';
import { OrderedListComponent } from '../ordered-list/ordered-list.component';
@Component({
  selector: 'app-json-form',
  templateUrl: './json-form.component.html',
  styleUrls: ['./json-form.component.scss'],
})
export class JsonFormComponent implements OnChanges, OnDestroy {
  jsonForm!: FormGroup;
  dependentFields = new Set();
  submitBtn: boolean = true;

  // Subscription to unsubscribe all observables on component destroy
  onDestroyNotifier$ = new Subject<void>();

  @Input() jsonFormData!: JsonFormData;
  @Input() companyKey!: string;
  @Output() onSubmitForm = new EventEmitter<FormGroup>();

  constructor(
    private dialogService: DialogService,
    private commonService: CommonService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['jsonFormData'].currentValue) {
      this.jsonForm = new FormGroup({});
      this.addControls();
      this.submitBtn = ['mlsAccess'].includes(this.jsonFormData.id) ? false : true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  addControls() {
    this.dependentFields = new Set();
    this.jsonFormData.controls.forEach((element) => {
      const validators = this.addValidators(element.displayTypeValidators);
      const isEnable = this.checkDependency(element);
      element.isEnable = isEnable;
      if (element.dependentOn) {
        for (const key in element.dependentOn) {
          this.dependentFields.add(key);
        }
      }

      this.jsonForm.addControl(
        element.name,
        new FormControl(
          { value: element.value, disabled: !isEnable },
          validators.validatorsToAdd,
          validators.asyncValidatorToAdd
        )
      );
    });
    this.updateEnable();
  }

  addFormArrayControls(element: JsonFormControls) {
    const formControls: any = new FormArray([]);
    element.template?.forEach((item: any) => {
      const formGroup = new FormGroup({});
      Object.keys(element.format).forEach((keys: string) => {
        formGroup.addControl(keys, new FormControl(item[keys]));
      });
      formControls.push(formGroup);
    });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return formControls;
  }

  addValidators(validators: JsonFormValidators) {
    const validatorsToAdd: any[] = [];
    const asyncValidatorToAdd: any[] = [];
    for (const [key, value] of Object.entries(validators)) {
      switch (key) {
        case 'min':
          validatorsToAdd.push(Validators.min(value));
          break;
        case 'max':
          validatorsToAdd.push(Validators.max(value));
          break;
        case 'required':
          if (value) {
            validatorsToAdd.push(Validators.required);
          }
          break;
        case 'pattern':
          if (value) {
            validatorsToAdd.push(Validators.pattern(value.pattern));
          }
          break;
        case 'asyncCustomValidator':
          asyncValidatorToAdd.push(this.asyncValidators(value));
          break;
        default:
          break;
      }
    }
    return {
      validatorsToAdd: validatorsToAdd,
      asyncValidatorToAdd: asyncValidatorToAdd,
    };
  }

  asyncValidators(value: string) {
    return (control: FormControl): Observable<ValidationErrors | null> => {
      if (control.dirty) {
        return timer(500).pipe(
          switchMap(() => {
            return this.commonService
              .onFormControlValueChange(value, { companyKey: this.companyKey, value: control.value }, {})
              .pipe(
                map((response: any) => {
                  if (response?.data && response.data.isValid == false) {
                    return {
                      asyncCustomValidator: response.data.message || '',
                    };
                  } else {
                    return null;
                  }
                })
              );
          })
        );
      } else {
        return of(null);
      }
    };
  }

  updateEnable() {
    this.dependentFields?.forEach((key: any) => {
      this.jsonForm
        .get(key)
        ?.valueChanges.pipe(takeUntil(this.onDestroyNotifier$))
        .subscribe(() => {
          const fields = this.jsonFormData.controls.filter((ctrl: JsonFormControls) => {
            return ctrl?.dependentOn && Object.prototype.hasOwnProperty.call(ctrl?.dependentOn, key);
          });
          fields?.forEach((element) => {
            const isEnable = this.checkDependency(element);
            if (isEnable) {
              this.jsonForm.get(element.name)?.enable();
              element.isEnable = true;
            } else {
              this.jsonForm.get(element.name)?.disable();
              element.isEnable = false;
            }
          });
        });
    });
  }

  getFormControl(control: string) {
    return this.jsonForm.get(control) as FormControl;
  }

  openOrderedListComp(control: any, event: any) {
    event.preventDefault();

    const dialogData = {
      fg: this.jsonForm,
      field: control,
      control: this.getFormControl(control.name),
    };

    this.dialogService.openDialog(OrderedListComponent, {
      maxWidth: '600px',
      width: '480px',
      data: dialogData,
    });
  }

  checkDependency(control: JsonFormControls) {
    if (
      !control.dependentOn ||
      (control.dependentOn &&
        Object.keys(control.dependentOn)?.every(
          (name: string) =>
            this.jsonForm.get(name)?.value == control.dependentOn[name] ||
            control.dependentOn[name].toString().includes(this.jsonForm.get(name)?.value?.toString())
        ))
    ) {
      return true;
    } else {
      return false;
    }
  }

  // getValidationErrors = () => {
  //   Object.keys(this.jsonForm.controls).forEach((key) => {
  //     const controlErrors: ValidationErrors = this.jsonForm.get(key)?.errors || {};
  //   });
  // };
  updateControl(event: any) {
    this.jsonForm.controls[event.name].patchValue(event.dataSource);
    this.onSubmit();
  }

  onSubmit() {
    if (this.jsonForm.invalid) {
      return;
    }
    this.onSubmitForm.emit(this.jsonForm);
  }
}
