import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { EndPoints } from 'src/app/shared/api-service/api-endpoints';
import { ApiGatewayService } from 'src/app/shared/api-service/api-gateway.service';
import { ExceptionConfig } from 'src/app/shared/model/error.model';
import { Utilities } from 'src/app/shared/utility/utilities';
import { UserSettingApi } from '../api/user-setting.api';
import { UserSettingState } from '../state/user-setting.state';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  constructor(
    private apiGatewayService: ApiGatewayService,
    private userSettingApi: UserSettingApi,
    private userSettingState: UserSettingState
  ) {}

  getUserSettings(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.getUserSettings + '?' + Utilities.encodeQueryParam(reqBody);
    return this.apiGatewayService.doGet(url, exptnConfig);
  }

  getMLSBoard(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.getMLSBoard + '&' + Utilities.encodeQueryParam(reqBody);
    return this.apiGatewayService.doGet(url, exptnConfig);
  }

  saveMLSAccessData(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.saveMLSAccessData;
    return this.apiGatewayService.doPost(url, reqBody, exptnConfig);
  }

  deleteMLSAccessData(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.deleteMLSAccessData;
    return this.apiGatewayService.doPost(url, reqBody, exptnConfig);
  }

  updateUserSettings(reqBody: any, exptnConfig: ExceptionConfig) {
    const url = EndPoints.API_URLS.updateUserSettings + '/' + reqBody.id;
    return this.apiGatewayService.doPatch(url, reqBody, exptnConfig);
  }

  /** Get user settings form */
  getUserFormSetting(): Observable<boolean> {
    return this.userSettingApi.getUserFormSetting().pipe(
      map(({ formList }) => {
        this.userSettingState.formList = formList;
        return false;
      })
    );
  }
}
