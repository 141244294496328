import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { InfoSheetConfig, InfoSheetKeyType } from '../config/info-sheet.config';

interface SheetData {
  title: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class InfoSheetService {
  /**
   * State to maintain the open/close state of the sheet
   */
  private _isOpenSub$ = new BehaviorSubject<boolean>(false);
  isOpen$ = this._isOpenSub$.asObservable();

  /**
   * State to maintain the data of the info sheet
   */
  private _sheetDataSub$ = new BehaviorSubject<SheetData>({ title: '', content: '' });
  sheetData$ = this._sheetDataSub$.asObservable();

  constructor() {}

  data(data: SheetData) {
    this.sheetData = data;
    return this;
  }

  open(key?: InfoSheetKeyType): void {
    if (key) {
      this.sheetData = InfoSheetConfig.sheetDataMap[key];
    }
    this.isOpen = true;
  }

  close(): void {
    this.isOpen = false;
  }

  private get isOpen(): boolean {
    return this._isOpenSub$.value;
  }

  private set isOpen(v: boolean) {
    this._isOpenSub$.next(v);
  }

  private get sheetData(): SheetData {
    return this._sheetDataSub$.value;
  }

  private set sheetData(v: SheetData) {
    this._sheetDataSub$.next(v);
  }
}
