import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent implements OnChanges {
  errorMessage: any = '';

  @Input() formError: any = {};

  ngOnChanges() {
    this.getErrorMessage();
  }

  getErrorMessage = () => {
    for (const [key, value] of Object.entries(this.formError)) {
      switch (key) {
        case 'min':
          this.errorMessage = 'Please enter valid input';
          break;
        case 'max':
          this.errorMessage = 'Please enter valid input';
          break;
        // case 'required':
        //   this.errorMessage = 'This field is required';
        //   break;
        case 'pattern':
          this.errorMessage = 'Please enter valid input';
          break;
        case 'email':
          this.errorMessage = 'Please enter valid email';
          break;
        case 'asyncCustomValidator':
          this.errorMessage = value || 'Please enter valid input';
          break;
        case 'customValidator':
          this.errorMessage = value || 'Please enter valid input';
          break;
        default:
          break;
      }
    }
  };
}
