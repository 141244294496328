import { Pipe, PipeTransform } from '@angular/core';
import { OrderMultiDataSource } from 'src/app/module/order/interface/value-reconciliation.interface';

@Pipe({
  name: 'orderMultiDataSource',
  standalone: true,
})
export class OrderMultiDataSourcePipe implements PipeTransform {
  transform<T>(value?: OrderMultiDataSource<T>, ignoreAutoComputed?: boolean): T {
    if (!value || !('userEntered' in value) || !('autoComputed' in value)) {
      return value as T;
    }

    if (ignoreAutoComputed) {
      return value.userEntered as T;
    }

    if (value.userEntered !== null) {
      return value.userEntered as T;
    } else {
      return value.autoComputed as T;
    }
  }
}
