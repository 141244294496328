import { AfterViewInit, Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { InfoSheetService } from '../../services/info-sheet.service';

@Component({
  selector: 'app-info-sheet',
  templateUrl: './info-sheet.component.html',
  styleUrls: ['./info-sheet.component.scss'],
})
export class InfoSheetComponent implements AfterViewInit {
  title: string = '';

  @ViewChild('contentWrapper') contentWrapper!: ElementRef<HTMLDivElement>;

  constructor(
    private _renderer: Renderer2,
    public infoSheetService: InfoSheetService
  ) {}

  ngAfterViewInit(): void {
    this.infoSheetService.sheetData$.subscribe(({ title, content }) => {
      this.title = title;
      this._renderer.setProperty(this.contentWrapper.nativeElement, 'innerHTML', content);
    });
  }
}
