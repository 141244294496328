import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/module/auth/service/auth.service';

export const unAuthGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  _router = inject(Router),
  authService = inject(AuthService)
) => {
  return authService
    .validateAuthentication()
    .then((isAuthenticated: boolean) => {
      if (!isAuthenticated) return true;
      else return _router.createUrlTree(['/orders']);
    })
    .catch(() => {
      return _router.createUrlTree(['/orders']);
    });
};
