import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExtendedPropertyDetail, PropertyPageViewSection } from '../interface/property.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyState {
  /** State where a property's page view layout config is stored */
  private _propertyPageLayoutSub$: BehaviorSubject<PropertyPageViewSection[]> = new BehaviorSubject<
    PropertyPageViewSection[]
  >([]);
  propertyPageLayout$: Observable<PropertyPageViewSection[]> = this._propertyPageLayoutSub$.asObservable();

  get propertyPageLayout(): PropertyPageViewSection[] {
    return this._propertyPageLayoutSub$.value;
  }

  set propertyPageLayout(propertyData: PropertyPageViewSection[]) {
    this._propertyPageLayoutSub$.next(propertyData);
  }

  /** State where subject property's data is stored */
  private _subjectPropertyDataSub$ = new BehaviorSubject<ExtendedPropertyDetail | null>(null);
  subjectPropertyData$: Observable<ExtendedPropertyDetail | null> = this._subjectPropertyDataSub$.asObservable();

  get subjectPropertyData(): ExtendedPropertyDetail | null {
    return this._subjectPropertyDataSub$.value;
  }

  set subjectPropertyData(propertyData: ExtendedPropertyDetail | null) {
    this._subjectPropertyDataSub$.next(propertyData);
  }

  /** State where an active property's data is stored */
  private _activePropertyDataSub$ = new BehaviorSubject<ExtendedPropertyDetail | null>(null);
  activePropertyData$: Observable<ExtendedPropertyDetail | null> = this._activePropertyDataSub$.asObservable();

  get activePropertyData(): ExtendedPropertyDetail | null {
    return this._activePropertyDataSub$.value;
  }

  set activePropertyData(propertyData: ExtendedPropertyDetail | null) {
    this._activePropertyDataSub$.next(propertyData);
  }

  /** State where an active property's active history data is stored */
  private _activePropertyHistoryDataSub$ = new BehaviorSubject<ExtendedPropertyDetail | null>(null);
  activePropertyHistoryData$: Observable<ExtendedPropertyDetail | null> =
    this._activePropertyHistoryDataSub$.asObservable();

  get activePropertyHistoryData(): ExtendedPropertyDetail | null {
    return this._activePropertyHistoryDataSub$.value;
  }

  set activePropertyHistoryData(propertyData: ExtendedPropertyDetail | null) {
    this._activePropertyHistoryDataSub$.next(propertyData);
  }

  /** State where active history listingId or not is stored */
  private _activePropertyHistoryListingIdSub$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  activePropertyHistoryListingId$: Observable<string | null> = this._activePropertyHistoryListingIdSub$.asObservable();

  get activePropertyHistoryListingId(): string | null {
    return this._activePropertyHistoryListingIdSub$.value;
  }

  set activePropertyHistoryListingId(value: string | null) {
    this._activePropertyHistoryListingIdSub$.next(value);
  }
}
