import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'simpleDate',
  standalone: true,
})
export class SimpleDatePipe implements PipeTransform {
  transform(value: any): any {
    if (!value) return;
    if (!dayjs(value).isValid()) return value;

    const date = dayjs(value);

    if (date.isSame(new Date(), 'day')) return date.format('h:mm a');

    if (date.isSame(new Date(), 'year')) return date.format('MMM D');

    return date.format('MMM D, YYYY');

    // if (!value) return;
    // else {
    //   value = dayjs(new Date(value)).format(args || 'MM-DD-YYYY');
    //   return value;
    // }
  }
}
