import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import { UserFormSettingApiResponse, UserSettingForm } from '../interface/user-setting.interface';

@Injectable({
  providedIn: 'root',
})
export class UserSettingApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  getUserSetting(params: any): Observable<any> {
    const httpParams: any = { ...params };
    return this._http.get(`${ENV.backendBaseUrl}/api/getPageLayoutDetails`, { params: httpParams }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  updateUserSetting(params: any): Observable<any> {
    const httpParams: any = { ...params };
    return this._http.get(`${ENV.backendBaseUrl}/api/getPageLayoutDetails`, { params: httpParams }).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = error.error.message;
        this.snackBarService.open('error', errorMessage);
        return throwError(() => error);
      })
    );
  }

  /** Get form details for user settings form*/
  getUserFormSetting(): Observable<{ formList: UserSettingForm[] }> {
    const httpParams: any = { formName: 'userSettings' };
    return this._http
      .get<CommonApiResponse<UserFormSettingApiResponse>>(`${ENV.backendBaseUrl}/api/getAllFormMetadata`, {
        params: httpParams,
      })
      .pipe(
        map((res) => {
          return { formList: res.data.sections };
        }),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return throwError(() => error);
        })
      );
  }
}
