import { GeoAddress } from '../model/address.model';
import { ApiResponse } from '../model/api-response.model';
import { AppState } from '../state/app.state';

export class Utilities {
  constructor(private appState: AppState) {}
  public static encodeQueryParam = (data: any) => {
    let query = '';
    if (Array.isArray(data)) {
      data?.forEach((element) => {
        query += encodeURIComponent(element.key) + '=' + encodeURIComponent(element.value) + '&';
      });
    } else {
      for (const key in data) {
        query += encodeURIComponent(key) + '=' + encodeURIComponent(data[key]) + '&';
      }
    }
    return query.slice(0, -1);
  };

  public static encodeUrlParam = (data: any) => {
    let query = '';
    for (const key in data) {
      query += '/' + encodeURIComponent(data[key]);
    }
    return query;
  };

  public static handleHttpResponse = (data: any) => {
    const response: ApiResponse = { data: null, message: '' };
    if (data && data.status == 'SUCCESS' && data.data) {
      response.data = data.data;
      response.message = data.message || '';
    }
    return response;
  };

  public static getAddressFromPlacesAPI(googlePlace: any) {
    const address = new GeoAddress();
    googlePlace?.address_components?.forEach((element: any) => {
      if (element.types.includes('street_number')) {
        address.streetNumber = element.long_name || '';
      } else if (element.types.includes('route')) {
        address.route = element.long_name || '';
      } else if (element.types.includes('locality')) {
        address.locality = element.long_name;
      } else if (element.types.includes('administrative_area_level_1')) {
        address.administrativeAreaLevel1 = element.long_name || '';
        address.stateCode = element.short_name;
      } else if (element.types.includes('administrative_area_level_2')) {
        address.administrativeAreaLevel2 = element.long_name || '';
      } else if (element.types.includes('country')) {
        address.country = element.long_name || '';
      } else if (element.types.includes('postal_code')) {
        address.postalCode = element.long_name || '';
      }
    });
    address.formatted_address = googlePlace.formatted_address || '';
    return address;
  }

  public static getAddressComp(address: any, code?: string) {
    const formattedAddress = {
      streetNumber: '',
      streetName: '',
      city: '',
      state: '',
      zip: '',
      fipsCode: '',
      parcelNumber: '',
      ownerName: '',
      unitNumber: '',
      street: '',
      streetAddress: '',
      streetWithoutAddress: '',
    };
    if (address) {
      const addressArray = address.split(',');
      formattedAddress['streetWithoutAddress'] = addressArray.slice(1)?.join(',')?.trim();
      if (addressArray[0]) {
        addressArray[0] = addressArray[0].trim();
        if (code == 'geocode') {
          formattedAddress['street'] = addressArray[0];
          formattedAddress['streetAddress'] = addressArray[0];
        } else {
          formattedAddress['streetNumber'] = addressArray[0].split(' ')[0];
          formattedAddress['streetName'] = addressArray[0].split(' ').slice(1).join(' ');
        }
      }
      if (addressArray[1]) {
        addressArray[1] = addressArray[1].trim();
        formattedAddress['city'] = addressArray[1];
      }
      if (addressArray[2]) {
        addressArray[2] = addressArray[2].trim();
        formattedAddress['state'] = addressArray[2].split(' ')[0];
        formattedAddress['zip'] = addressArray[2].split(' ').slice(1).join(' ');
      }
    }
    return formattedAddress;
  }

  public static getStdAddrComp = (address: any) => {
    const addr = {
      city: (address.city || address.City || '').toUpperCase(),
      state: (address.state || address.StateOrProvince || '').toUpperCase(),
      zip: (address.zip || address.PostalCode || '').toUpperCase(),
      streetAddress: (address.streetAddress || address.StreetAddress || '').toUpperCase(),
      streetWithoutAddress: '',
    };
    addr['streetWithoutAddress'] = `${addr.city}, ${addr.state} ${addr.zip}, USA`.toUpperCase();

    return addr;
  };

  public static getSelectedData = (data: any, selectedSource?: string) => {
    const source = selectedSource ?? data?.selectedSource ?? '';
    let value = '';
    switch (source) {
      case 'inspectionRecordData':
        value = data['inspectionRecordData'];
        break;
      case 'listingRecordData':
        value = data['listingRecordData'];
        break;
      case 'publicRecordData':
        value = data['publicRecordData'];
        break;
      case 'userRecordData':
        value = data['userRecordData'];
        break;
      default:
        break;
    }
    return value;
  };
}
