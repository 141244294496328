import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressLoaderService {
  private _isLoadingSub$ = new BehaviorSubject<boolean>(false);
  isLoading$ = this._isLoadingSub$.asObservable();

  loaderMessage = 'Loading';

  constructor() {}

  show(): ProgressLoaderService {
    this.isLoading = true;
    return this;
  }

  hide(): void {
    this.isLoading = false;
    this.loaderMessage = 'Loading';
  }

  message(message: string): void {
    this.loaderMessage = message;
  }

  private get isLoading(): boolean {
    return this._isLoadingSub$.value;
  }

  private set isLoading(v: boolean) {
    this._isLoadingSub$.next(v);
  }
}
