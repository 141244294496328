import { environment } from 'src/environments/environment';

const API_BASE_URL = environment.backendBaseUrl;
export class EndPoints {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static API_URLS = {
    getAllFormMetadata: API_BASE_URL + '/api/getAllFormMetadata',
    getCompanySettings: API_BASE_URL + '/api/companySettingses/search/findCompanyByCompanyKey',
    updateCompanySettings: API_BASE_URL + '/api/companySettingses',

    getUserSettings: API_BASE_URL + '/api/getUserSettings',
    getMLSBoard: API_BASE_URL + '/api/proxy/get?api=/mls/v3/GetMLSBoards',
    saveMLSAccessData: API_BASE_URL + '/api/saveAndVerifyMLSMembership',
    deleteMLSAccessData: API_BASE_URL + '/api/deleteMLSMembership',
    updateUserSettings: API_BASE_URL + '/api/userSettingses',
  };
}
