import { Component } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { SlidingSheetComponent } from '../../interface/sliding-sheet.interface';
import { CompanyState } from '../../state/company.state';
import { UserState } from '../../state/user.state';

@Component({
  selector: 'app-main-navigation',
  templateUrl: './main-navigation.component.html',
  styleUrls: ['./main-navigation.component.scss'],
})
export class MainNavigationComponent implements SlidingSheetComponent {
  slidingSheet!: MatSidenav;

  constructor(
    private _router: Router,
    public companyState: CompanyState,
    public userState: UserState
  ) {}

  isCurrentRoute(path: string): boolean {
    const currentUrl = this._router.url;
    if (path == '/orders/new' && currentUrl.startsWith('/orders/new')) return true;
    if (path == '/orders/orderId' && /\/orders\/[0-9a-f]{24}\//.test(currentUrl)) return true;
    if (path == '/company' && currentUrl.startsWith('/company')) return true;
    if (path == '/user' && currentUrl.startsWith('/user')) return true;
    return false;
  }
}
