<mat-form-field appearance="outline">
  <mat-label *ngIf="label"> {{ label }} </mat-label>

  <input
    *ngIf="type == 'number'; else numberStringInput"
    matInput
    [(ngModel)]="modelCtrlValue"
    [required]="required"
    [disabled]="isDisabled"
    [mask]="isPercentage ? 'percent.2' : 'separator.2'"
    thousandSeparator=","
    [allowNegativeNumbers]="true"
  />

  <ng-template #numberStringInput>
    <input
      *ngIf="type == 'numberString'; else stringInput"
      matInput
      [(ngModel)]="modelCtrlValue"
      [required]="required"
      [disabled]="isDisabled"
      mask="0*"
    />
  </ng-template>

  <ng-template #stringInput>
    <input matInput [type]="type" [(ngModel)]="modelCtrlValue" [required]="required" [disabled]="isDisabled" />
  </ng-template>

  <mat-icon *ngIf="prefixIcon" matPrefix> {{ prefixIcon }} </mat-icon>

  <span matTextPrefix *ngIf="prefixText"> {{ prefixText }}&nbsp;</span>

  <span matTextSuffix *ngIf="suffixText"> {{ suffixText }}&nbsp;</span>

  <button *ngIf="suffixIcon" mat-icon-button matSuffix (click)="onSuffixBtnClick()">
    <mat-icon> {{ suffixIcon }} </mat-icon>
  </button>

  <mat-icon *ngIf="infoText" class="input-info-suffix" matSuffix [matTooltip]="infoText"> info </mat-icon>

  <mat-hint *ngIf="hint"> {{ hint }} </mat-hint>
</mat-form-field>
