import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ExceptionConfig, HttpExceptions } from '../model/error.model';
import { ProgressLoaderService } from '../services/progress-loader.service';
import { Utilities } from '../utility/utilities';

@Injectable({
  providedIn: 'root',
})
export class ApiGatewayService {
  constructor(
    private http: HttpClient,
    private progressLoaderService: ProgressLoaderService
  ) {}

  doGet(url: string, exptnConfig: ExceptionConfig): Observable<any> {
    return new Observable((observer) => {
      if (exptnConfig.loader) {
        this.progressLoaderService.show();
      }
      this.http.get(url).subscribe({
        next: (data) => {
          this.progressLoaderService.hide();
          observer.next(Utilities.handleHttpResponse(data));
          observer.complete();
        },
        error: (error) => {
          this.progressLoaderService.hide();
          observer.error(new HttpExceptions(error, exptnConfig));
        },
      });
    });
  }

  doPost(url: string, reqBody: any, exptnConfig: ExceptionConfig): Observable<any> {
    return new Observable((observer) => {
      if (exptnConfig.loader) {
        this.progressLoaderService.show();
      }
      this.http.post(url, reqBody).subscribe({
        next: (data) => {
          this.progressLoaderService.hide();
          observer.next(Utilities.handleHttpResponse(data));
          observer.complete();
        },
        error: (error) => {
          this.progressLoaderService.hide();
          observer.error(new HttpExceptions(error, exptnConfig));
        },
      });
    });
  }

  doPut(url: string, reqBody: any, exptnConfig: ExceptionConfig): Observable<any> {
    return new Observable((observer) => {
      if (exptnConfig.loader) {
        this.progressLoaderService.show();
      }
      this.http.put(url, reqBody).subscribe({
        next: (data) => {
          this.progressLoaderService.hide();
          observer.next(Utilities.handleHttpResponse(data));
          observer.complete();
        },
        error: (error) => {
          this.progressLoaderService.hide();
          observer.error(new HttpExceptions(error, exptnConfig));
        },
      });
    });
  }

  doPatch(url: string, reqBody: any, exptnConfig: ExceptionConfig): Observable<any> {
    return new Observable((observer) => {
      if (exptnConfig.loader) {
        this.progressLoaderService.show();
      }
      this.http.patch(url, reqBody).subscribe({
        next: (data) => {
          this.progressLoaderService.hide();
          observer.next(Utilities.handleHttpResponse(data));
          observer.complete();
        },
        error: (error) => {
          this.progressLoaderService.hide();
          observer.error(new HttpExceptions(error, exptnConfig));
        },
      });
    });
  }
}
