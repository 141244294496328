import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SyncService } from './service/sync.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class SyncModule {
  constructor(private syncService: SyncService) {}
}
