import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderConfig } from '../config/order.config';
import { CostApproach } from '../interface/cost-approach.interface';
import {
  ComparableTableDataSource,
  OrderDetail,
  PropertyMap,
  SecondarySearchFilter,
  ValuationType,
} from '../interface/order.interface';
import { OrderState } from './order.state';

@Injectable({
  providedIn: 'root',
})
export class CostApproachState {
  constructor(private orderState: OrderState) {}

  /** Stores cost approach details */
  private _costApproachDetailSub$ = new BehaviorSubject<CostApproach | null>(null);
  costApproachDetail$ = this._costApproachDetailSub$.asObservable();

  get costApproachDetail(): CostApproach | null {
    return this._costApproachDetailSub$.value;
  }

  set costApproachDetail(data: CostApproach | null) {
    this._costApproachDetailSub$.next(data);

    const order: OrderDetail = structuredClone(this.orderState.orderDetail);

    if (order.costApproach) order.costApproach = data;
    this.orderState.orderDetail = structuredClone(order);
  }

  /**
   * Stores the criteria to be used to get comparables.
   * This state should be updated every time a sub-filter, pagination or sort happens on the comparables.
   * This will trigger the /getComparables API based on the saved criteria.
   */
  private _secondarySearchFilterSub$ = new BehaviorSubject<SecondarySearchFilter | null>({
    ...OrderConfig.defaultSecondarySearchFilter,
    valuationType: ValuationType.cost,
  });
  secondarySearchFilter$ = this._secondarySearchFilterSub$.asObservable();

  get secondarySearchFilter(): SecondarySearchFilter | null {
    return this._secondarySearchFilterSub$.value;
  }

  set secondarySearchFilter(order: SecondarySearchFilter | null) {
    this._secondarySearchFilterSub$.next(order);
  }

  /** Stores a list of comparable properties an active order */
  private _comparableListSub$ = new BehaviorSubject<Array<ComparableTableDataSource>>([]);
  comparableList$ = this._comparableListSub$.asObservable();

  get comparableList(): Array<ComparableTableDataSource> {
    return this._comparableListSub$.value;
  }

  set comparableList(order: Array<ComparableTableDataSource>) {
    this._comparableListSub$.next(order);
  }

  // /** Stores full list of selected comparable properties an active order */
  private _selectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  selectedComparableMap$ = this._selectedComparableMapSub$.asObservable();

  get selectedComparableMap(): PropertyMap {
    return this._selectedComparableMapSub$.value;
  }

  set selectedComparableMap(value: PropertyMap) {
    this._selectedComparableMapSub$.next(value);
  }

  /**
   * Stores total length of land comparable properties an active order
   * This is to be mainly used for pagination.
   */
  private _comparableListLengthSub$ = new BehaviorSubject<number>(0);
  comparableListLength$ = this._comparableListLengthSub$.asObservable();

  get comparableListLength(): number {
    return this._comparableListLengthSub$.value;
  }

  set comparableListLength(length: number) {
    this._comparableListLengthSub$.next(length);
  }
}
