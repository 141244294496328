<app-progress-loader />

<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #slidingSheet [fixedInViewport]="true">
    <ng-template [sliding-sheet]="slidingSheet"></ng-template>
  </mat-sidenav>
  <mat-sidenav-content class="app-sidenav-content">
    <!-- <app-header *ngIf="authState.isAuthenticated$ | async" (sideNavToggle)="slidingSheet.toggle()" /> -->
    <lib-header
      *ngIf="authState.isAuthenticated$ | async"
      [appName]="app"
      [template]="create_order"
      (menuEvent)="sideMenuToggle()"
    />

    <ng-template #create_order>
      <div class="d-flex w-100 justify-content-between mx-5">
        <div>
          <mat-form-field
            appearance="outline"
            class="mat-form-field-sm company-switch"
            *ngIf="userState?.permittedActions?.includes('company_switch')"
          >
            <mat-label>Company</mat-label>
            <mat-select [formControl]="selectedCompany">
              <mat-option *ngFor="let company of companyState.companyList$ | async" [value]="company.key">
                {{ company.displayValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div>
          <a mat-stroked-button color="primary" routerLink="/orders/new" class="small_btn">
            <mat-icon>list_alt_add</mat-icon>
            Create Order
          </a>
        </div>
      </div>
    </ng-template>
    <!-- All pages rendered here -->
    <main class="body_wrap">
      <router-outlet />
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
