type FieldDescription = {
  [k in InfoSheetKeyType]: {
    title: string;
    content: string;
  };
};

export type InfoSheetKeyType = 'monthsBack';

export class InfoSheetConfig {
  /**
   * This data is used to populate the info-sheet based on the key.
   * @param title: Will be shown on the header of the sheet.
   * @param content: Will be the main body of the sheet. Can be HTML string. All styles should be inline.
   */
  static readonly sheetDataMap: FieldDescription = {
    monthsBack: {
      title: 'Months Back',
      content: `
      <ul>\
        <li style="margin-bottom: 1rem">It is calculated from the date specified by the EffectiveDate parameter. The default value for EffectiveDate is the current Date</li>\
        <li style="margin-bottom: 1rem">It is applied to CloseDate for <b>Sold</b> listings, to ModificationTimestamp for “Cancel/Withdrawn” listings and to ListingContractDate for listings in all other statuses</li>\
      </ul>`,
    },
  };
}
