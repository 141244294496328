import { ComponentRef, Directive, Input, OnInit, Renderer2, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SlidingSheetState } from '../state/sliding-sheet.state';

@Directive({
  selector: '[sliding-sheet]',
  standalone: true,
})
export class SlidingSheetDirective implements OnInit {
  componentRef!: ComponentRef<any>;

  @Input('sliding-sheet') slidingSheet!: MatSidenav;

  constructor(
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef,
    // private _zone: NgZone,
    private slidingSheetState: SlidingSheetState
  ) {}

  ngOnInit(): void {
    this.slidingSheetState.dynamicComponent$.subscribe(({ component, data, config }) => {
      // this._zone.runOutsideAngular(() => {
      this._viewContainerRef.clear();

      this.componentRef = this._viewContainerRef.createComponent<any>(component);

      if (data && Object.keys(data).length) {
        Object.keys(data).forEach((key) => {
          this.componentRef.instance[key] = data[key];
        });
      }

      this.componentRef.instance.slidingSheet = this.slidingSheet;

      this._renderer.setStyle(this.componentRef.location.nativeElement, 'display', 'block');
      this._renderer.setStyle(this.componentRef.location.nativeElement, 'maxWidth', 'calc(100vw - 5em)');

      if (config?.width) this._renderer.setStyle(this.componentRef.location.nativeElement, 'width', config.width);

      if (config?.minWidth)
        this._renderer.setStyle(this.componentRef.location.nativeElement, 'minWidth', config.minWidth);

      this.slidingSheet.position = config?.position || 'start';
      // });
    });

    this.slidingSheet.openedChange.subscribe((isOpened) => {
      if (isOpened != this.slidingSheetState.isOpen$.value) this.slidingSheetState.isOpen$.next(isOpened);
    });

    this.slidingSheetState.isOpen$.subscribe((isOpen) => {
      // this._zone.runOutsideAngular(() => {
      if (isOpen) this.slidingSheet.open();
      else {
        this.componentRef?.destroy();
        this.slidingSheet.close();
        this.slidingSheet.position = 'start';
      }
      // });
    });
  }
}
