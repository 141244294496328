import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OrderDetail } from '../interface/order.interface';
import { ProjectInformation } from '../interface/project-information.interface';
import { OrderState } from './order.state';

@Injectable({
  providedIn: 'root',
})
export class ProjectInformationState {
  constructor(private orderState: OrderState) {}

  /**Stores Project Information details in order */
  private _projectInformationSub$ = new BehaviorSubject<ProjectInformation>({} as ProjectInformation);
  projectInformation$ = this._projectInformationSub$.asObservable();

  get projectInformation(): ProjectInformation {
    return this._projectInformationSub$.value;
  }

  set projectInformation(projectInfo: ProjectInformation) {
    this._projectInformationSub$.next(projectInfo);

    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    if (order.projectInformation) order.projectInformation = projectInfo;
    this.orderState.orderDetail = structuredClone(order);
  }
}
