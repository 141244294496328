import { type ResourcesConfig } from 'aws-amplify';
import { environment } from './environments/environment';

const awsAmplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: environment.cognitoUserPoolId,
      userPoolClientId: environment.cognitoAppClientId,
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: environment.cognitoAuthDomain,
          scopes: ['phone', 'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [`${environment.appFrontendUrl}/app/orders`],
          redirectSignOut: [`${environment.logInUrl}`],
          responseType: 'code',
        },
      },
    },
  },
};

export default awsAmplifyConfig;
