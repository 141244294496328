<mat-card class="example-card p-3" [formGroup]="jsonForm">
  <h3 class="mb-3">{{ jsonFormData.name }}</h3>
  <!-- <form [formGroup]="jsonForm"> -->
  <div class="row flex-wrap settings_body">
    <div class="flex-fill mb-4" *ngFor="let control of jsonFormData?.controls">
      <app-mls-access
        *ngIf="control.displayType == 'mlsAccess' && control.isEnable"
        [control]="getFormControl(control.name)"
        [field]="control"
        (onSubmitMls)="updateControl($event)"
      />
      <profet-input
        class="w-auto d-inline-block"
        *ngIf="['text', 'password'].includes(control.displayType) && control.isEnable"
        [formControlName]="control.name"
        [label]="control.displayName"
      />
      <profet-radio-group
        *ngIf="control.displayType == 'radio' && control.isEnable"
        [formControlName]="control.name"
        [label]="control.displayName"
        [options]="control.enums"
      />
      <profet-checkbox
        *ngIf="control.displayType == 'checkbox' && control.isEnable"
        [formControlName]="control.name"
        [label]="control.displayName"
      />
      <profet-textarea
        class="w-auto d-inline-block"
        *ngIf="control.displayType == 'textarea' && control.isEnable"
        [formControlName]="control.name"
        [label]="control.displayName"
      />
      <profet-select
        class="w-auto d-inline-block"
        *ngIf="control.displayType == 'dropdown' && control.isEnable"
        [outerLabel]="control.displayName"
        [formControlName]="control.name"
        [options]="control.enums"
        [multiple]="control.options?.multiple ?? false"
      />
      <profet-slide-toggle
        *ngIf="control.displayType == 'toggle' && control.isEnable"
        [formControlName]="control.name"
        [label]="control.displayName"
      />
      <img [src]="jsonForm.get(control.name)?.value || ''" *ngIf="control.displayType == 'image' && control.isEnable" />
      <button
        class="bg-light"
        *ngIf="control.displayType == 'listEnum' && control.isEnable"
        mat-stroked-button
        color="primary"
        (click)="openOrderedListComp(control, $event)"
      >
        <mat-icon>settings</mat-icon>
        {{ control.displayName }}
      </button>
      <button
        class="bg-light"
        *ngIf="control.displayType == 'orderedListEnum' && control.isEnable"
        mat-stroked-button
        color="primary"
        (click)="openOrderedListComp(control, $event)"
      >
        <mat-icon>settings</mat-icon>
        {{ control.displayName }}
      </button>
      <button
        class="bg-light"
        *ngIf="control.displayType == 'orderedListString' && control.isEnable"
        mat-stroked-button
        color="primary"
        (click)="openOrderedListComp(control, $event)"
      >
        <mat-icon>settings</mat-icon>
        {{ control.displayName }}
      </button>
      <button
        class="bg-light"
        color="primary"
        *ngIf="control.displayType == 'orderedListEditableString' && control.isEnable"
        mat-stroked-button
        (click)="openOrderedListComp(control, $event)"
      >
        <mat-icon>description</mat-icon>
        {{ control.displayName }}
      </button>
      <div *ngIf="control.displayType == 'testEditor' && control.isEnable">
        <label class="m-0">Add Disclosure</label>
        <app-text-editor [control]="getFormControl(control.name)" [field]="control" />
      </div>
      <p class="m-0" *ngIf="jsonForm.get(control.name)?.errors">
        <app-form-error [formError]="jsonForm.get(control.name)?.errors" />
      </p>
    </div>
  </div>
  <div class="d-flex mt-3">
    <button
      type="button"
      mat-flat-button
      color="accent"
      [disabled]="jsonForm.invalid"
      *ngIf="submitBtn"
      (click)="onSubmit()"
    >
      Save
    </button>
  </div>
  <!-- </form> -->
</mat-card>
