import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { CombinedOrderType } from 'src/app/shared/interface/report.interface';
import { ProgressLoaderService } from 'src/app/shared/services/progress-loader.service';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { CompanyState } from 'src/app/shared/state/company.state';
import { NewOrderApi } from '../api/new-order.api';
import { NewOrder, PropertySearchCriteria, SearchMode, SearchedPropertyData } from '../interface/new-order.interface';

@Injectable({
  providedIn: 'root',
})
export class NewOrderService {
  constructor(
    private newOrderApi: NewOrderApi,
    private _router: Router,
    private snackBarService: SnackBarService,
    private proxyService: ProxyService,
    private progressLoaderService: ProgressLoaderService,
    private companyState: CompanyState
  ) {}

  searchProperty(searchCriteria: PropertySearchCriteria, mode: SearchMode): Observable<SearchedPropertyData> {
    return this.proxyService.searchProperty(searchCriteria, mode);
  }

  searchOrderByAddress(searchCriteria: any): Observable<any> {
    return this.newOrderApi.searchOrderByAddress(searchCriteria);
  }

  createOrder(newOrder: NewOrder): Observable<boolean> {
    this.progressLoaderService.show().message('Creating Order');
    return this.newOrderApi
      .createOrder({ ...newOrder, reportType: newOrder.reportType || (newOrder.orderType ?? '') })
      .pipe(
        map((orderId) => {
          this.progressLoaderService.hide();
          const reportType = newOrder.reportType || newOrder.orderType;

          const initialRedirectPath: string =
            this.companyState.companySetting?.[reportType as CombinedOrderType]?.initialRedirect ?? 'basic-info';

          const path = orderId && initialRedirectPath ? orderId + '/' + initialRedirectPath : '';
          if (path) this._router.navigate([`orders/${path}`]);

          return false;
        }),
        catchError((error) => {
          this.progressLoaderService.hide();
          this.snackBarService.open('error', error.error.message);
          return of(false);
        })
      );
  }
}
