import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PropertyMap } from '../interface/order.interface';
import { OrderState } from './order.state';

@Injectable({
  providedIn: 'root',
})
export class SalesApproachState {
  constructor(private orderState: OrderState) {}

  // MOVE ALL THE SALES-APPROACH RELATED STATE MANAGEMENT CODE FROM ORDER.STATE.TS TO HERE

  // /** Stores applied/default criteria to be used to generate comparable properties for active order */
  // private _primarySearchFilterSub$ = new BehaviorSubject<PrimarySearchFilter>(OrderConfig.defaultPrimarySearchFilter);
  // primarySearchFilter$ = this._primarySearchFilterSub$.asObservable();

  // get primarySearchFilter(): PrimarySearchFilter {
  //   return this._primarySearchFilterSub$.value;
  // }

  // set primarySearchFilter(order: PrimarySearchFilter) {
  //   this._primarySearchFilterSub$.next(order);
  // }

  // /** Stores a list of comparable properties in an active order */
  // private _comparableListSub$ = new BehaviorSubject<Array<ComparableTableDataSource>>([]);
  // comparableList$ = this._comparableListSub$.asObservable();

  // get comparableList(): Array<ComparableTableDataSource> {
  //   return this._comparableListSub$.value;
  // }

  // set comparableList(order: Array<ComparableTableDataSource>) {
  //   this._comparableListSub$.next(order);
  // }

  // /**
  //  * Stores total length of comparable properties in an active order
  //  * This is to be mainly used for pagination.
  //  */
  // private _comparableListLengthSub$ = new BehaviorSubject<number>(0);
  // comparableListLength$ = this._comparableListLengthSub$.asObservable();

  // get comparableListLength(): number {
  //   return this._comparableListLengthSub$.value;
  // }

  // set comparableListLength(length: number) {
  //   this._comparableListLengthSub$.next(length);
  // }

  // /**
  //  * Stores the criteria to be used to get comparables.
  //  * This state should be updated every time a sub-filter, pagination or sort happens on the comparables.
  //  * This will trigger the /getComparables API based on the saved criteria.
  //  */
  // private _secondarySearchFilterSub$ = new BehaviorSubject<SecondarySearchFilter | null>(
  //   OrderConfig.defaultSecondarySearchFilter
  // );
  // secondarySearchFilter$ = this._secondarySearchFilterSub$.asObservable();

  // get secondarySearchFilter(): SecondarySearchFilter | null {
  //   return this._secondarySearchFilterSub$.value;
  // }

  // set secondarySearchFilter(subFilter: Partial<SecondarySearchFilter | null>) {
  //   if (subFilter !== null) {
  //     const concatenatedValue = {
  //       ...this._secondarySearchFilterSub$.value,
  //       ...subFilter,
  //     };
  //     this._secondarySearchFilterSub$.next(concatenatedValue);
  //   } else {
  //     this._secondarySearchFilterSub$.next(null);
  //   }
  // }

  /**
   * Stores complete list of selected comparable properties in an active order.
   * DO NOT USE THIS STATE to read/write selected comparable properties.
   * Use selectedComparableMap$ instead.
   */
  // private _selectedComparableListSub$ = new BehaviorSubject<Array<ExtendedPropertyDetail>>([]);
  // selectedComparableList$ = this._selectedComparableListSub$.asObservable();

  // get selectedComparableList(): Array<ExtendedPropertyDetail> {
  //   return this._selectedComparableListSub$.value;
  // }

  // set selectedComparableList(order: Array<ExtendedPropertyDetail>) {
  //   this._selectedComparableListSub$.next(order);
  // }

  /**
   * Stores complete list of selected comparable properties in an active order just like selectedComparableList$.
   * However it stores the data in a map format for faster read/write operations.
   * Only use this state when you need to read/write selected comparable properties.
   */
  private _selectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  selectedComparableMap$ = this._selectedComparableMapSub$.asObservable();

  get selectedComparableMap(): PropertyMap {
    return this._selectedComparableMapSub$.value;
  }

  set selectedComparableMap(value: PropertyMap) {
    this._selectedComparableMapSub$.next(value);
    // this.selectedComparableList = [...Object.values(value)];
  }
}
