import { Directive, HostBinding, Input } from '@angular/core';
import { StandardStatusType } from 'src/app/module/property/interface/property.interface';

@Directive({
  selector: '[standardStatus]',
})
export class StandardStatusDirective {
  status!: StandardStatusType;

  @Input() set standardStatus(status: string) {
    this.status = status as StandardStatusType;
  }

  @HostBinding('class.propstatus_base') readonly baseClass = true;

  @HostBinding('class.sold') get soldClass() {
    return this.status == StandardStatusType.sold;
  }

  @HostBinding('class.active') get activeClass() {
    return this.status == StandardStatusType.active;
  }

  @HostBinding('class.pending') get cancelOrWithdrawnClass() {
    return this.status == StandardStatusType.canceled;
  }

  @HostBinding('class.expired') get expiredClass() {
    return this.status == StandardStatusType.expired;
  }
}
