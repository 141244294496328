import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/module/auth/service/auth.service';

export const authGuard: CanActivateFn = async (
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  authService = inject(AuthService)
) => {
  return authService
    .validateAuthentication()
    .then((isAuthenticated: boolean) => {
      if (isAuthenticated) return true;
      else {
        // authService.logout();
        return false;
      }
    })
    .catch(() => {
      // authService.logout();
      return false;
    });
};
