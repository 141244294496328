import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { SlidingSheetConfig } from '../interface/sliding-sheet.interface';

@Injectable({
  providedIn: 'root',
})
export class SlidingSheetState {
  dynamicComponent$: Subject<any> = new Subject<any>();

  isOpen$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  config$: Subject<SlidingSheetConfig | undefined> = new Subject<SlidingSheetConfig | undefined>();
}
