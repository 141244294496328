import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserState } from '../state/user.state';

@Directive({
  selector: '[appAccessControl]',
})
export class AccessControlDirective {
  @Input() set appAccessControl(roles: string[]) {
    const value = roles.some((r: string) => {
      return this.userState.userSetting?.roles?.includes(r);
    });
    if (value) {
      this.viewRef.createEmbeddedView(this.tempRef);
    } else {
      this.viewRef.clear();
    }
  }

  constructor(
    private tempRef: TemplateRef<any>,
    private viewRef: ViewContainerRef,
    private userState: UserState
  ) {}
}
