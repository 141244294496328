import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ProgressSpinnerComponent } from 'src/app/shared/components/progress-spinner/progress-spinner.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { InputComponent } from './component/input/input.component';
import { RadioGroupComponent } from './component/radio-group/radio-group.component';
import { SelectComponent } from './component/select/select.component';
import { SlideToggleComponent } from './component/slide-toggle/slide-toggle.component';
import { TextAreaComponent } from './component/textarea/textarea.component';

@NgModule({
  declarations: [
    CheckboxComponent,
    DatePickerComponent,
    SelectComponent,
    InputComponent,
    RadioGroupComponent,
    SlideToggleComponent,
    TextAreaComponent,
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressSpinnerComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    CheckboxComponent,
    DatePickerComponent,
    SelectComponent,
    InputComponent,
    RadioGroupComponent,
    SlideToggleComponent,
    TextAreaComponent,
  ],
  providers: [provideNgxMask()],
})
export class ProfetControlWidgetModule {}
