import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnumItem } from '../interface/common.interface';
import { CompanySetting } from '../interface/company.interface';

@Injectable({
  providedIn: 'root',
})
export class CompanyState {
  /** Stores details related to the current company */
  private _companySettingSub$ = new BehaviorSubject<CompanySetting>({} as CompanySetting);
  companySetting$ = this._companySettingSub$.asObservable();

  get companySetting(): CompanySetting {
    return this._companySettingSub$.value;
  }

  set companySetting(company: CompanySetting) {
    this._companySettingSub$.next(company);
  }

  /**
   * Stores details related to all companies for which Profet Valuation is enabled.
   * Used to show company filter for Profet Admin
   */
  private _companyListSub$ = new BehaviorSubject<EnumItem[] | null>(null);
  companyList$ = this._companyListSub$.asObservable();

  get companyList(): EnumItem[] | null {
    return this._companyListSub$.value;
  }

  set companyList(companyList: EnumItem[] | null) {
    this._companyListSub$.next(companyList);
  }
}
