import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { OrderConfig } from '../../order/config/order.config';
import { NeighborhoodInsight } from '../../order/interface/neighborhood.interface';
import { OrderDetail, PropertyMap } from '../../order/interface/order.interface';
import { ExtendedPropertyDetail } from '../../property/interface/property.interface';
import { SyncScope } from '../interface/sync.interface';

@Injectable({
  providedIn: 'root',
})
export class SyncState {
  constructor() {}

  /** Stores the status of sync in progress */
  private _isSyncInProgressSub$ = new BehaviorSubject<SyncScope[]>([]);
  isSyncInProgress$: Observable<boolean> = this._isSyncInProgressSub$.asObservable().pipe(
    map((scopeList: SyncScope[]): boolean => {
      if (!scopeList.length) return false;
      return true;
    })
  );

  /** Update the current state of the sync for different scopes  */
  setIsSyncInProgress(scope: SyncScope, inProgress: boolean): void {
    const currentScopeSet = new Set(this._isSyncInProgressSub$.value);

    if (inProgress) {
      currentScopeSet.add(scope);
    } else {
      currentScopeSet.delete(scope);
    }

    this._isSyncInProgressSub$.next(Array.from(currentScopeSet));
  }

  /** Stores the last state of the active order when sync was completed successfully */
  private _syncedOrderDetailSub$ = new BehaviorSubject<OrderDetail>({} as OrderDetail);
  syncedOrderDetail$ = this._syncedOrderDetailSub$.asObservable();

  get syncedOrderDetail(): OrderDetail {
    return this._syncedOrderDetailSub$.value;
  }

  set syncedOrderDetail(order: OrderDetail) {
    this._syncedOrderDetailSub$.next(order);
  }

  /** Stores the last state of subject property of the active order when sync was completed successfully */
  private _syncedSubjectPropertyDataSub$ = new BehaviorSubject<ExtendedPropertyDetail | null>(null);
  syncedSubjectPropertyData$ = this._syncedSubjectPropertyDataSub$.asObservable();

  get syncedSubjectPropertyData(): ExtendedPropertyDetail | null {
    return this._syncedSubjectPropertyDataSub$.value;
  }

  set syncedSubjectPropertyData(propertyData: ExtendedPropertyDetail | null) {
    this._syncedSubjectPropertyDataSub$.next(propertyData);
  }

  /** Stores the last state of selected comparable properties in an active order when sync was completed successfully */
  private _syncedSelectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  syncedSelectedComparableMap$ = this._syncedSelectedComparableMapSub$.asObservable();

  get syncedSelectedComparableMap(): PropertyMap {
    return this._syncedSelectedComparableMapSub$.value;
  }

  set syncedSelectedComparableMap(order: PropertyMap) {
    this._syncedSelectedComparableMapSub$.next(order);
  }

  /** Stores the last state of neighborhood insights an active order when sync was completed successfully */
  private _syncedNeighborhoodInsightSub$ = new BehaviorSubject<NeighborhoodInsight>(
    OrderConfig.defaultNeighborhoodInsight
  );
  syncedNeighborhoodInsight$ = this._syncedNeighborhoodInsightSub$.asObservable();

  get syncedNeighborhoodInsight(): NeighborhoodInsight {
    return this._syncedNeighborhoodInsightSub$.value;
  }

  set syncedNeighborhoodInsight(order: NeighborhoodInsight) {
    this._syncedNeighborhoodInsightSub$.next(order);
  }

  /** Stores the last state of selected comparable properties in an active order when sync was completed successfully */
  private _syncedCostApproachSelectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  syncedCostApproachSelectedComparableMap$ = this._syncedCostApproachSelectedComparableMapSub$.asObservable();

  get syncedCostApproachSelectedComparableMap(): PropertyMap {
    return this._syncedCostApproachSelectedComparableMapSub$.value;
  }

  set syncedCostApproachSelectedComparableMap(order: PropertyMap) {
    this._syncedCostApproachSelectedComparableMapSub$.next(order);
  }

  /** Stores the last state of short term rent selected comparable properties in an active order when sync was completed successfully */
  private _syncedShortTermRentSelectedComparableMapSub$ = new BehaviorSubject<PropertyMap>({});
  syncedShortTermRentSelectedComparableMap$ = this._syncedShortTermRentSelectedComparableMapSub$.asObservable();

  get syncedShortTermRentSelectedComparableMap(): PropertyMap {
    return this._syncedShortTermRentSelectedComparableMapSub$.value;
  }

  set syncedShortTermRentSelectedComparableMap(order: PropertyMap) {
    this._syncedShortTermRentSelectedComparableMapSub$.next(order);
  }
}
