export class GeoAddress {
  constructor(
    public streetNumber?: string,
    public route?: string,
    public locality?: string,
    public administrativeAreaLevel1?: string,
    public administrativeAreaLevel2?: string,
    public country?: string,
    public postalCode?: string,
    public stateCode?: string,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    public formatted_address?: string
  ) {}
}
