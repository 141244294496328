import { Pipe, PipeTransform } from '@angular/core';
import { AppEnumMap, EnumItem } from '../interface/common.interface';
import { AppState } from '../state/app.state';

export interface EnumRefPipeOptions {
  /** If true, the display value will be transformed to key value */
  reverse?: boolean;

  /** If true, return null if a matching enumeration is not found */
  validOnly?: boolean;

  /** If true, use abbreviated display value (if available) instead of display value */
  abbreviated?: boolean;
}

@Pipe({
  name: 'enumRef',
  standalone: true,
})
export class EnumRefPipe implements PipeTransform {
  constructor(private appState: AppState) {}

  /**
   *
   * @param value The string value to be transformed
   * @param enumType The enum type to be used for transformation
   * @returns
   */
  transform(
    value: string | number | boolean | Date | null | undefined,
    enumType: keyof AppEnumMap | null | undefined,
    options: EnumRefPipeOptions = {
      reverse: false,
      validOnly: false,
      abbreviated: false,
    }
  ): string | null {
    if (!value || !enumType || typeof value != 'string') {
      if (options.validOnly) return null;
      return value as string;
    }

    const enumData: EnumItem[] | undefined = this.appState.enumMap[enumType as keyof AppEnumMap];

    if (!enumData) {
      if (options.validOnly) return null;
      return value;
    }

    const enumKeyList: string[] = value
      .split(',')
      .map((value) => value.trim())
      .filter((value) => value);

    if (!enumKeyList.length) return null;

    const transformedStringList: string[] = [];

    enumKeyList.forEach((enumKey) => {
      if (!options.reverse) {
        const transformedValue = this.matchKeyToValue(enumKey, enumData, options.validOnly, options.abbreviated);
        if (transformedValue) transformedStringList.push(transformedValue);
      } else {
        const transformedValue = this.matchValueToKey(enumKey, enumData, options.validOnly);
        if (transformedValue) transformedStringList.push(transformedValue);
      }
    });

    return transformedStringList.join(', ');
  }

  matchKeyToValue(
    key: string,
    enumList: EnumItem[],
    validOnly: boolean = false,
    abbreviated: boolean = false
  ): string | null {
    const enumValue = enumList.find((enumData) => enumData.key == key);

    if (validOnly && !enumValue) return null;

    if (abbreviated) return enumValue?.abbreviateDisplayValue ?? enumValue?.displayValue ?? key;

    return enumValue?.displayValue ?? key;
  }

  matchValueToKey(value: string, enumList: EnumItem[], validOnly: boolean = false): string | null {
    const enumValue = enumList.find((enumData) => enumData.displayValue == value);

    if (validOnly && !enumValue) return null;

    return enumValue?.key ?? value;
  }
}
