import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { CommonApiResponse } from 'src/app/shared/interface/common.interface';
import { OrderType } from 'src/app/shared/interface/report.interface';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { environment as ENV } from 'src/environments/environment';
import { SyncUpdateSet } from '../interface/sync.interface';

@Injectable({
  providedIn: 'root',
})
export class SyncApi {
  constructor(
    private _http: HttpClient,
    private snackBarService: SnackBarService
  ) {}

  updateOrder(orderId: string, orderType: OrderType, params: SyncUpdateSet[]): Observable<boolean> {
    return this._http
      .patch<
        CommonApiResponse<SyncUpdateSet>
      >(`${ENV.backendBaseUrl}/api/updateOrder/${orderId}?orderType=${orderType}`, params)
      .pipe(
        map(() => true),
        catchError((error: HttpErrorResponse) => {
          const errorMessage = error.error.message;
          this.snackBarService.open('error', errorMessage);
          return of(false);
        })
      );
  }
}
