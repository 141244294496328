<h1 mat-dialog-title>{{ dialogData?.title }}</h1>
<div mat-dialog-content>
  {{ dialogData?.description }}
</div>
<div mat-dialog-actions class="justify-content-end">
  <button mat-button mat-dialog-close (click)="onClose(false)">{{ dialogData?.buttonLabel[0] }}</button>
  <button mat-button mat-dialog-close color="primary" cdkFocusInitial (click)="onClose(true)">
    {{ dialogData?.buttonLabel[1] }}
  </button>
</div>
