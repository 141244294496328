import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { CookieStorage } from 'aws-amplify/utils';
import { AppModule } from './app/app.module';
import awsAmplifyConfig from './aws.export';
import { environment as ENV } from './environments/environment';

Amplify.configure(awsAmplifyConfig);

const cookieStorage = new CookieStorage({
  domain: ENV.cookieStorage.domain,
  path: ENV.cookieStorage.path,
  expires: ENV.cookieStorage.expires,
  secure: ENV.cookieStorage.secure,
  sameSite: 'strict',
});
cognitoUserPoolsTokenProvider.setKeyValueStorage(cookieStorage);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
