<div
  *ngIf="progressLoaderService.isLoading$ | async"
  class="progress-overlay d-flex justify-content-center align-items-center text-center"
>
  <div class="progress-container">
    <img src="assets/preload_common.svg" height="80px" width="80px" />
    <h2>
      {{ progressLoaderService.loaderMessage }}<span class="ellipse">{{ ellipsis }}</span>
    </h2>
  </div>
</div>
