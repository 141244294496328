import { OrderListApiResponse, OrderListFilter } from '../interface/order-list.interface';

export class OrderListConfig {
  /**
   * Default filter used on order-list page on initial page load.
   */
  static readonly defaultFilter: Partial<OrderListFilter> = {
    orderType: null,
    status: null,
    createdDate: {
      from: null,
      to: null,
    },
    pageLimit: 25,
    pageNumber: 1,
    state: null,
    city: null,
    zip: null,
    orderId: null,
    ownedUsername: null,
  };

  /**
   * Default Order list data to be set on the order list state
   */
  static readonly defaultOrderListData: OrderListApiResponse = {
    orderList: [],
    config: [],
    pageLimit: 25,
    pageNumber: 1,
    totalCount: 0,
  };
}
