import { AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Directive({
  selector: '[pmxShowInfo]',
})
export class ShowInfoDirective implements AfterViewInit {
  @Input() pmxShowInfo!: string;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _viewContainerRef: ViewContainerRef
  ) {}

  ngAfterViewInit(): void {
    this._viewContainerRef.clear();
    const buttonRef = this._viewContainerRef.createComponent(MatIcon);
    // buttonRef.setInput('fontIcon', 'info');
    // buttonRef.changeDetectorRef.detectChanges();

    const infoButtonElement = buttonRef.instance._elementRef.nativeElement;

    // Style the info button
    // this._renderer.appendChild(infoButtonElement, this._renderer.createText('i'));
    this._renderer.addClass(infoButtonElement, 'btn-show-info');

    this._renderer.setProperty(infoButtonElement, 'innerHTML', 'info');
    // // this._renderer.setStyle(infoButtonElement, 'height', hostElement.clientHeight + 'px');
    // this._renderer.setStyle(infoButtonElement, 'left', hostElement.offsetLeft + hostElement.clientWidth + 'px');

    // const iconElement = this._renderer.createElement('mat-icon');
    // this._renderer.setAttribute(iconElement, 'aria-hidden', 'true');
    // this._renderer.appendChild(iconElement, this._renderer.createText('info'));
    // this._renderer.appendChild(infoButtonElement, iconElement);

    // Add the button to host element
    // this._renderer.appendChild(hostElement, infoButtonElement);
  }
}
