import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PendingState } from '../interface/async-data.interface';

@Injectable({
  providedIn: 'root',
})
export class AsyncDataState {
  constructor() {}

  /** Stores the list of panding states calling asynchronously in backend  */
  private _asyncPendingDataSub$ = new BehaviorSubject<PendingState[]>([]);
  asyncPendingData$ = this._asyncPendingDataSub$.asObservable();

  get asyncPendingData(): PendingState[] {
    return this._asyncPendingDataSub$.value;
  }

  set asyncPendingData(states: PendingState[]) {
    this._asyncPendingDataSub$.next(states);
  }
}
