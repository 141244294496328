export interface SyncUpdateSet {
  id?: string;
  scope: 'ORDER' | 'PROPERTY';
  jsonPath: string;
  value: any;
}

export type StateScope =
  | 'ORDER'
  | 'SUBJECT'
  | 'SALES_SELECTED_COMPS'
  | 'COST_SELECTED_COMPS'
  | 'SHORT_TERM_RENTAL_SELECTED_COMPS';

export enum SyncScope {
  ORDER = 'ORDER',
  SUBJECT = 'SUBJECT',
  SALES_SELECTED_COMPS = 'SALES_SELECTED_COMPS',
  COST_SELECTED_COMPS = 'COST_SELECTED_COMPS',
  NEIGHBORHOOD_INSIGHTS = 'NEIGHBORHOOD_INSIGHTS',
  SHORT_TERM_RENTAL_SELECTED_COMPS = 'SHORT_TERM_RENTAL_SELECTED_COMPS',
}
