import { Injectable } from '@angular/core';
import { JSONPath } from 'jsonpath-plus';
import { BehaviorSubject, Observable, filter, map, pairwise } from 'rxjs';

type LocalStorageScopeType = keyof typeof LocalStorageScope;

export enum LocalStorageScope {
  preference = 'preference',
}

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /** Stores all localStorage data set using LocalStorageService */
  private _dataSub$ = new BehaviorSubject<any>({});
  private _data$ = this._dataSub$.asObservable();

  constructor() {
    const data: Record<string, unknown> = {};
    Object.keys(localStorage).forEach((key: string) => {
      // Only consider data added through this service
      if (!Object.keys(LocalStorageScope).includes(key)) return;

      const localStorageText = localStorage.getItem(key);
      if (localStorageText) data[key] = JSON.parse(localStorageText);
    });

    this.data = data;
  }

  /**
   * Sets a value under the scope
   * @param scope A group where the values should be added.
   * @param path JSON path in the group object where the value needs to be added.
   * @param value New value to be added to the scope.
   */
  setItem(scope: LocalStorageScopeType, path: string, value: unknown): void {
    const jsonString: string | null = localStorage.getItem(scope);

    let jsonData: Record<string, unknown> = {};

    if (jsonString) jsonData = JSON.parse(jsonString) as Record<string, unknown>;

    const updatedData = this._updateJson(jsonData, path, value);

    localStorage.setItem(scope, JSON.stringify(updatedData));

    const stateData: Record<string, unknown> = structuredClone(this.data) as Record<string, unknown>;
    stateData[scope] = updatedData;

    this.data = stateData;
  }

  /**
   * Get a value under the scope
   * @param scope A group where the values should be added.
   * @param path JSON path in the group object where the value needs to be added.
   * @returns The value in the scopes json path.
   */
  getItem(scope: LocalStorageScopeType, path: string): unknown {
    const jsonString = localStorage.getItem(scope);

    if (!jsonString) return null;

    const jsonData: Record<string, unknown> = JSON.parse(jsonString) as Record<string, unknown>;

    if (typeof jsonData !== 'object') return jsonData;

    return JSONPath({ json: jsonData, path: path, wrap: false });
  }

  /**
   * Observable to get a value under the scope when it changes
   * @param scope A group where the values should be added.
   * @param path JSON path in the group object where the value needs to be added.
   * @returns The value in the scopes json path if it changes.
   */
  getItem$(scope: LocalStorageScopeType, path: string): Observable<unknown> {
    return this._data$.pipe(
      pairwise(),
      filter(([previous, current]) => {
        const previousPathValue: string = JSONPath({ json: previous, path: `${scope}.${path}`, wrap: false });
        const currentPathValue: string = JSONPath({ json: current, path: `${scope}.${path}`, wrap: false });
        return previousPathValue != currentPathValue;
      }),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      map(([previous, current]) => JSONPath({ json: current, path: `${scope}.${path}`, wrap: false }))
    );
  }

  /**
   * Clear all the values or reset local storage.
   * Usually used on session log-out.
   */
  clearAll(): void {
    const resetData: Record<string, unknown> = structuredClone(this.data) as Record<string, unknown>;
    Object.keys(LocalStorageScope).forEach((key: string) => {
      resetData[key] = null;
      localStorage.setItem(key, '');
    });
  }

  /**
   * Updates a JSON object with a new value at the specified path.
   * @param json JSON object that needs to be updated.
   * @param jsonPath JSON path where new value should be updated
   * @param value New value to be added to the jsonpath
   * @returns Updated JSON object
   */
  private _updateJson(json: any, jsonPath: string, value: unknown): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    if (!jsonPath) return json;

    const pathArray = jsonPath.split('.');
    let current = json;

    for (let i = 0; i < pathArray.length; i++) {
      const key = pathArray[i];

      if (!current[key]) {
        // If the key doesn't exist, create an object
        current[key] = {};
      }

      if (i === pathArray.length - 1) {
        // If it's the last key, set the value
        current[key] = value;
      } else {
        // Otherwise, update 'current' to the nested object
        current = current[key];
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return json;
  }

  private get data(): unknown {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._dataSub$.value;
  }

  private set data(value: unknown) {
    this._dataSub$.next(value);
  }
}
