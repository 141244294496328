import { AfterViewInit, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { DefaultValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { SelectOptionData } from 'src/app/shared/interface/profet-control-widget.interface';

@Component({
  selector: 'profet-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useClass: DefaultValueAccessor,
      multi: true,
    },
  ],
})
export class RadioGroupComponent implements AfterViewInit, OnDestroy {
  isDisabled: boolean = false;

  // Subscription to unsubscribe all observables on component destroy
  onDestroyNotifier$ = new Subject<void>();

  @Input() label: string | null = null;
  @Input() options: Array<SelectOptionData> = [];
  @Input() hidden: boolean = false;
  @Input() required: boolean = false;

  @HostBinding('class.hidden') get hideClass() {
    return this.hidden;
  }

  constructor(public ngControl: NgControl) {}

  ngAfterViewInit(): void {
    // Disable the control if it is disabled in the parent form
    this.isDisabled = this.ngControl.status === 'DISABLED';
    this.ngControl.statusChanges
      ?.pipe(takeUntil(this.onDestroyNotifier$))
      .subscribe((status) => (this.isDisabled = status === 'DISABLED'));
  }

  ngOnDestroy(): void {
    this.onDestroyNotifier$.next();
    this.onDestroyNotifier$.complete();
  }

  get modelCtrl(): FormControl {
    return this.ngControl.control as FormControl;
  }

  get modelCtrlValue(): any {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return (this.ngControl.control as FormControl)?.value;
  }

  set modelCtrlValue(value: any) {
    if (this.ngControl.control) this.ngControl.control.setValue(value);
  }
}
