<label *ngIf="outerLabel"> {{ outerLabel }} </label>
<mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
  <mat-label *ngIf="label"> {{ label }} </mat-label>
  <mat-select
    [(ngModel)]="modelCtrlValue"
    [multiple]="multiple"
    [compareWith]="compareWithFn"
    [disabled]="isDisabled || inProgress"
    [required]="required"
  >
    <div *ngIf="canSearch" class="search-option">
      <mat-form-field appearance="outline" [subscriptSizing]="'dynamic'">
        <input
          #searchInput
          matInput
          type="text"
          class="search-input"
          tabindex="0"
          placeholder="Search"
          autocomplete="off"
          [formControl]="searchCtrl"
          (keydown)="$event.stopPropagation()"
        />
      </mat-form-field>
    </div>
    <ng-container *ngFor="let option of filteredOptions">
      <mat-optgroup [label]="option.displayValue ?? ''" *ngIf="option.children">
        <mat-option color="primary" *ngFor="let child of option.children" [value]="child.key">
          {{ child.displayValue }}
        </mat-option>
      </mat-optgroup>
      <mat-option color="primary" *ngIf="!option.children" [value]="option.key">
        {{ option.displayValue }}
      </mat-option>
    </ng-container>

    <span *ngIf="!filteredOptions?.length" class="no-results"> Not Found </span>
  </mat-select>
  <app-progress-spinner *ngIf="inProgress" [inline]="true" [size]="16"></app-progress-spinner>
  <mat-error *ngIf="modelCtrlValue">Required: Please select a value</mat-error>
</mat-form-field>
