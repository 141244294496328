import { Injectable } from '@angular/core';
import { ApproachFactoryService } from 'src/app/module/order/service/approach-factory.service';
import { ApproachFactoryState } from 'src/app/module/order/state/approach-factory.state';
import { PropertyConfig } from 'src/app/shared/config/property.config';
import { SelectedSourceDataPipe } from 'src/app/shared/pipes/selected-source-data.pipe';
import {
  OrderDetail,
  PropertyDataSourceType,
  ValueAdjustmentDataSourceType,
} from '../../order/interface/order.interface';
import { OrderMultiDataSource } from '../../order/interface/value-reconciliation.interface';
import { OrderState } from '../../order/state/order.state';
import {
  ExtendedPropertyDetail,
  PropertyAttribute,
  PropertyAttributeValue,
} from '../../property/interface/property.interface';
import { PropertyState } from '../../property/state/property.state';
import { CellDataSetterParams, UnitAdjustmentRecommendation } from '../interface/adjustment.interface';
import { ValueAdjustmentState } from '../state/value-adjustment.state';

@Injectable({
  providedIn: 'root',
})
export class CellDataSetterService {
  constructor(
    private propertyState: PropertyState,
    private orderState: OrderState,
    private selectedSourceDataPipe: SelectedSourceDataPipe,
    private valuesAdjustmentState: ValueAdjustmentState,
    private approachFactoryState: ApproachFactoryState,
    private approachFactoryService: ApproachFactoryService
  ) {}

  /** This fn will be triggered when any Unit Adjustment cell is updated */
  public setUnitAdjustmentCellData = (params: CellDataSetterParams) => {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as ExtendedPropertyDetail;
    const comparableMap = this.approachFactoryState.selectedComparableMap;
    const fieldKey: PropertyAttribute = params.key as PropertyAttribute;
    const unitAdjustment = Number(params.selectedData ?? '');
    let propertyFieldList: Array<PropertyAttribute>;

    this.approachFactoryState.gridConfig.layout.some((group) => {
      return group.children.some((config) => {
        if (config.key === fieldKey) {
          propertyFieldList = config.propertyFieldList;
          return true;
        }

        return false;
      });
    });

    if (this.approachFactoryState.activeApproachKey && order[this.approachFactoryState.activeApproachKey]) {
      if (
        order[this.approachFactoryState.activeApproachKey]?.adjustment &&
        !order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment
      ) {
        order[this.approachFactoryState.activeApproachKey]!.adjustment.unitAdjustment = {};
      }

      // Update unitAdjustment
      if (order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment) {
        order[this.approachFactoryState.activeApproachKey]!.adjustment.unitAdjustment![fieldKey] = Number(
          params.selectedData ?? ''
        );
      }
    }

    this.orderState.orderDetail = order;

    // Compute comparables Adjustment
    for (const propertyId in comparableMap) {
      if (!propertyFieldList! || !propertyFieldList?.length) continue;

      let adjustmentValue = 0;

      for (const propertyField of propertyFieldList) {
        const selectedSubjectData = Number(
          (this.selectedSourceDataPipe.transform(subjectProperty?.[propertyField]) as string) ?? 0
        );
        const selectedComparableData = Number(
          (this.selectedSourceDataPipe.transform(comparableMap[propertyId]?.[propertyField]) as string) ?? 0
        );

        adjustmentValue += Math.round(Number((selectedSubjectData - selectedComparableData) * unitAdjustment));
      }

      (comparableMap[propertyId][fieldKey] as PropertyAttributeValue).adjustmentValue.autoComputed = adjustmentValue
        ? adjustmentValue
        : null;
    }

    // Update comparable adjustments and price
    this.approachFactoryState.selectedComparableMap = comparableMap;

    this.approachFactoryService.recalculateAndUpdateProperties();
  };

  /** This fn will be triggered when applying Unit Adjustment Recommendation across all cells */
  public setAllUnitAdjustmentRecommendationData = (adjustmentType: ValueAdjustmentDataSourceType) => {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as ExtendedPropertyDetail;
    const comparableMap = this.approachFactoryState.selectedComparableMap;

    if (this.approachFactoryState.activeApproachKey && order[this.approachFactoryState.activeApproachKey]) {
      if (
        order[this.approachFactoryState.activeApproachKey]?.adjustment &&
        !order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment
      ) {
        order[this.approachFactoryState.activeApproachKey]!.adjustment.unitAdjustment = {};
      }

      const applicableAdjustment: UnitAdjustmentRecommendation | undefined =
        this.valuesAdjustmentState.unitAdjustmentRecommendation?.[adjustmentType];

      if (applicableAdjustment) {
        for (const key in applicableAdjustment) {
          let propertyFieldList: Array<PropertyAttribute>;

          this.approachFactoryState.gridConfig.layout.some((group) => {
            return group.children.some((config) => {
              if (config.key === key) {
                propertyFieldList = config.propertyFieldList;
                return true;
              }

              return false;
            });
          });

          const fieldKey: PropertyAttribute = key as PropertyAttribute;
          const unitAdjustment = Number(applicableAdjustment[fieldKey]);

          // Update unitAdjustment
          if (order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment) {
            order[this.approachFactoryState.activeApproachKey]!.adjustment.unitAdjustment![fieldKey] = unitAdjustment;
          }

          // Compute comparables Adjustment
          for (const propertyId in comparableMap) {
            if (!propertyFieldList! || !propertyFieldList?.length) continue;

            let adjustmentValue = 0;

            for (const propertyField of propertyFieldList) {
              const selectedSubjectData = Number(
                (this.selectedSourceDataPipe.transform(subjectProperty?.[propertyField]) as string) ?? 0
              );
              const selectedComparableData = Number(
                (this.selectedSourceDataPipe.transform(comparableMap[propertyId]?.[propertyField]) as string) ?? 0
              );

              adjustmentValue += Math.round(Number((selectedSubjectData - selectedComparableData) * unitAdjustment));
            }
            (comparableMap[propertyId][fieldKey] as PropertyAttributeValue).adjustmentValue.autoComputed =
              adjustmentValue ? adjustmentValue : null;
          }
        }
      }
    }

    this.orderState.orderDetail = order;

    // Update comparable adjustments and price
    this.approachFactoryState.selectedComparableMap = comparableMap;

    this.approachFactoryService.recalculateAndUpdateProperties();
  };

  /** This fn will be triggered when any Subject Property cell is updated */
  public setSubjectCellData = (params: CellDataSetterParams) => {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as ExtendedPropertyDetail;
    const fieldKey: PropertyAttribute = params.key as PropertyAttribute;

    subjectProperty[fieldKey]!.selectedSource = (params.selectedSource ?? 'userRecord') as PropertyDataSourceType;

    if (params.selectedSource === 'userRecord') {
      subjectProperty[fieldKey]!.userRecord = params.selectedData;
    }

    this.propertyState.subjectPropertyData = subjectProperty;

    // Compute comparables Adjustment if unit adjustment is available
    let propertyFieldList: Array<PropertyAttribute>;
    let groupFieldKey: PropertyAttribute;

    this.approachFactoryState.gridConfig.layout.some((group) => {
      return group.children.some((config) => {
        if (config.propertyFieldList.includes(params.key as PropertyAttribute)) {
          propertyFieldList = config.propertyFieldList;
          groupFieldKey = config.key as PropertyAttribute;
          return true;
        }

        return false;
      });
    });

    if (groupFieldKey!) {
      const unitAdjustment: number | undefined =
        order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment?.[groupFieldKey];

      if (unitAdjustment != undefined && unitAdjustment != null) {
        const comparableMap = this.approachFactoryState.selectedComparableMap;

        for (const propertyId in comparableMap) {
          if (!propertyFieldList! || !propertyFieldList?.length) continue;

          let adjustmentValue = 0;

          for (const propertyField of propertyFieldList) {
            const selectedComparableData = Number(
              (this.selectedSourceDataPipe.transform(comparableMap[propertyId]?.[propertyField]) as string) ?? 0
            );
            const selectedSubjectData = Number(
              (this.selectedSourceDataPipe.transform(subjectProperty?.[propertyField]) as string) ?? 0
            );

            adjustmentValue += Math.round(Number((selectedSubjectData - selectedComparableData) * unitAdjustment));
          }
          (comparableMap[propertyId][groupFieldKey] as PropertyAttributeValue).adjustmentValue.autoComputed =
            adjustmentValue ? adjustmentValue : null;
        }

        // Update comparable adjustments and price
        this.approachFactoryState.selectedComparableMap = comparableMap;
      }
    }

    this.approachFactoryService.recalculateAndUpdateProperties();
  };

  /** This fn will be triggered when any Comparable Property cell is updated */
  public setComparableCellData = (params: CellDataSetterParams) => {
    const order: OrderDetail = structuredClone(this.orderState.orderDetail);
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as ExtendedPropertyDetail;
    const fieldKey: PropertyAttribute = params.key as PropertyAttribute;

    const comparable = this.approachFactoryState.selectedComparableMap[params.propertyId!];

    (comparable[fieldKey] as PropertyAttributeValue).selectedSource = (params.selectedSource ??
      'userRecord') as PropertyDataSourceType;

    if (params.selectedSource === 'userRecord') {
      (comparable[fieldKey] as PropertyAttributeValue).userRecord = params.selectedData;
    }

    let propertyFieldList: Array<PropertyAttribute>;
    let groupFieldKey: PropertyAttribute;

    this.approachFactoryState.gridConfig.layout.some((group) => {
      return group.children.some((config) => {
        if (config.propertyFieldList.includes(params.key as PropertyAttribute)) {
          propertyFieldList = config.propertyFieldList;
          groupFieldKey = config.key as PropertyAttribute;
          return true;
        }

        return false;
      });
    });

    if (groupFieldKey!) {
      const unitAdjustment =
        order[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment?.[groupFieldKey] ?? 0;

      if (unitAdjustment) {
        if (propertyFieldList! && propertyFieldList?.length) {
          let adjustmentValue = 0;

          for (const propertyField of propertyFieldList) {
            const selectedSubjectData = Number(
              (this.selectedSourceDataPipe.transform(subjectProperty?.[propertyField]) as string) ?? 0
            );
            const selectedComparableData = Number(
              (this.selectedSourceDataPipe.transform(comparable[propertyField]) as string) ?? 0
            );
            adjustmentValue += Math.round(Number((selectedSubjectData - selectedComparableData) * unitAdjustment) ?? 0);
          }

          (comparable[groupFieldKey] as PropertyAttributeValue).adjustmentValue.autoComputed = adjustmentValue
            ? adjustmentValue
            : null;
        }
      }
    }

    const selectedComparableMap = this.approachFactoryState.selectedComparableMap;
    selectedComparableMap[params.propertyId!] = comparable;
    this.approachFactoryState.selectedComparableMap = selectedComparableMap;

    this.approachFactoryService.recalculateAndUpdateProperties();
  };

  /** This fn will be triggered when any Adjustment on comparable property cell is updated */
  public setComparableAdjustmentCellData = (params: CellDataSetterParams) => {
    const fieldKey: PropertyAttribute = params.key as PropertyAttribute;

    const comparable = this.approachFactoryState.selectedComparableMap[params.propertyId!];

    (comparable[fieldKey] as PropertyAttributeValue).adjustmentValue[
      params.selectedSource as keyof OrderMultiDataSource
    ] = Math.round(Number((params.selectedData as string) ?? 0));

    if (params.selectedSource === 'autoComputed') {
      (comparable[fieldKey] as PropertyAttributeValue).adjustmentValue.userEntered = null;
    }

    const selectedComparableMap = this.approachFactoryState.selectedComparableMap;
    selectedComparableMap[params.propertyId!] = comparable;
    this.approachFactoryState.selectedComparableMap = selectedComparableMap;

    this.approachFactoryService.recalculateAndUpdateProperties();
  };

  /**
   * This fn will be triggered when the copy button is clicked on the subject property cell
   * It will copy the data from the subject property cell to all the comparable properties for the same field
   */
  public copySubjectPropertyDataToComparable = (params: CellDataSetterParams) => {
    const subjectProperty = structuredClone(this.propertyState.subjectPropertyData) as ExtendedPropertyDetail;
    const comparableMap = this.approachFactoryState.selectedComparableMap;
    const groupFieldKey: PropertyAttribute = params.key as PropertyAttribute;

    let propertyFieldList: Array<PropertyAttribute>;

    this.approachFactoryState.gridConfig.layout.some((group) => {
      return group.children.some((config) => {
        if (config.key == params.key) {
          propertyFieldList = config.propertyFieldList;
          return true;
        }

        return false;
      });
    });

    // Update Comparable Properties
    for (const propertyId in comparableMap) {
      if (!propertyFieldList! || !propertyFieldList?.length) continue;

      const unitAdjustment =
        this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]?.adjustment?.unitAdjustment?.[
          groupFieldKey
        ] ?? 0;

      let adjustmentValue = 0;

      for (const propertyField of propertyFieldList) {
        let selectedSubjectData: string | number = this.selectedSourceDataPipe.transform(
          subjectProperty?.[propertyField]
        ) as string;

        if (
          PropertyConfig.propertyFieldConfig[propertyField]?.datatype === 'number' &&
          ![null, undefined, ''].includes(selectedSubjectData)
        ) {
          selectedSubjectData = Number(selectedSubjectData);
        }

        (comparableMap[propertyId][propertyField] as PropertyAttributeValue).userRecord = selectedSubjectData;
        (comparableMap[propertyId][propertyField] as PropertyAttributeValue).selectedSource = 'userRecord';

        if (unitAdjustment && typeof selectedSubjectData == 'number') {
          const selectedComparableData = Number(
            (this.selectedSourceDataPipe.transform(comparableMap[propertyId]?.[propertyField]) as string) ?? 0
          );
          adjustmentValue += Math.round(Number((selectedSubjectData - selectedComparableData) * unitAdjustment) ?? 0);
        }
      }

      (comparableMap[propertyId][groupFieldKey] as PropertyAttributeValue).adjustmentValue.autoComputed =
        adjustmentValue ? adjustmentValue : null;
      (comparableMap[propertyId][groupFieldKey] as PropertyAttributeValue).adjustmentValue.userEntered = null;
    }

    // Update subject property and comparable properties
    this.approachFactoryState.selectedComparableMap = comparableMap;

    this.approachFactoryService.recalculateAndUpdateProperties();
  };
}
