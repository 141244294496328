import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

type SnackBarType = 'success' | 'info' | 'warning' | 'error';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(
    private _snackBar: MatSnackBar,
    private _ngZone: NgZone
  ) {}

  /**
   * Displays a custom snackbar when called
   * @param type Type of snackbar
   * @param message Message to be displayed in the snackbar
   */
  open(type: SnackBarType, message: string) {
    this._ngZone.run(() => {
      this._snackBar.openFromComponent(SnackBarComponent, {
        data: { type, message },
        duration: 4000,
        verticalPosition: 'top',
        panelClass: 'snackbar',
      });
    });
  }
}
