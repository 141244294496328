import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface AuthToken {
  idToken: string | null;
  accessToken: string | null;
  // refreshToken: string | null; // Refresh token is not available in the v6 version of Amplify
}

@Injectable({
  providedIn: 'root',
})
export class AuthState {
  constructor() {}

  /** This state stores whether the user is authenticated or not */
  private _isAuthenticatedSub$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isAuthenticated$: Observable<boolean> = this._isAuthenticatedSub$.asObservable();

  get isAuthenticated(): boolean {
    return this._isAuthenticatedSub$.value;
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticatedSub$.next(value);
  }

  /** This state stores all the authentication tokens in the session */
  private _authTokenSub$: BehaviorSubject<AuthToken | null> = new BehaviorSubject<AuthToken | null>(null);
  authToken$: Observable<AuthToken | null> = this._authTokenSub$.asObservable();

  get authToken(): AuthToken | null {
    return this._authTokenSub$.value;
  }

  set authToken(value: AuthToken | null) {
    this._authTokenSub$.next(value);
  }
}
