import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { DataTableConfig } from '../../model/data-table-config';
import { PaginatorConfig } from '../../model/paginator-config';

@Component({
  selector: 'app-data-table-wrapper',
  templateUrl: './data-table-wrapper.component.html',
  styleUrls: ['./data-table-wrapper.component.scss'],
})
export class DataTableWrapperComponent implements OnChanges {
  pageSize: number = 0;
  pageIndex: number = 0;

  pageList: number[] | undefined;
  selectedIndex: any;
  _array = Array;

  @Input() tableConfig!: DataTableConfig;
  @Input() tableContent: any;
  @Input() enablePagination: boolean = false;
  @Input() paginationData!: PaginatorConfig;
  @Output() getDataForPage = new EventEmitter<PageEvent>();
  @Output() startRowAction = new EventEmitter<any>();

  ngOnChanges(changes: SimpleChanges): void {
    if (this.enablePagination && changes['paginationData']?.currentValue) {
      this.pageSize = changes['paginationData'].currentValue.pageSize;
      this.pageIndex = changes['paginationData'].currentValue.pageIndex;
    }
  }

  actionTrigger(rowData: any, actionType: any): void {
    const userAction: any = {
      actionToPerform: actionType,
      rowData: rowData,
    };

    this.startRowAction.emit(userAction);
  }

  changeSelection(index?: any) {
    this.selectedIndex = index;
  }

  getColumnDataByKey(columnData: any, dataProperty: string) {
    const props = dataProperty.split('.');
    switch (props.length) {
      case 1:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return columnData[props[0]];
      case 2:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return columnData[props[0]]?.[props[1]] ?? null;
      case 3:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return columnData[props[0]]?.[props[1]]?.[props[2]] ?? null;
      default:
        return null;
    }
  }

  pagination(event: PageEvent) {
    this.getDataForPage.emit(event);
  }

  openRow(columnHead: any) {
    columnHead.expand = !columnHead.expand;
  }
}
