import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserState } from '../state/user.state';

export const accessControlGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  userState = inject(UserState),
  router = inject(Router)
): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree => {
  try {
    if (
      (userState.permittedActions.includes('company_edit_settings') ||
        userState.permittedActions.includes('company_add')) &&
      state.url.includes('company')
    ) {
      return true;
    } else {
      return router.createUrlTree(['/orders']);
    }
  } catch (error) {
    return router.createUrlTree(['/orders']);
  }
};
