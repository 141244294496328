import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, forkJoin, map, of } from 'rxjs';
import { AuthService } from 'src/app/module/auth/service/auth.service';
import { googleMapLoader } from 'src/app/shared/initializer/google-map';
import { AppState } from 'src/app/shared/state/app.state';
import { CompanyState } from 'src/app/shared/state/company.state';
import { ReportState } from 'src/app/shared/state/report.state';
import { UserState } from 'src/app/shared/state/user.state';
import { environment } from 'src/environments/environment';
import {
  AllCompanyDetailsResponse,
  AppEnumMap,
  ApplicationSetting,
  ApplicationSettingApiResponse,
  CommonApiResponse,
  EnumItem,
  UserPermittedActionsResponse,
} from '../interface/common.interface';
import { ReportSetting } from '../interface/report.interface';

const API_BASE_URL = environment.backendBaseUrl;

@Injectable({
  providedIn: 'root',
})
export class InitializerService {
  constructor(
    private _http: HttpClient,
    private _router: Router,
    private appState: AppState,
    private companyState: CompanyState,
    private userState: UserState,
    private reportState: ReportState,
    private authService: AuthService
  ) {
    googleMapLoader(environment.googleMapsApiKey);
  }

  initializeApp(): Observable<void> {
    return forkJoin({
      enums: this._getAppEnumeration(),
      setting: this._getAppSetting(),
      userPermittedActions: this._getAccessSetting(),
    }).pipe(
      map(({ enums, setting, userPermittedActions }) => {
        this.appState.enumMap = this._formatAppEnumeration(enums);
        this.companyState.companySetting = setting.companySettings || {};
        this.userState.userSetting = setting.userSettings || {};
        this.userState.permittedActions = userPermittedActions;
        this.reportState.reportSetting = this.formatReportSetting(setting.orderTypeList, enums) ?? [];
        if (this.userState.permittedActions.includes('company_switch')) {
          this._getAllCompanies();
        }
        this._handleLoginSuccess();
      }),
      catchError((error: unknown) => {
        this.authService.logout();
        console.error('Error initializing app', error);
        return of();
      })
    );
  }

  public formatReportSetting(reportSetting: ReportSetting[] | undefined, enums: AppEnumMap) {
    return reportSetting?.map((report) => {
      report.key = report.enumValue;
      report.displayValue = report.enumDisplayValue;
      report.reportTypes = report.reportTypes?.map((reportType) => {
        reportType.key = reportType.enumValue;
        reportType.displayValue = reportType.enumDisplayValue;
        reportType.reportFormList = reportType.reportForms?.map((reportForm) => {
          let formEnum;
          switch (reportType.key) {
            case 'appraisalReport':
              formEnum = enums.appraisalReportForms;
              break;

            case 'evaluationReport':
              formEnum = enums.evaluationReportForms;
              break;

            case 'desktopAppraisalReport':
              formEnum = enums.desktopAppraisalReportForms;
              break;

            default:
              break;
          }

          const formOption = formEnum?.find((form) => form.enumValue == reportForm);

          return {
            key: formOption?.enumValue,
            displayValue: formOption?.enumDisplayValue,
          };
        });
        return reportType;
      });
      return report;
    });
  }

  private _getAppEnumeration(): Observable<AppEnumMap> {
    return this._http
      .get<CommonApiResponse<AppEnumMap>>(`${API_BASE_URL}/api/getAllEnumerations`)
      .pipe(map((res) => res.data));
  }

  private _getAppSetting(): Observable<ApplicationSetting> {
    return this._http
      .get<CommonApiResponse<ApplicationSettingApiResponse>>(`${API_BASE_URL}/api/getApplicableSettings`)
      .pipe(map((res) => res.data.applicableSettings));
  }

  private _getAccessSetting(): Observable<string[]> {
    return this._http
      .get<CommonApiResponse<UserPermittedActionsResponse>>(`${API_BASE_URL}/api/accessControl`)
      .pipe(map((res) => res.data.permittedActions));
  }

  private _getAllCompanies() {
    this._http
      .get<CommonApiResponse<AllCompanyDetailsResponse>>(
        `${API_BASE_URL}/api/companySettingses/search/findAllCompanies`
      )
      .pipe(
        map(
          (res) =>
            res.data.companies?.map((company) => {
              return {
                key: company.companyKey,
                displayValue: company.companyName,
              };
            }) ?? []
        ),
        catchError(() => {
          return of([]);
        })
      )
      .subscribe((res) => (this.companyState.companyList = res));
  }

  private _formatAppEnumeration(appEnums: AppEnumMap): AppEnumMap {
    const formattedEnum: any = {};
    Object.entries(appEnums).forEach(([key, valueList]: [string, EnumItem[]]) => {
      formattedEnum[key] = valueList.map((value: EnumItem) => {
        value.key = value.enumValue as string;
        value.displayValue = value.enumDisplayValue as string;
        return value;
      });
    });
    return formattedEnum as AppEnumMap;
  }

  private _handleLoginSuccess(): void {
    const redirectUrl = localStorage.getItem('redirectUrl');

    if (redirectUrl) {
      this._router.navigate([redirectUrl]);
      localStorage.removeItem('redirectUrl');
    }
  }
}
