import { Injectable } from '@angular/core';
import { JSONPath } from 'jsonpath-plus';
import { debounceTime, tap } from 'rxjs';
import { CommonService } from 'src/app/shared/utility/common.service';
import { NeighborhoodInsight } from '../../order/interface/neighborhood.interface';
import { OrderDetail, PropertyMap } from '../../order/interface/order.interface';
import { CostApproachState } from '../../order/state/cost-approach.state';
import { NeighborhoodState } from '../../order/state/neighborhood.state';
import { OrderState } from '../../order/state/order.state';
import { SalesApproachState } from '../../order/state/sales-approach.state';
import { PropertyState } from '../../property/state/property.state';
import { ShortTermRentalState } from '../../short-term-rental/state/short-term-rental.state';
import { SyncApi } from '../api/sync.api';
import { StateScope, SyncScope, SyncUpdateSet } from '../interface/sync.interface';
import { SyncState } from '../state/sync.state';

@Injectable({
  providedIn: 'root',
})
export class SyncService {
  constructor(
    private syncApi: SyncApi,
    private syncState: SyncState,
    private orderState: OrderState,
    private costApproachState: CostApproachState,
    private propertyState: PropertyState,
    private commonService: CommonService,
    private neighborhoodState: NeighborhoodState,
    private salesApproachState: SalesApproachState,
    private shortTermRentalState: ShortTermRentalState
  ) {
    this._syncOrderState();
    this._syncSubjectPropertyState();
    this._syncSelectedComparableListState();
    this._syncNeighborhoodInsightState();
    this._syncCostApproachSelectedComparableListState();
    this._syncShortTermRentSelectedComparableListState();
  }

  /**This function is used to update state instanly */
  public syncStatesWithoutUpdate(scope: StateScope, stateData: any) {
    if (scope == 'ORDER') {
      this.orderState.orderDetail = structuredClone(stateData);
      this.syncState.syncedOrderDetail = structuredClone(stateData);
    }

    if (scope == 'SUBJECT') {
      this.propertyState.subjectPropertyData = structuredClone(stateData);
      this.syncState.syncedSubjectPropertyData = structuredClone(stateData);
    }

    if (scope == 'SALES_SELECTED_COMPS') {
      this.salesApproachState.selectedComparableMap = stateData;
      this.syncState.syncedSelectedComparableMap = stateData;
    }

    if (scope == 'COST_SELECTED_COMPS') {
      this.costApproachState.selectedComparableMap = structuredClone(stateData);
      this.syncState.syncedCostApproachSelectedComparableMap = structuredClone(stateData);
    }

    if (scope == 'SHORT_TERM_RENTAL_SELECTED_COMPS') {
      this.shortTermRentalState.selectedComparableMap = structuredClone(stateData);
      this.syncState.syncedShortTermRentSelectedComparableMap = structuredClone(stateData);
    }
  }

  private _syncOrderState() {
    this.orderState.orderDetail$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.ORDER, true)),
        debounceTime(2000)
      )
      .subscribe((newOrder: OrderDetail) => {
        const syncedOrder = this.syncState.syncedOrderDetail;

        const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
          syncedOrder,
          newOrder
        );

        if (!modifiedJsonPathList?.length) {
          this.syncState.setIsSyncInProgress(SyncScope.ORDER, false);
          return;
        }

        const updateSet: SyncUpdateSet[] = [];

        modifiedJsonPathList.forEach((modifiedPath) => {
          if (modifiedPath.includes('primarySearchFilter')) return;

          const modifiedValue = JSONPath({ json: newOrder, path: modifiedPath, wrap: false });
          updateSet.push({
            scope: 'ORDER',
            jsonPath: modifiedPath,
            value: modifiedValue,
          });
        });

        if (!updateSet.length) {
          this.syncState.setIsSyncInProgress(SyncScope.ORDER, false);
          return;
        }

        this.syncApi
          .updateOrder(newOrder.id, newOrder.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.ORDER, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedOrderDetail = newOrder;
          });
      });
  }

  private _syncSubjectPropertyState() {
    // TODO: Merge all Property state
    this.propertyState.subjectPropertyData$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.SUBJECT, true)),
        debounceTime(2000)
      )
      .subscribe((newProperty) => {
        if (!newProperty) return;

        const order = this.orderState.orderDetail;
        const syncedProperty = this.syncState.syncedSubjectPropertyData;

        const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
          syncedProperty,
          newProperty
        );

        if (!modifiedJsonPathList?.length) {
          this.syncState.setIsSyncInProgress(SyncScope.SUBJECT, false);
          return;
        }

        const updateSet: SyncUpdateSet[] = [];

        modifiedJsonPathList.forEach((modifiedPath) => {
          const modifiedValue = JSONPath({ json: newProperty, path: modifiedPath, wrap: false });
          updateSet.push({
            id: newProperty.id,
            scope: 'PROPERTY',
            jsonPath: modifiedPath,
            value: modifiedValue,
          });
        });

        this.syncApi
          .updateOrder(order.id, order.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.SUBJECT, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedSubjectPropertyData = newProperty;
          });
      });
  }

  private _syncSelectedComparableListState() {
    this.salesApproachState.selectedComparableMap$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.SALES_SELECTED_COMPS, true)),
        debounceTime(2000)
      )
      .subscribe((newComparableMap: PropertyMap) => {
        const newComparableList = Object.values(newComparableMap);
        const order = structuredClone(this.orderState.orderDetail);
        const syncedComparableList = Object.values(this.syncState.syncedSelectedComparableMap);
        const updateSet: SyncUpdateSet[] = [];

        for (let i = 0; i < newComparableList.length; i++) {
          const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
            syncedComparableList[i],
            newComparableList[i]
          );

          if (!modifiedJsonPathList?.length) continue;

          modifiedJsonPathList.forEach((modifiedPath) => {
            const modifiedValue = JSONPath({ json: newComparableList[i], path: modifiedPath, wrap: false });
            updateSet.push({
              id: newComparableList[i].id,
              scope: 'PROPERTY',
              jsonPath: modifiedPath,
              value: modifiedValue,
            });
          });
        }

        if (!updateSet.length) {
          this.syncState.setIsSyncInProgress(SyncScope.SALES_SELECTED_COMPS, false);
          return;
        }

        this.syncApi
          .updateOrder(order.id, order.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.SALES_SELECTED_COMPS, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedSelectedComparableMap = structuredClone(newComparableMap);
          });
      });
  }

  private _syncNeighborhoodInsightState() {
    this.neighborhoodState.insight$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.NEIGHBORHOOD_INSIGHTS, true)),
        debounceTime(2000)
      )
      .subscribe((newInsight: NeighborhoodInsight) => {
        const syncedInsight = this.syncState.syncedNeighborhoodInsight;

        const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
          syncedInsight,
          newInsight
        );

        if (!modifiedJsonPathList?.length) {
          this.syncState.setIsSyncInProgress(SyncScope.NEIGHBORHOOD_INSIGHTS, false);
          return;
        }

        const updateSet: SyncUpdateSet[] = [];

        modifiedJsonPathList.forEach((modifiedPath) => {
          const modifiedValue = JSONPath({ json: newInsight, path: modifiedPath, wrap: false });
          updateSet.push({
            scope: 'ORDER',
            jsonPath: 'neighborhood.' + modifiedPath,
            value: modifiedValue,
          });
        });

        this.syncApi
          .updateOrder(this.orderState.orderDetail.id, this.orderState.orderDetail.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.NEIGHBORHOOD_INSIGHTS, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedNeighborhoodInsight = newInsight;
          });
      });
  }

  /**This function is used to update state instantly */
  private _syncCostApproachSelectedComparableListState() {
    this.costApproachState.selectedComparableMap$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.COST_SELECTED_COMPS, true)),
        debounceTime(2000)
      )
      .subscribe((newComparableMap: PropertyMap) => {
        const newComparableList = Object.values(newComparableMap);
        const order = structuredClone(this.orderState.orderDetail);
        const syncedComparableList = Object.values(this.syncState.syncedCostApproachSelectedComparableMap);
        const updateSet: SyncUpdateSet[] = [];

        for (let i = 0; i < newComparableList.length; i++) {
          const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
            syncedComparableList[i],
            newComparableList[i]
          );

          if (!modifiedJsonPathList?.length) continue;

          modifiedJsonPathList.forEach((modifiedPath) => {
            const modifiedValue = JSONPath({ json: newComparableList[i], path: modifiedPath, wrap: false });
            updateSet.push({
              id: newComparableList[i].id,
              scope: 'PROPERTY',
              jsonPath: modifiedPath,
              value: modifiedValue,
            });
          });
        }

        if (!updateSet.length) {
          this.syncState.setIsSyncInProgress(SyncScope.COST_SELECTED_COMPS, false);
          return;
        }

        this.syncApi
          .updateOrder(order.id, order.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.COST_SELECTED_COMPS, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedCostApproachSelectedComparableMap = structuredClone(newComparableMap);
          });
      });
  }

  private _syncShortTermRentSelectedComparableListState() {
    this.shortTermRentalState.selectedComparableMap$
      .pipe(
        tap(() => this.syncState.setIsSyncInProgress(SyncScope.SHORT_TERM_RENTAL_SELECTED_COMPS, true)),
        debounceTime(2000)
      )
      .subscribe((newComparableMap: PropertyMap) => {
        const newComparableList = Object.values(newComparableMap);
        const order = structuredClone(this.orderState.orderDetail);
        const syncedComparableList = Object.values(this.syncState.syncedShortTermRentSelectedComparableMap);
        const updateSet: SyncUpdateSet[] = [];

        for (let i = 0; i < newComparableList.length; i++) {
          const modifiedJsonPathList: string[] | undefined = this.commonService.getModifiedJsonPaths(
            syncedComparableList[i],
            newComparableList[i]
          );

          if (!modifiedJsonPathList?.length) continue;

          modifiedJsonPathList.forEach((modifiedPath) => {
            const modifiedValue = JSONPath({ json: newComparableList[i], path: modifiedPath, wrap: false });
            updateSet.push({
              id: newComparableList[i].id,
              scope: 'PROPERTY',
              jsonPath: modifiedPath,
              value: modifiedValue,
            });
          });
        }

        if (!updateSet.length) {
          this.syncState.setIsSyncInProgress(SyncScope.SHORT_TERM_RENTAL_SELECTED_COMPS, false);
          return;
        }

        this.syncApi
          .updateOrder(order.id, order.orderType, updateSet)
          .pipe(tap(() => this.syncState.setIsSyncInProgress(SyncScope.SHORT_TERM_RENTAL_SELECTED_COMPS, false)))
          .subscribe((success) => {
            if (!success) return;

            this.syncState.syncedShortTermRentSelectedComparableMap = structuredClone(newComparableMap);
          });
      });
  }
}
