import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map, of, startWith, tap, throwError } from 'rxjs';
import { ProgressLoaderService } from 'src/app/shared/services/progress-loader.service';
import { OrderApi } from '../api/order.api';
import {
  AddCompValidationStatus,
  AllStateCountyFips,
  ComparableProperty,
  ComparableTableDataSource,
  GeocodeApiResponse,
  GeocodeSearchParams,
  GetComparablePropertyApiResponse,
  OrderDetail,
  OrderRoutePathConfig,
  PropertyMap,
  ValuationType,
} from '../interface/order.interface';
import { OrderState } from '../state/order.state';

import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { JSONPath } from 'jsonpath-plus';
import { CellDataSetterService } from 'src/app/module/adjustment-grid/service/cell-data-setter.service';
import { MarkerType } from 'src/app/module/google-map/interface/google-map.interface';
import { ApproachFactoryService } from 'src/app/module/order/service/approach-factory.service';
import { ApproachFactoryState } from 'src/app/module/order/state/approach-factory.state';
import { SelectedSourceDataPipe } from 'src/app/shared/pipes/selected-source-data.pipe';
import { ProxyService } from 'src/app/shared/services/proxy.service';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { AccessControlState } from 'src/app/shared/state/access-control.state';
import { CompanyState } from 'src/app/shared/state/company.state';
import { AsyncDataState } from '../../async/state/async-data.state';
import { SearchedProperty } from '../../new-order/interface/new-order.interface';
import {
  ExtendedPropertyDetail,
  PropertyDetail,
  StandardStatusType,
} from '../../property/interface/property.interface';
import { PropertyState } from '../../property/state/property.state';
import { ShortTermRentalState } from '../../short-term-rental/state/short-term-rental.state';
import { SyncUpdateSet } from '../../sync/interface/sync.interface';
import { SyncService } from '../../sync/service/sync.service';
import { SyncState } from '../../sync/state/sync.state';
import { ValidationState } from '../../validation/state/validation.state';
import { OrderConfig } from '../config/order.config';
import { SimilarityScoreAttributes } from '../interface/similarity-score.interface';
import { CostApproachState } from '../state/cost-approach.state';
import { NeighborhoodState } from '../state/neighborhood.state';
import { ProjectInformationState } from '../state/project-information.state';
import { SalesApproachState } from '../state/sales-approach.state';
import { ValueReconciliationState } from '../state/value-reconciliation.state';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(
    private _router: Router,
    private accessControlState: AccessControlState,
    private approachFactoryService: ApproachFactoryService,
    private asyncDataState: AsyncDataState,
    private cellDataSetterService: CellDataSetterService,
    private companyState: CompanyState,
    private costApproachState: CostApproachState,
    private neighborhoodState: NeighborhoodState,
    private orderApi: OrderApi,
    private orderState: OrderState,
    private progressLoaderService: ProgressLoaderService,
    private projectInformationState: ProjectInformationState,
    private propertyState: PropertyState,
    private proxyService: ProxyService,
    private salesApproachState: SalesApproachState,
    private snackBarService: SnackBarService,
    private syncService: SyncService,
    private syncState: SyncState,
    private validationState: ValidationState,
    private valueReconciliationState: ValueReconciliationState,
    public selectedSourceDataPipe: SelectedSourceDataPipe,
    public shortTermRentalState: ShortTermRentalState,
    private approachFactoryState: ApproachFactoryState
  ) {}

  getOrderDetail(orderId: string): Observable<boolean> {
    this.progressLoaderService.show().message('Getting Order Details');
    return this.orderApi.getOrderDetail(orderId).pipe(
      map((order) => {
        this.orderState.orderDetail = order;
        this.syncState.syncedOrderDetail = order;
        // this.orderState.primarySearchFilter = order.salesComparison?.primarySearchFilter ?? null;

        this.orderState.secondarySearchFilter = OrderConfig.defaultSecondarySearchFilter;

        this.costApproachState.costApproachDetail = order.costApproach ?? null;
        if (order.costApproach) {
          order.costApproach.primarySearchFilter = order.costApproach?.primarySearchFilter ?? {
            ...this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]?.primarySearchFilter,
            valuationType: ValuationType.cost,
          };
        }

        this.costApproachState.secondarySearchFilter = {
          ...OrderConfig.defaultSecondarySearchFilter,
          valuationType: ValuationType.cost,
        };

        this.shortTermRentalState.secondarySearchFilter = {
          ...OrderConfig.defaultSecondarySearchFilter,
          valuationType: ValuationType.shortTermRental,
        };

        this.projectInformationState.projectInformation = order.projectInformation ?? {};

        this.neighborhoodState.neighborhood = order.neighborhood ?? null;

        if (order.valueReconciliation) this.valueReconciliationState.valueReconciliation = order.valueReconciliation;

        this.progressLoaderService.hide();
        return true;
      }),
      catchError((error: Error) => {
        this._router.navigate(['/orders']);
        return throwError(() => error);
      })
    );
  }

  searchComparables(valuationType?: ValuationType): Observable<boolean> {
    this.progressLoaderService.show().message('Searching for Comparables');
    let primarySearchFilter;
    if (this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]?.primarySearchFilter) {
      primarySearchFilter =
        this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]?.primarySearchFilter;
    } else if (valuationType === ValuationType.cost) {
      primarySearchFilter = {
        ...this.orderState.orderDetail.salesComparison.primarySearchFilter,
        valuationType: ValuationType.cost,
      };
    } else if (valuationType === ValuationType.shortTermRental) {
      primarySearchFilter = {
        ...this.companyState.companySetting.shortTermRentalReport.primarySearchFilter,
        effectiveDate: new Date(),
      };
    }

    return this.orderApi.searchComparables(this.orderState.orderDetail?.id, primarySearchFilter).pipe(
      map(() => {
        this.progressLoaderService.hide();
        return false;
      }),

      catchError((error) => {
        this.progressLoaderService.hide();
        this.snackBarService.open('error', error.message);
        return of(false);
      })
    );
  }

  getComparables(valuationType?: string): Observable<boolean> {
    const requestData = {
      id: this.orderState.orderDetail?.id,
      ...(valuationType == ValuationType.cost
        ? this.costApproachState.secondarySearchFilter
        : valuationType == ValuationType.shortTermRental
          ? this.shortTermRentalState.secondarySearchFilter
          : this.orderState.secondarySearchFilter),
    };
    return this.orderApi.getComparables(requestData).pipe(
      map((res: GetComparablePropertyApiResponse) => {
        if (!res.comparableList) return false;

        if (valuationType == ValuationType.cost) {
          let index = 1;
          const { pageNumber, pageLimit } = this.costApproachState.secondarySearchFilter ?? {};
          this.costApproachState.comparableListLength = res.totalCount;

          /** Add index/Sl.No. to each property to display in map marker and table view */
          this.costApproachState.comparableList = res.comparableList.map((comparable: ComparableProperty) => {
            if (pageNumber && pageLimit) comparable.index = (pageNumber - 1) * pageLimit + index;
            index++;
            return comparable;
          }) as ComparableTableDataSource[];
        } else if (valuationType == ValuationType.shortTermRental) {
          let index = 1;
          const { pageNumber, pageLimit } = this.shortTermRentalState.secondarySearchFilter ?? {};
          this.shortTermRentalState.comparableListLength = res.totalCount;

          /** Add index/Sl.No. to each property to display in map marker and table view */
          this.shortTermRentalState.comparableList = res.comparableList.map((comparable: ComparableProperty) => {
            if (pageNumber && pageLimit) comparable.index = (pageNumber - 1) * pageLimit + index;
            index++;
            return comparable;
          }) as ComparableTableDataSource[];
        } else {
          let index = 1;
          const { pageNumber, pageLimit } = this.orderState.secondarySearchFilter ?? {};

          this.orderState.comparableListLength = res.totalCount;

          /** Add index/Sl.No. to each property to display in map marker and table view */
          this.orderState.comparableList = res.comparableList.map((comparable: any) => {
            if (pageNumber && pageLimit) comparable.index = (pageNumber - 1) * pageLimit + index;
            index++;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return comparable;
          });
        }

        return false;
      }),
      startWith(true),
      catchError((error) => {
        this.snackBarService.open('error', error.message);
        return of(false);
      })
    );
  }

  getSelectedComparableList(valuationType: ValuationType): Observable<boolean> {
    return this.orderApi.getSelectedComparables(this.orderState.orderDetail?.id, valuationType).pipe(
      map((res: ExtendedPropertyDetail[]) => {
        if (!res) return false;

        if (valuationType == ValuationType.sales) {
          const selectedComparableMap = this._convertComparableListToMap(res);
          this.syncState.syncedSelectedComparableMap = structuredClone(selectedComparableMap);
          this.salesApproachState.selectedComparableMap = structuredClone(selectedComparableMap);
        }

        if (valuationType == ValuationType.cost) {
          const selectedComparableMap = this._convertComparableListToMap(res);
          this.syncState.syncedCostApproachSelectedComparableMap = structuredClone(selectedComparableMap);
          this.costApproachState.selectedComparableMap = structuredClone(selectedComparableMap);
        }

        if (valuationType == ValuationType.shortTermRental) {
          const selectedComparableMap = this._convertComparableListToMap(res);
          this.syncState.syncedShortTermRentSelectedComparableMap = structuredClone(selectedComparableMap);
          this.shortTermRentalState.selectedComparableMap = structuredClone(selectedComparableMap);
        }

        return false;
      }),
      catchError((error) => {
        this.snackBarService.open('error', error.message);
        return of(false);
      })
    );
  }

  computeSimilarityScore(similarityScores: SimilarityScoreAttributes): Observable<boolean> {
    return this.orderApi.computeSimilarityScore(this.orderState.orderDetail?.id, similarityScores).pipe(
      map(() => false),
      startWith(true),
      catchError(() => of(false))
    );
  }

  downloadComparables(type: 'pdf' | 'csv') {
    const reqParams = {
      id: this.orderState.orderDetail.id,
      type: type,
    };
    this.orderApi.downloadComparables(reqParams).subscribe();
  }

  getGeocode(reqParams: GeocodeSearchParams): Observable<GeocodeApiResponse> {
    return this.orderApi.getGeocode(reqParams);
  }

  getAccessControl() {
    return this.orderApi.getAccessControl(this.orderState.orderDetail.id).pipe(
      map((res: any) => {
        if (!res) return false;
        this.accessControlState.permittedActions = res;
        return false;
      }),
      catchError(() => of(false))
    );
  }

  getMarkerType(status?: StandardStatusType): MarkerType {
    let markerType: MarkerType;
    switch (status) {
      case StandardStatusType.sold:
        markerType = 'sold';
        break;

      case StandardStatusType.active:
      case StandardStatusType.activeUC:
        markerType = 'active';
        break;

      case StandardStatusType.pending:
        markerType = 'pending';
        break;

      case StandardStatusType.canceled:
      case StandardStatusType.delisted:
      case StandardStatusType.expired:
        markerType = 'disabled';
        break;

      default:
        markerType = 'sold';
        break;
    }
    return markerType;
  }

  getPrice(property: PropertyDetail) {
    const standardStatus = this.selectedSourceDataPipe.transform(property.StandardStatus);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return standardStatus && standardStatus.toLowerCase() == 'sold'
      ? this.selectedSourceDataPipe.transform(property.ClosePrice) ||
          this.selectedSourceDataPipe.transform(property.ListPrice)
      : this.selectedSourceDataPipe.transform(property.ListPrice);
  }

  calculatePricePerSqftLand(property: PropertyDetail) {
    const price = this.getPrice(property);
    const lotSizeSquareFeet = this.selectedSourceDataPipe.transform(property.LotSizeSquareFeet);
    if (Number(price) && Number(lotSizeSquareFeet)) {
      return (price / lotSizeSquareFeet)?.toFixed(2)?.toString() || '';
    } else {
      return '';
    }
  }

  calculatePricePerSqft(property: PropertyDetail) {
    const price = this.getPrice(property);
    const livingArea = this.selectedSourceDataPipe.transform(property.LivingArea);
    if (Number(price) && Number(livingArea)) {
      return (price / livingArea)?.toFixed(2)?.toString() || '';
    } else {
      return '';
    }
  }

  updateOrder(updateSet: SyncUpdateSet[]) {
    return this.orderApi
      .updateOrder(this.orderState.orderDetail?.id, this.orderState.orderDetail.orderType, updateSet)
      .pipe(
        map(() => true),
        catchError(() => of(false))
      );
  }

  getInspectionRecord(reqParams: any) {
    return this.orderApi.getInspectionRecord(reqParams).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  updateInspectionDetails(reqParams: any): Observable<void> {
    return this.orderApi.updateInspectionDetails(reqParams).pipe(
      tap(() => {
        this.snackBarService.open('success', 'Inspection data updated successfully');
      }),
      catchError(({ error }: HttpErrorResponse) => {
        this.snackBarService.open('error', (error?.message as string) ?? 'Unable to fetch inspection data');
        return EMPTY;
      })
    );
  }

  initializeRouteConfig() {
    const availableList: OrderRoutePathConfig[] = this.filterConfig(OrderConfig.defaultOrderRoutePathList);
    OrderConfig.orderRoutePathList = [...availableList];
  }

  filterConfig(configList: OrderRoutePathConfig[]): OrderRoutePathConfig[] {
    return configList
      .filter((config) => {
        const isOrderTypeMatch = config.orderType.includes(this.orderState.orderType) || false;

        if (isOrderTypeMatch) {
          if (config.companySettingJsonPath) {
            const modifiedPath = this.orderState.orderType
              ? `${this.orderState.orderType}.${config.companySettingJsonPath}`
              : config.companySettingJsonPath;

            const jsonValueWITHModifiedPath: unknown = JSONPath({
              json: this.companyState.companySetting,
              path: modifiedPath,
              wrap: false,
            });
            const jsonValueWITHOUTModifiedPath: unknown = JSONPath({
              json: this.companyState.companySetting,
              path: config.companySettingJsonPath,
              wrap: false,
            });

            if (jsonValueWITHModifiedPath == undefined) {
              if (jsonValueWITHOUTModifiedPath == true) return true;
              else return false;
            } else if (jsonValueWITHModifiedPath == true) return true;
            else return false;
          } else return true;
        } else return false;
      })
      .map((config) => {
        const filteredChildren = config.children ? this.filterConfig(config.children) : [];

        return {
          ...config,
          children: filteredChildren,
        };
      });
  }

  getSectionInfo() {
    const navigationList = OrderConfig.orderRoutePathList;
    if (!navigationList || navigationList.length === 0) {
      return {
        currentSection: { route: '', jsonPath: '', children: [], validationGroup: [] },
        currentSectionIndex: -1,
        currentSubSectionIndex: -1,
      };
    }
    const id = this.orderState.orderDetail.id;

    const currentRoute = this._router.url.split(`${id}/`)?.[1]?.split('/') ?? [];

    let section = currentRoute[0]; //finding the section
    if (section?.indexOf('#') > -1) section = section.slice(0, section.indexOf('#'));
    const currentSectionIndex = navigationList.findIndex((item) => item.route.includes(section));
    const currentSection = navigationList[currentSectionIndex];

    const subSection = currentRoute.reverse()[0]; //finding the sub section
    const currentSubSectionIndex = currentSection?.['children']?.findIndex((item) => item.route.includes(subSection));

    return {
      currentSection: currentSection ?? { route: '', jsonPath: '', children: [] },
      currentSectionIndex: currentSectionIndex ?? -1,
      currentSubSectionIndex: currentSubSectionIndex ?? -1,
    };
  }

  gotoSection(direction: string): string[] {
    const navigationList = OrderConfig.orderRoutePathList;

    if (!navigationList || navigationList.length === 0) {
      return [];
    }

    const sectionInfo = this.getSectionInfo();
    const currentSection = sectionInfo.currentSection;
    const currentSectionIndex = sectionInfo.currentSectionIndex;
    const currentSubSectionIndex = sectionInfo.currentSubSectionIndex;

    if (direction == 'next') {
      if (currentSubSectionIndex > -1 && currentSubSectionIndex < currentSection['children'].length - 1) {
        //goto next sub section
        return [currentSection['route'], currentSection['children'][currentSubSectionIndex + 1]['route']];
      } else if (currentSectionIndex > -1 && currentSectionIndex < navigationList.length - 1) {
        //goto next section
        return [navigationList[currentSectionIndex + 1]['route']];
      } else {
        //no next section
        return [];
      }
    } else {
      if (currentSubSectionIndex > -1 && currentSubSectionIndex - 1 > -1) {
        //goto previous sub section
        return [currentSection['route'], currentSection['children'][currentSubSectionIndex - 1]['route']];
      } else if (currentSectionIndex > -1 && currentSectionIndex - 1 > -1) {
        // goto previous section
        // if the the  previous section has children then navigate to last sub section
        if (navigationList[currentSectionIndex - 1].children.length) {
          const cl = navigationList[currentSectionIndex - 1]['children'].length;
          return [
            navigationList[currentSectionIndex - 1]['route'],
            navigationList[currentSectionIndex - 1]['children'][cl - 1]['route'],
          ];
        } else return [navigationList[currentSectionIndex - 1]['route']];
      } else {
        //no previous section
        return [];
      }
    }
  }

  checkRouteAvailable(): { next: boolean; previous: boolean } {
    const navigationList = OrderConfig.orderRoutePathList;
    const currentSectionIndex = this.getSectionInfo().currentSectionIndex;

    return {
      next: currentSectionIndex != navigationList.length - 1,
      previous: currentSectionIndex != 0,
    };
  }

  getAllStateCountyFips() {
    return this.orderApi.getAllStateCountyFips().pipe(
      map((res: AllStateCountyFips) => res.counties),
      catchError(() => of())
    );
  }

  getMLSBoards(params: any): Observable<any> {
    return this.proxyService.getMLSBoards(params);
  }

  validateComparableExists(selectedProperty: Partial<SearchedProperty>) {
    const params = {
      id: this.orderState.orderDetail.id,
      listingId: selectedProperty?.ListingId ?? null,
      addressInfo: {
        streetAddress: selectedProperty?.StreetAddress ?? null,
        unitNumber: selectedProperty?.UnitNumber ?? null,
        city: selectedProperty?.City ?? null,
        state: selectedProperty?.StateOrProvince ?? null,
        zip: selectedProperty?.PostalCode ?? null,
      },
    };
    const validationStatus: AddCompValidationStatus = {
      isDuplicate: null,
      isSelected: null,
      isRemovableComparable: null,
      notification: '',
      propertyInfo: null,
    };

    return this.orderApi.validateComparableExists(params).pipe(
      map((res) => {
        if (res?.listingId && res?.propertyId) {
          const selectedStatus =
            this.orderState.orderDetail?.[this.approachFactoryState.activeApproachKey]?.selectedComparableList?.some(
              (item: any) => {
                return item.ListingId == res.listingId;
              }
            ) ?? false;

          /**
           * isSelected            : true -> hide select button ,  false -> show select button
           * isRemovableComparable : true -> show remove button , false -> hide remove button
           * isDuplicate           : true -> hide add button , false -> show add button
           */
          switch (res?.propertyRole) {
            case 'SUBJECT':
              validationStatus['isDuplicate'] = true;
              validationStatus['isSelected'] = true;
              validationStatus['isRemovableComparable'] = false;
              validationStatus['notification'] = 'This is the Subject Property';
              break;

            case 'SPECIFIC_COMPARABLE':
            case 'CUSTOM_COMPARABLE':
              validationStatus['isDuplicate'] = true;
              validationStatus['isSelected'] = selectedStatus;
              validationStatus['isRemovableComparable'] = true;
              validationStatus['notification'] = 'This comparable already exists';

              break;

            case 'COMPARABLE':
              validationStatus['isDuplicate'] = true;
              validationStatus['isSelected'] = selectedStatus;
              validationStatus['isRemovableComparable'] = false;
              validationStatus['notification'] = 'This comparable already exists';

              break;

            default:
              validationStatus['isDuplicate'] = null;
              validationStatus['isSelected'] = null;
              validationStatus['isRemovableComparable'] = null;
              validationStatus['notification'] = '';
              break;
          }
        } else {
          validationStatus['isDuplicate'] = false;
          validationStatus['isSelected'] = null;
          validationStatus['isRemovableComparable'] = false;
          validationStatus['notification'] = '';
        }

        return { ...validationStatus, propertyInfo: res };
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  /**function is used to clear order state while navigating to other routes */
  clearState() {
    this.orderState.orderDetail = {} as OrderDetail;
    this.syncService.syncStatesWithoutUpdate('ORDER', {});

    this.orderState.comparableList = [];
    this.orderState.comparableListLength = 0;
    this.salesApproachState.selectedComparableMap = {};
    this.syncService.syncStatesWithoutUpdate('SALES_SELECTED_COMPS', []);

    if (
      this.orderState.orderDetail &&
      this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]?.primarySearchFilter
    ) {
      this.orderState.orderDetail[this.approachFactoryState.activeApproachKey]!.primarySearchFilter =
        OrderConfig.defaultPrimarySearchFilter;
    }
    this.orderState.secondarySearchFilter = null;

    this.costApproachState.comparableList = [];
    this.costApproachState.comparableListLength = 0;
    this.costApproachState.selectedComparableMap = {};
    this.syncService.syncStatesWithoutUpdate('COST_SELECTED_COMPS', []);

    this.costApproachState.secondarySearchFilter = null;

    this.shortTermRentalState.comparableList = [];
    this.shortTermRentalState.comparableListLength = 0;
    this.shortTermRentalState.selectedComparableMap = {};
    this.syncService.syncStatesWithoutUpdate('SHORT_TERM_RENTAL_SELECTED_COMPS', []);
    this.shortTermRentalState.secondarySearchFilter = null;

    this.asyncDataState.asyncPendingData = [];

    this.validationState.selectedRule = null;
    this.validationState.validationResult = null;
    this.validationState.validationAuditTime = null;
    this.validationState.validationSheetToggle = false;

    this.propertyState.subjectPropertyData = null;
    this.syncService.syncStatesWithoutUpdate('SUBJECT', {});
    this.propertyState.activePropertyData = null;
  }

  /** Convert a comparable list to a map with propertyId as the key */
  private _convertComparableListToMap(comparableList: ExtendedPropertyDetail[]): PropertyMap {
    const comparableMap: PropertyMap = {};
    comparableList.forEach((comparable) => {
      if (comparable.id) comparableMap[comparable.id] = structuredClone(comparable);
    });
    return comparableMap;
  }
}
