import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CombinedOrderType } from 'src/app/shared/interface/report.interface';
import { OrderConfig } from '../config/order.config';
import {
  ComparableTableDataSource,
  OrderDetail,
  SecondarySearchFilter,
  SpecificComparable,
  ValuationType,
} from '../interface/order.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderState {
  /** Stores details related to a single active order */
  private _orderDetailSub$ = new BehaviorSubject<OrderDetail>({} as OrderDetail);
  orderDetail$ = this._orderDetailSub$.asObservable();

  get orderDetail(): OrderDetail {
    return this._orderDetailSub$.value;
  }

  set orderDetail(order: OrderDetail) {
    this._orderDetailSub$.next(order);
  }

  /** Get active order's order type */
  get orderType(): CombinedOrderType {
    return (this.orderDetail.reportType || this.orderDetail.orderType) as CombinedOrderType;
  }

  private _activeValuationTypeSub$ = new BehaviorSubject<ValuationType | null>(null);
  activeValuationType$ = this._activeValuationTypeSub$.asObservable();

  get activeValuationType(): ValuationType | null {
    return this._activeValuationTypeSub$.value;
  }

  set activeValuationType(valuationType: ValuationType | null) {
    this._activeValuationTypeSub$.next(valuationType);
  }

  /** Stores a list of comparable properties in an active order */
  private _comparableListSub$ = new BehaviorSubject<Array<ComparableTableDataSource>>([]);
  comparableList$ = this._comparableListSub$.asObservable();

  get comparableList(): Array<ComparableTableDataSource> {
    return this._comparableListSub$.value;
  }

  set comparableList(order: Array<ComparableTableDataSource>) {
    this._comparableListSub$.next(order);
  }

  /**
   * Stores total length of comparable properties in an active order
   * This is to be mainly used for pagination.
   */
  private _comparableListLengthSub$ = new BehaviorSubject<number>(0);
  comparableListLength$ = this._comparableListLengthSub$.asObservable();

  get comparableListLength(): number {
    return this._comparableListLengthSub$.value;
  }

  set comparableListLength(length: number) {
    this._comparableListLengthSub$.next(length);
  }

  /**
   * Stores the criteria to be used to get comparables.
   * This state should be updated every time a sub-filter, pagination or sort happens on the comparables.
   * This will trigger the /getComparables API based on the saved criteria.
   */
  private _secondarySearchFilterSub$ = new BehaviorSubject<SecondarySearchFilter | null>(
    OrderConfig.defaultSecondarySearchFilter
  );
  secondarySearchFilter$ = this._secondarySearchFilterSub$.asObservable();

  get secondarySearchFilter(): SecondarySearchFilter | null {
    return this._secondarySearchFilterSub$.value;
  }

  set secondarySearchFilter(subFilter: Partial<SecondarySearchFilter | null>) {
    if (subFilter !== null) {
      const concatenatedValue = {
        ...this._secondarySearchFilterSub$.value,
        ...subFilter,
      };
      this._secondarySearchFilterSub$.next(concatenatedValue);
    } else {
      this._secondarySearchFilterSub$.next(null);
    }
  }

  private _specificComparableSub$ = new BehaviorSubject<SpecificComparable | null>(null);
  specificComparable$ = this._specificComparableSub$.asObservable();

  get specificComparable(): SpecificComparable | null {
    return this._specificComparableSub$.value;
  }

  set specificComparable(value: SpecificComparable | null) {
    this._specificComparableSub$.next(value);
  }
}
