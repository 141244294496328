<div>
  <!-- <div class="row-flex"><label>MLS Access Configurations</label></div> -->
  <div class="d-flex mb-3 align-items-center">
    <p class="mb-0">MLS Board limit : 15</p>
    <button mat-flat-button class="ms-auto" color="accent" (click)="addMlsData()" *ngIf="addNewButton" type="button">
      Add New
    </button>
  </div>

  <div *ngIf="templateopen">
    <div>
      <div class="d-flex gap-3">
        <mat-form-field appearance="outline" class="fields-height">
          <mat-label>State</mat-label>
          <mat-select
            [(ngModel)]="stateSelected"
            [(value)]="stateSelected"
            (ngModelChange)="onOptionsSelected('state')"
            disabled="{{ editMode }}"
          >
            <mat-option *ngFor="let st of states" [value]="st.enumValue">
              {{ st.enumDisplayValue }} - {{ st.enumValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fields-height" style="min-width: 500px">
          <mat-label>MLS board</mat-label>
          <mat-select
            [(ngModel)]="mlsBoardSelected"
            [(value)]="mlsBoardSelected"
            (ngModelChange)="onOptionsSelected()"
            disabled="{{ editMode }}"
          >
            <mat-option *ngFor="let br of mlsBoard" [value]="br">
              {{ buildMLSBoardDisplayNameForDropDown(br) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="fields-height">
          <mat-label>Membership Number</mat-label>
          <input matInput [(ngModel)]="membershipNumber" (ngModelChange)="onOptionsSelected()" />
        </mat-form-field>
      </div>
      <div class="row-flex mb-4">
        <button mat-button type="button" color="primary" (click)="closeMlsData()">Cancel</button>
        <button
          mat-flat-button
          color="accent"
          type="button"
          (click)="addAndVerify()"
          disabled="{{ addVerifyDisable }}"
          *ngIf="addVerifyHidden"
        >
          Add and Verify
        </button>
        <button
          mat-flat-button
          color="accent"
          type="button"
          (click)="editAndVerify()"
          disabled="{{ editVerifyDisable }}"
          *ngIf="editVerifyHidden"
        >
          Edit and Verify
        </button>
      </div>
    </div>
  </div>
  <div class="table-responsive">
    <table class="gen_table border-bottom" mat-table [dataSource]="dataSource" ngDefaultControl [formControl]="control">
      <ng-container matColumnDef="slNosection">
        <th mat-header-cell *matHeaderCellDef class="heading-color">Sl No</th>
        <td mat-cell *matCellDef="let i = index">{{ i + 1 }}</td>
      </ng-container>

      <ng-container [matColumnDef]="column.columnKey" *ngFor="let column of columDef">
        <th mat-header-cell *matHeaderCellDef class="heading-color">
          {{ column.header }}
        </th>
        <td mat-cell *matCellDef="let element">
          <ng-container
            [ngTemplateOutlet]="getTableColumnTemplate(column.header)"
            [ngTemplateOutletContext]="{ element: element, columnKey: column.columnKey }"
          ></ng-container>
        </td>
        <ng-template #statusTemplate let-element="element" let-columnKey="columnKey">
          <ng-container *ngIf="element[columnKey] == true; then trueTemplate; else falseTemplate"></ng-container>
          <ng-template #trueTemplate>
            <mat-icon class="color-success" matTooltip="Verified">done</mat-icon>
          </ng-template>
          <ng-template #falseTemplate>
            <mat-icon class="color-danger" matTooltip="Not Verified">close</mat-icon>
          </ng-template>
        </ng-template>
        <ng-template #mlsInformationTemplate let-element="element" let-columnKey="columnKey">
          {{ buildMLSBoardDisplayNameFromUserSettings(element) }}
        </ng-template>

        <ng-template #defaultTemplate let-element="element" let-columnKey="columnKey">
          {{ element[columnKey] }}
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="actionButton">
        <th mat-header-cell *matHeaderCellDef class="heading-color">Actions</th>
        <td mat-cell *matCellDef="let row; let i = index">
          <button mat-icon-button (click)="editMlsData(row)">
            <mat-icon color="primary">edit</mat-icon>
          </button>
          <button
            mat-icon-button
            type="button"
            (click)="openMlsAccessContextActions(deleteTrigger)"
            cdkOverlayOrigin
            #deleteTrigger="cdkOverlayOrigin"
          >
            <mat-icon class="color-danger">delete</mat-icon>
          </button>
          <ng-template #deleteOverlayTemplate>
            <div class="delete-confirmation-overlay">
              <button mat-button color="warn" type="button" (click)="removeMls(row)">Delete MLS Access</button>
              <button mat-button type="button" (click)="closeMlsAccessContextActions()">Cancel</button>
            </div>
          </ng-template>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['slNosection'].concat(columnsToDisplay.concat(['actionButton']))"></tr>
      <tr mat-row *matRowDef="let row; columns: ['slNosection'].concat(columnsToDisplay.concat(['actionButton']))"></tr>
    </table>
    <!-- <mat-paginator [length]="100"
              [pageSize]="5"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page">
   </mat-paginator> -->
  </div>
</div>
