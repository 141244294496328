<mat-form-field appearance="outline" *ngIf="!range" (click)="picker.open()">
  <mat-label> {{ label }} </mat-label>
  <input
    matInput
    [min]="minDate"
    [max]="maxDate"
    [matDatepicker]="picker"
    [(ngModel)]="modelCtrlValue"
    [disabled]="isDisabled"
  />
  <mat-datepicker #picker (closed)="pickerClosed()" [startView]="startView"></mat-datepicker>

  <button matSuffix mat-icon-button aria-label="Clear" *ngIf="clear" (click)="clearData()">
    <mat-icon>close_small</mat-icon>
  </button>
  <mat-icon *ngIf="infoText" class="input-info-suffix" matSuffix [matTooltip]="infoText"> info </mat-icon>
</mat-form-field>

<mat-form-field appearance="outline" *ngIf="range" (click)="picker.open()">
  <mat-label> {{ label }} </mat-label>
  <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">
    <input matStartDate placeholder="Start date" [(ngModel)]="rangeStart" [disabled]="isDisabled" />
    <input matEndDate placeholder="End date" [(ngModel)]="rangeEnd" [disabled]="isDisabled" />
  </mat-date-range-input>
  <mat-date-range-picker #picker (closed)="pickerClosed()" [startView]="startView"></mat-date-range-picker>

  <mat-icon *ngIf="infoText" class="input-info-suffix" matSuffix [matTooltip]="infoText"> info </mat-icon>
</mat-form-field>
